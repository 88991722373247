import React, { Suspense, useEffect, useRef, useState } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
// amplify
import Amplify, { API, graphqlOperation} from "aws-amplify";
// redux
import {useDispatch ,useSelector} from 'react-redux';
import { Link } from 'react-router-dom';

// Organisms
import ViewingHeader from '../Organisms/viewing-header.js';
import Summary from '../Organisms/summary.js';
// Molecules
import BlackModal from '../Molecules/black-modal';
import GiftModal from '../Molecules/giftModal';
import PaidModal from '../Molecules/paid_modal';
// Atoms
import Button from '../Atoms/button';

import Chat from '../Pages/Chat.js';
// Containers
import UseToggle from '../../containers/toggle.js';
import { useParams, Prompt } from "react-router";
import {paid_toggle} from '../../actions';
// DB関連
import * as gqlQueries from '../../graphql/queries'

function Viewing(props){
  const streamingInfo = props.resource.read().data.getStreaming;
  // ユーザー情報
  const userInfo = useSelector(state => state.auth.userInfo);
  // パラメータから現在アクセスしているチャンネルを取得
  let { channelId } = useParams();
  const [nowStreaming, changeStreaming] = useState(false);
	const [client, setClient] = useState([]);
	const [uid, setUid] = useState('');
	const [audioTrack, setAudio] = useState([]);
	const [videoTrack, setVideo] = useState([]);
	const [toggle, modalToggle] = UseToggle(false);
	const [paidToggle, changePaid] = UseToggle(false);
  let [viewerCount, setViewerCount] = useState(streamingInfo.viewerCount + 1);
	// reduxディスパッチ
	const dispatch = useDispatch();
  // 有料配信を支払い済かどうかのstate
  const paid_state = useSelector(state => state.paid_state.paid);

  const divEl = useRef(null);

  // ページを離れる時にチャンネルを離れる
  const navgation = async()=>{
    // チャンネルを退出する。
    await client.leave();
  }
	useEffect(()=>{
    // Agoraのクライアントを作成
    const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    setClient(client);
    window.addEventListener('popstate', function(e) {
      // 他のページへ遷移する際にチャンネルを退出する。
      client.leave();
    });
    const streaming_config = ()=>{
      // streaming情報を取得する
      (async function getStreaming(){ 
        let streaming_uid = '';
          const agoraInfo = await API.graphql(graphqlOperation(gqlQueries.getAgoraToken, { channel: channelId }));
      // チャンネルに参加する。
      await client.join(
        '927dcdc2edac4016b94439051dfe8c88',
        channelId, 
        agoraInfo.data.getAgoraToken.token,
        null)
        // ライブ配信が開始された処理
        client.on("user-published", async (user, mediaType) => {
          //サブスクライブする
          await client.subscribe(user, mediaType);
          streaming_uid = user.uid;
          setUid(user.uid);
          if (mediaType === "video") {
            const remoteVideoTrack = user.videoTrack;
            changeStreaming(true);
            const playerContainer = document.createElement("div");
            playerContainer.id = user.uid;
            // playerContainer.classList.add('h-full', 'w-auto');
            // playerContainer.classList.add('h-full');
            let parent = document.getElementById("play_video");
            parent.appendChild(playerContainer);
            // ライブ配信の再生する。
            remoteVideoTrack.play(playerContainer);

            let video = document.querySelector('video')
            video.classList.add('m-auto');
            video.removeAttribute('style');
            // video要素にcontrolsを追加する
            video.setAttribute('controls', '');
            setVideo(remoteVideoTrack);
          }

          if (mediaType === "audio") {
            const remoteAudioTrack = user.audioTrack;
            remoteAudioTrack.play();
            setAudio(remoteAudioTrack);
          }
        });

        //　誰かがライブ配信を見に来た 
        await client.on("user-joined", (user,reason) => {
          // 視聴者をインクリメント
          viewerCount = viewerCount + 1;
          setViewerCount(viewerCount);
        })
        // ライブ配信が終了された処理
        await client.on("user-left", (user,reason) => {
          // 視聴者をデクリメント
          viewerCount = viewerCount - 1;
          setViewerCount(viewerCount);
          // 配信者が離脱したかその他ユーザーが離脱したかを判定する。配信者ならライブ配信を終了させる
          // 終了と同時に動的に作成したdiv要素を消す
          const playerContainer = document.getElementById(user.uid);
          if(streaming_uid === user.uid){
            if(playerContainer != null){
              playerContainer.remove();
            }
            // チャンネルを退出する。
            client.leave();
            changeStreaming(false);
            let finish_dom = document.createElement('div');
            finish_dom.id = 'event_dom';
            finish_dom.classList.add(
              'h-full', 
              'bg-blue-300',
              'rounded',
              'md:mt-4',
              'mt-0',
              'text-white',
              'text-center',
              'md:text-2xl',
              'text-xl',
              'font-bold',
              'flex',
            );
            let p1 = document.createElement('p');
            let p2 = document.createElement('p');
            p1.innerHTML = 'この配信は終了しました。'
            p2.innerHTML = 'ご視聴ありがとうございました。'
            let content_dom = document.createElement('div');
            content_dom.classList.add('m-auto');
            content_dom.appendChild(p1);
            content_dom.appendChild(p2);
            // ローディングボタンを消す
            document.getElementById("loading").classList.add('hidden');
            // 一時停止画面があれば消す
            let event_dom = document.getElementById("event_dom");
            if(event_dom != null){
              event_dom.remove();
            }
            let parent = document.getElementById("video_wrapper");
            finish_dom.appendChild(content_dom);
            parent.appendChild(finish_dom);
          }
        });

        // マイクがミュートされたか、ビデオ一時中止
        await client.on("user-unpublished", (user,mediaType) => {
          if (mediaType === "video") {
              changeStreaming(false);
              // 一時終了と同時に動的に作成したdiv要素を消す
              const playerContainer = document.getElementById(user.uid);
              if(playerContainer != null){
                playerContainer.remove();
                let pause_dom = document.createElement('div');
                pause_dom.id = 'event_dom';
                pause_dom.classList.add(
                  'h-full', 
                  'bg-blue-300',
                  'rounded',
                  'md:mt-4',
                  'mt-0',
                  'text-white',
                  'text-center',
                  'md:text-2xl',
                  'text-xl',
                  'font-bold',
                  'flex',
                );
                let p1 = document.createElement('p');
                let p2 = document.createElement('p');
                p1.innerHTML = 'この配信は一時停止中です'
                p2.innerHTML = 'お待ちください。'
                let content_dom = document.createElement('div');
                content_dom.classList.add('m-auto');
                content_dom.appendChild(p1);
                content_dom.appendChild(p2);
                // ローディングボタンを消す
                document.getElementById("loading").classList.add('hidden');
                let parent = document.getElementById("video_wrapper");
                pause_dom.appendChild(content_dom);
                parent.appendChild(pause_dom);
            }
          }
        })
      })();
    }
    // 有料配信設定でログイン済だった場合、この配信にお金を払ったか確認する
    if(streamingInfo.is_paid){
      const result = API.graphql(graphqlOperation(gqlQueries.listPaids,{filter:{streaming_id: {eq: channelId}, user_id: {eq: userInfo.id}}}));
      result.then((val)=>{
        // すでに支払い済かどうか確認
        if(val.data.listPaids.items.length){
          dispatch(paid_toggle(true));
          streaming_config();
        }else{
          dispatch(paid_toggle(false));
        }
      })
    }else{
      streaming_config();
    }
  },[paid_state])

    useEffect(()=>{
      function setHeight() {
        if(document.getElementById('video_content')){
          const view_height = window.innerHeight;
          // 動的にチャットの高さを取得
          const video_height = document.getElementById('video_content').clientHeight;
          const height2 = document.getElementById('height_2').clientHeight;
          let all_video_height = video_height + height2;
          document.getElementById('pc_chat_height').style.height = (view_height - 60) + 'px';
          let chat_height = view_height - all_video_height;
          document.getElementById('chat_height').style.height = chat_height + 'px';
        }
      }
      // 2.初期化
      setHeight();
      // 3.ブラウザのサイズが変更された時・画面の向きを変えた時に再計算する
      window.addEventListener('resize', setHeight);
    },[])
  return(
		<React.Fragment>
    {/* promptを使いページ遷移を検知している */}
    <Prompt
      when={true}
      message={navgation}
    />
			<BlackModal targetID='modal' />
      {/* 支払い済みか否かでギフトをお送るモーダルと支払いのモーダルを切り替える */}
      {
        streamingInfo.is_paid ?
          paid_state ?
            /* ギフトを送る為のmodal */
            <GiftModal targetID='modal' toggle={toggle} modalToggle={modalToggle}/>
          :
            /* 有料配信の支払いをする */
            <PaidModal targetID='modal' toggle={paidToggle} modalToggle={changePaid} streamingInfo={streamingInfo} userInfo={userInfo}/>
        :
        /* ギフトを送る為のmodal */
        <GiftModal targetID='modal' toggle={toggle} modalToggle={modalToggle}/>
      }
				<div ref={divEl} id="video_content" className="flex flex-col  md:w-9/12 w-full md:px-4">
          {
            streamingInfo.is_paid ? 
              paid_state ?
                nowStreaming ?  
                  <div className="md:mt-5 md:h-100 md:mt-4" id="play_video">
                  </div>
                  :
                  <div className="relative w-full md:h-100 h-72 md:mt-4" id="not_streaming">
                    <div className="h-full w-full bg-black bg-opacity-50" id="video_wrapper">
                      {/* <video autoPlay playsInline controls id="video" className="h-full w-full" id=""></video> */}
                      <img src="/images/loading.png" id="loading" className="transform-50 absolute top-1/2 left-1/2 h-10"/>
                    </div>
                  </div>
              :
              <div className="relative w-full md:h-100 h-72 md:mt-4" id="not_streaming">
                <div className="h-full w-full bg-blue-600 bg-opacity-50 relative" id="video_wrapper">
                  <div className="absolute-center text-white font-bold md:text-3xl text-lg text-center">
                    <p>有料配信です</p>
                    <p>視聴するためには{streamingInfo.price}ptを支払う<span className="inline-block">必要</span>があります</p>
                    {/* ログイン済か未ログインか確認 */}
                    {userInfo.id ?
                      <Button 
                      size="paid_button" 
                      type="paid" 
                      children="支払う" 
                      func={changePaid} 
                      />
                    :
                      <Link to="/login">
                        <Button 
                        size="paid_button" 
                        type="paid" 
                        children="支払う" 
                        />
                      </Link>
                    }
                  </div>
                </div>
              </div>
            :
            nowStreaming ?  
              <div className="md:mt-5 md:h-100 md:mt-4" id="play_video">
              </div>
              :
              <div className="relative w-full md:h-100 h-72 md:mt-4" id="not_streaming">
                <div className="h-full w-full bg-black bg-opacity-50" id="video_wrapper">
                  {/* <video autoPlay playsInline controls id="video" className="h-full w-full" id=""></video> */}
                  <img src="/images/loading.png" id="loading" className="transform-50 absolute top-1/2 left-1/2 h-10"/>
                </div>
              </div>
          }
          {/* 概要欄 */}  
          <div id="height_2" className="">
            <Suspense fallback={<React.Fragment></React.Fragment>}>
              <Summary resource={props.resource} channelId={channelId} dark={false}/>
            </Suspense>
          </div>
				</div>
        <div className="md:hidden block md:w-3/12 w-full" id="chat_height">
          <Chat qr={false} modalToggle={modalToggle} viewerCount={viewerCount}/>
        </div>
        {/* コメント欄 */}
        <div className="md:block hidden md:w-3/12 w-full h-full" id="pc_chat_height">
          <Chat qr={false} modalToggle={modalToggle} viewerCount={viewerCount}/>
        </div>
    </React.Fragment>
  )
}

export default Viewing
