import React, {useState, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { notification_badge} from '../../actions';
import '../../style/header.css';
import API, { graphqlOperation } from '@aws-amplify/api';
// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPodcast } from "@fortawesome/free-solid-svg-icons";
// redux
import {useSelector, useDispatch } from 'react-redux';
// Containers
import UseToggle from '../../containers/toggle.js';
//Atoms
import BellIcon from '../Atoms/bellIcon';
import Button from '../Atoms/button';
import Img from '../Atoms/img';

//Molecules
import TogglePage from './togglePage.js';
import HeaderMenu from './headerMenu.js';

// DB関連
import * as gqlSubscriptions from '../../graphql/subscriptions.js';

function HeaderList(props){
	const [ bellopen, bellToggle ] = UseToggle(false);
	const [ myopen, myToggle ] = UseToggle(false);
	const dispatch = useDispatch();
	//通知数 
	let countNotices = useSelector(state => state.countNotices.count);
	const element =(
		<span className="flex">
			<FontAwesomeIcon icon={faPodcast} className="my-auto mr-1" />
			LIVE
		</span>
	)

  //通知を監視しリアルタイムで変更があった場合には更新する 
  useEffect(() => {
    const subscription = API
      .graphql(graphqlOperation(gqlSubscriptions.onCreateNotices))
      .subscribe({
        next: (event) => {
				if(event.value.data.onCreateNotices.receive_user == props.userInfo.id){
					countNotices += 1;
					// 通知の数を更新
					dispatch(notification_badge(countNotices));
				}
        }
      });
    return () => {
      subscription.unsubscribe();
    }
  },[countNotices]);
	return(
		<div className={"flex flex-col max-w-screen-xl px-2 md:items-center md:justify-between md:flex-row" + (props.dark ? " bg-gray-800" : "" )}>
			{/*PCナビバーモバイル*/}
			<nav className={"flex-col flex-grow pb-4 md:pb-0 md:flex md:justify-end md:flex-row" + (props.dark ? " bg-gray-800" : "" )}>
			<div className="relative">
				{/*通知ベル*/}
				<div className="relative hover:opacity-75">
					<BellIcon size="md" func={bellToggle}/>
					{/* 通知バッジ */}
					{
						countNotices ?
							<span className="absolute top-0 md:right-4 right-0 rounded-full h-5 w-5 flex items-center justify-center bg-red-600 text-white font-bold text-xs">{countNotices}</span>
						:
							<span></span>
					}
				</div>
				{/*クリックで表示非表示*/}
				<TogglePage value={bellopen} userInfo={props.userInfo}/>
			</div>
			<div className="relative m-auto hidden md:block">
					{/*プロフィール画像*/}
					{props.userInfo.icon ?
						<Img size="md" type="md" src={props.userInfo.icon} func={myToggle} />
							:
						<img src="/images/smiley-sticker.svg" className="h-10 w-auto rounded-full hover:opacity-75 cursor-pointer" onClick={myToggle}/>
					}
					<HeaderMenu value={myopen} />
			</div>
			<Link to="/liveconfig" className="hidden md:block">
				<Button size="md" type="blue" children={element}/>
			</Link>
			</nav>
		</div>
	)
}
export default HeaderList
