// amplify
import Amplify, {API, graphqlOperation} from "aws-amplify";
// DB関連
import * as gqlQueries from '../graphql/queries'
import * as gqlMutations from '../graphql/mutations'

const UseAgoraFunction =()=>{
    // ライブ配信の終了処理
    const agoraStop = async(client, audioTrack, videoTrack, channelId, resourceId, sid)=>{
        await client.leave();
        if(Object.keys(audioTrack).length){
            await audioTrack.setEnabled(false);
            audioTrack.close();
        }
        await videoTrack.setEnabled(false);
        videoTrack.close();

        // streaming情報を取得する
        const nowStreaming = await API.graphql(graphqlOperation(gqlQueries.getStreaming, { id: channelId }));
        // ライブ配信中の最大視聴者数
        const max_viewerCount = nowStreaming.data.getStreaming.max_viewerCount;
        const user_id = nowStreaming.data.getStreaming.user_id;
        const userInfo = await API.graphql(graphqlOperation(gqlQueries.getUser, { id: user_id }));
        // ユーザーの過去累積視聴者数
        const nowCount = userInfo.data.getUser.all_viewerCount;
        // ライブ配信中の最大視聴者数と過去累積視聴者数の合計で更新する。
        const total = nowCount + max_viewerCount;
        API.graphql(
            graphqlOperation(gqlMutations.updateUser, {
                input: {
                    id: user_id,
                    all_viewerCount: total
                }
            })
        )
        // アーカイブに残す配信をしていたら終了してs3に録画した動画を保存する。Lambdaで実行
        if(resourceId){
            const {data:{agoraRecording: {filename, error}}} = await API.graphql(graphqlOperation(gqlQueries.agoraRecording, {event: 'stopRecording', channelId : channelId, resourceId: resourceId, sid: sid}));
            const str = filename;
            console.log(str);
            let archive_filename = str.substr( 10 );
            API.graphql(
                graphqlOperation(gqlMutations.updateStreaming, {
                input: {
                    id: channelId,
                        archive: 1,
                        archive_file: archive_filename,
                        is_live: 0
                    }
                })
            )
            return {
                filename : archive_filename,
                error: error 
            }
        }else{
            API.graphql(
                graphqlOperation(gqlMutations.updateStreaming, {
                input: {
                    id: channelId,
                        is_live: 0
                    }
                })
            )
        }
    }
    // 録画を開始する
    const agoraRecording = async(token, channelId)=>{
        const recording_result  = await API.graphql(graphqlOperation(gqlQueries.agoraRecording, {event: 'startRecording', token: token , channelId : channelId }));
        return recording_result;
    }
    return [agoraStop,agoraRecording]
}
export default UseAgoraFunction