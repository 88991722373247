import Amplify, { Auth, API, graphqlOperation} from "aws-amplify";
import { useHistory} from 'react-router-dom';
// redux
import { useDispatch } from 'react-redux';
import { login_now, user_info} from '../actions';
// DB関連
import * as gqlQueries from '../graphql/queries'
import * as gqlMutations from '../graphql/mutations'

const UseStreaming = ()=>{
	// streamingテーブルにデータを登録する。通常配信
		const createStream = async({channel, data, input, userInfo,thumbnail=null, is_paid = 0, price = 0})=>{
			const tec_data = await API.graphql(
			graphqlOperation(gqlMutations.createTec, {
				input: input
			})
			)
			// dynamodbstreamingを作成
			await API.graphql(
			graphqlOperation(gqlMutations.createStreaming, {
			input: {
						id:	 channel,
						title: data.title,
						summary: data.summary,
						is_live: 0,
						thumbnail: thumbnail,
						user_id: userInfo.id,
						tec_id: tec_data.data.createTec.id,
						announce_url: data.url,
						viewerCount:0,
						archive:0,
						reserve: 0,
						reserve_peoples: 0,
						archive_file:'',
						max_viewerCount: 0,
						archiveCount: 0,
						is_paid: is_paid,
						price: price 
				}
			})
			)
	}
  // 予約配信
	const reserveStreaming = async({channel, data, input, userInfo, thumbnail = null, schedule, is_paid=0, price= 0})=>{
		const tec_data = await API.graphql(
		graphqlOperation(gqlMutations.createTec, {
			input: input
		})
		)
		// dynamodbstreamingを作成
		const result = await API.graphql(
		graphqlOperation(gqlMutations.createStreaming, {
		input: {
					id:	 channel,
					title: data.title,
					summary: data.summary,
					is_live: 0,
					thumbnail: thumbnail,
					user_id: userInfo.id,
					tec_id: tec_data.data.createTec.id,
					announce_url: data.url,
          viewerCount:0,
					archive:0,
          reserve: 1,
					reserveDate: schedule,
					archive_file:'',
					max_viewerCount: 0,
					archiveCount: 0,
					is_paid: is_paid,
					price: price 
			}
		})
		)
    return(result);
  }
	// 視聴予約
	const viewing_reserve = async(streamingId, userId)=>{
		// reserveテーブルに挿入を作成
		const result = await API.graphql(
		graphqlOperation(gqlMutations.createReserve, {
			input: {
					streaming_id: streamingId,
					user_id: userId,
					flag:0
			}
		})
		)
		return(result);
	}
	// 視聴予約を削除
	const delete_reserve = async(reserveId)=>{
		// reserveカラムを削除
		const result = await API.graphql(
		graphqlOperation(gqlMutations.deleteReserve, {
			input: {
				id: reserveId
			}
		})
		)
		return(result);
	}
  return {reserveStreaming, viewing_reserve, delete_reserve,createStream};
}
export default UseStreaming