import React, {useState, useEffect } from 'react';
// フォームを扱うライブラリ
import { useForm } from "react-hook-form";
import {useHistory} from 'react-router-dom';
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from 'uuid';
// redux
import {useDispatch ,useSelector} from 'react-redux';
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
// Atoms
import Button from '../Atoms/button';

// Molecules
import SelectBox from '../Molecules/selectBox';
import Modal from '../Molecules/modal';
import SuccessSlider from '../Molecules/success_slider.js';
import Schedule from '../Molecules/schedule.js';

// Containers
import UseToggle from '../../containers/toggle.js';
import UseUuid from '../../containers/uuid.js';
import Uses3Upload from '../../containers/s3-upload.js';
import UseStreaming from '../../containers/streamingFunction.js';

import * as gqlQueries from '../../graphql/queries'
import * as gqlMutations from '../../graphql/mutations'

function LiveCog(props){
	// ログイン中のユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
	// 今の日付と時間を管理
	const reserve_schedule = useSelector(state => state.now_schedule.date);
	// react-hook-formで使用する
	const { register, handleSubmit, watch, errors } = useForm({criteriaMode: "all"});
	const [ is_loading, setLoading ] = useState(false);
	const [is_select, setSelect] = UseToggle(false);
	let history = useHistory();
	const inputRef = React.useRef();
	const [channel, setChannel] = useState(uuidv4());
	const [src, setSrc] = useState(null);
	const [filetype, setType] = useState(null);
	const [toggle, modalToggle] = UseToggle(false);
	const [succeeded, modalSuccess] = UseToggle(false);
	const [disabled, setDisabled] = useState(false);
	const [fileUrl, setFileUrl ] =  useState('');
	const [is_checked, checkToggle] = UseToggle(true);
	const [number, setNumber ] =  useState(5);
	const [paidState, paidToggle] = UseToggle(false);
	// s3にアップロード
	const [upload] = Uses3Upload();
	//配信関連の関数 
	// const {reserveStreaming,createStream} = UseStreaming();
	const streamingFunction = UseStreaming();
	// 選択中の言語
	const [tecs, setTec] = useState([]);

	const array ={
		JavaScript: null,
		Python: null,
		Ruby: null,
		SQL: null,
		HTML: null,
		Nextjs: null,
		Vuejs : null,
		Rails: null,
		Go:null,
		AWS: null,
		TypeScript: null,
		PHP: null,
		Laravel: null,
		Docker: null,
		Rust: null,
		COBOL: null,
		R: null,
		Java: null,
		Swift: null,
		Git: null,
		React: null,
	} 
	tecs.map(lang =>{
		if(lang == 'Next.js'){
			array['Nextjs'] = lang
		}else if(lang == 'Vue.js'){
			array['Vuejs'] = lang
		}else{
			array[lang] = lang
		}
	});

	const input ={
			JavaScript: array['JavaScript'],
			Python: array['Python'],
			Ruby: array['Ruby'],
			SQL: array['SQL'],
			HTML: array['HTML'],
			Nextjs: array['Nextjs'],
			Vuejs : array['Vuejs'],
			Rails: array['Rails'],
			Go: array['Go'],
			AWS: array['AWS'],
			TypeScript: array['TypeScript'],
			PHP: array['PHP'],
			Laravel: array['Laravel'],
			Docker: array['Docker'],
			Rust: array['Rust'],
			COBOL: array['COBOL'],
			R: array['R'],
			Java: array['Java'],
			Swift: array['Swift'],
			Git: array['Git'],
			React: array['React'],
	}

	useEffect(()=>{
    // LambdaからAgoraトークンを取得する
    (async function getChannel(){
				await API.graphql(graphqlOperation(gqlQueries.getAgoraToken, { channel: channel }));
    })();
	},[]
	)
	// ライブ配信の設定を行う。
	async function settingLive(data){
		setLoading(true);
    nprogress.start();
		try{
			// デフォルトのサムネイルで設定
			if(fileUrl == ''){
				const args = {
					channel: channel,
					data: data,
					input: input,
					userInfo: userInfo,
					is_paid: paidState ? 1 : 0,  
					price: data.price ? Math.round(data.price) : 0
				}
				await streamingFunction.createStream(args);
				setLoading(false);
				nprogress.done();
				// Livestreamingへリダイレクト
				history.push('/livestreaming/' + channel);
			}else{
				// サムネイルをアップロードした配信
				// uuidを作成
				const [uuid] = await UseUuid();
				let imageData = await fetch(fileUrl);
				let blobData = await imageData.blob();
				// s3にアップロードする
				let key = await upload(uuid,blobData,filetype);			
				const args = {
					channel: channel,
					data: data,
					input: input,
					userInfo: userInfo,
					thumbnail: key.key,
					is_paid: paidState ? 1 : 0,  
					price: data.price ? Math.round(data.price) : 0
				}
				await streamingFunction.createStream(args);
				setLoading(false);
				nprogress.done();
				// Livestreamingへリダイレクト
				history.push('/livestreaming/' + channel);
			}
		}catch(e){
			setLoading(false);
			nprogress.done();
			console.log(e);
			alert("エラーが起こりました。時間をおいて再度お試しください。");
		}
	}

	//予約配信
	const reserveLive = async(data)=>{
		setLoading(true);
    nprogress.start();
		var date_obj = new Date(reserve_schedule.year,(reserve_schedule.month - 1),reserve_schedule.day, reserve_schedule.hours,reserve_schedule.minutes);
		// yyyy-MM-ddTHH:mm:ss の形に変換する
		let date_str = date_obj.toISOString();
		try{
			// デフォルトのサムネイルで設定
			if(fileUrl == ''){
				const args = {
					channel: channel,
					data: data,
					input: input,
					userInfo: userInfo,
					schedule: date_str,
					is_paid: paidState ? 1 : 0,  
					price: data.price ? Math.round(data.price) : 0
				}
				await streamingFunction.reserveStreaming(args);
				setLoading(false);
				nprogress.done();
        // succeededのステートを変更
				modalSuccess();
				setDisabled(true);
			}else{
				// サムネイルをアップロードした配信
				// uuidを作成
				const [uuid] = await UseUuid();
				let imageData = await fetch(fileUrl);
				let blobData = await imageData.blob();
				// s3にアップロードする
				let key = await upload(uuid,blobData,filetype);			
				const args = {
					channel: channel,
					data: data,
					input: input,
					userInfo: userInfo,
					thumbnail: key.key,  
					schedule: date_str,
					is_paid: paidState ? 1 : 0,  
					price: data.price ? Math.round(data.price) : 0
				}
				await streamingFunction.reserveStreaming(args);
				setLoading(false);
				nprogress.done();
        // succeededのステートを変更
				modalSuccess();
				setDisabled(true);
			}
		}catch(e){
			setLoading(false);
			nprogress.done();
			alert("エラーが起こりました。時間をおいて再度お試しください。");
		}
	}

	// inputを擬似クリック
	const handleClick = ()=> inputRef.current.click();

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
			let selectedFile = e.target.files[0];
			setType(selectedFile.type)
			const reader = new FileReader();
			let imgtag = document.getElementById("myimage");
			imgtag.title = selectedFile.name;
			reader.addEventListener('load', () =>
				setSrc(reader.result)
			);       
			// サムネイルの箇所にプレビューさせる
			reader.onload = function(event) {
				imgtag.src = event.target.result;
			};
			reader.readAsDataURL(e.target.files[0]);
			modalToggle();
			// valueを初期化
			let inputfile = document.getElementById("inputfile");
			inputfile.value = '';
    }
	};
	// リアルタイムでフォームを監視
  watch('title')
  watch('summary')
  watch('price')
  // 隠れてるチェックボックスをトグル
  const checktoggle = (e)=>{
    checkToggle();
  }
	return(
			<React.Fragment>
				<Modal src={src} targetID='modal' modalToggle={modalToggle} toggle={toggle} setFileUrl={setFileUrl} fileUrl={fileUrl}/>
				{/* 成功したことを知らせるスライド */}
				<SuccessSlider modalToggle={modalSuccess} toggle={succeeded} message="予約が開始されました" />
						<div className="h-fitcontent w-8/12 mx-auto my-10 border-solid border border-gray-200 shadow-md rounded">
							<div className="">
								<h2 className="text-center font-semibold text-2xl my-5">配信設定</h2>
								<div className="w-full m-auto">
									<div className="container m-auto flex">
										{/*プロフィールアイコン*/}
										<div className="relative setting-img w-68 mx-5 text-center">
											<label className="text-gray-700 font-bold mb-2 block">サムネイル</label>
											<div className="mb-8 w-full h-44">
												{											
													fileUrl != '' &&
													<img 
														className="w-full h-full rounded-lg shadow-lg antialiased cursor-pointer hover:opacity-50 object-cover m-auto" 
														id="myimage" 
														alt="Crop" 
														src={fileUrl} 
														onClick={handleClick}
													/>
												}
												{											
													fileUrl == '' &&
													<img 
														src="/images/home-logo.png" 
														className="w-full h-full rounded-lg shadow-lg antialiased cursor-pointer hover:opacity-50 object-cover m-auto" 
														id="myimage" 
														onClick={handleClick} 
														alt="thumbnail"
													/>
												}
												<input id="inputfile" className="hidden" type="file" ref={inputRef} onChange={onSelectFile} />
											</div>
										</div>
										<div className="m-auto w-9/12">
											<label className="text-gray-700 font-bold">タイトル</label>
											<input 
												name="title" 
												className={"border border-gray-300 focus:outline-none rounded w-full my-1 py-2 px-3 md:text-sm text-base" + (errors.title ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
												ref={register({ required: true })} 
												type="text" 
												placeholder="タイトル"
											/>						
											{errors.title && <span className="text-red-600 font-semibold text-xs block mb-1">タイトルを入力してください</span>}
											<label className="text-gray-700 font-bold">概要</label>
											<textarea 
												name="summary" 
												className={"focus:outline-none mt-1 border-gray-300 rounded border px-3 py-2 md:text-ml w-full h-40 md:text-sm text-base" + (errors.summary ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
												placeholder="概要" 
												ref={register({ required: true })} 
												/>
											{errors.summary && <span className="text-red-600 font-semibold text-xs block mb-1">概要を入力してください</span>}
											<label className="text-gray-700 font-bold flex">
												<span className="mr-1">告知したいURL</span>
												<a href="https://puzzled-apogee-3f0.notion.site/URL-c2006ccfe7ae487182a01c9397624752" 
												target="_blank"
												className="my-auto">
													<svg className="h-5 w-5 cursor-pointer hover:opacity-75" xmlns="http://www.w3.org/2000/svg" width="192" height="192" fill="#1e6dc2" viewBox="0 0 256 256">
														<rect width="256" height="256" fill="none"></rect>
														<circle cx="128.00244" cy="128" r="96" opacity="0.2"></circle>
														<circle cx="128.00244" cy="128" r="96" fill="none" stroke="#1e6dc2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></circle>
														<circle cx="128.00244" cy="180" r="12"></circle>
														<path d="M128.00194,144.0045v-8a28,28,0,1,0-28-28" fill="none" stroke="#1e6dc2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></path>
													</svg>
												</a>
											</label>
											<input 
												name="url" 
												className={"border border-gray-300 focus:outline-none rounded w-full my-1 py-2 px-3 md:text-sm text-base" + (errors.url ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
												type="text" 
												ref={register({ required: false })} 
												placeholder="https://stacklive.jp"
											/>						
											<label className="text-gray-700 font-bold">ジャンル</label>
											{/*セレクトボックス*/}
											<SelectBox setTec={setTec} state={tecs} is_select={is_select} selectToggle={setSelect}/>
											<label className="inline-flex items-center mt-3 cursor-pointer">
												<input type="checkbox" className="form-checkbox h-5 w-5 text-blue-600 rounded" onClick={()=>paidToggle()}/>
												<span className="ml-2 text-gray-700 font-bold">有料にする</span>
											</label>
											<div className={(paidState ? " flex flex-row mt-1" : " hidden")}>
												<span className="flex items-center bg-grey-lighter rounded rounded-r-none pr-3 font-bold text-grey-darker">¥</span>
												<input 
													name="price" 
													className={"border border-gray-300 focus:outline-none rounded my-1 py-1 px-3 md:text-lg text-base" + (errors.price ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
													ref={register({ 
														required: paidState ? '金額を入力してください' : false,
														valueAsNumber: true,
														min: {value : 50, message: '50円以上を設定してください'},
													})} 
													type="number" 
													step="1"
													placeholder="500"
												/>						
											</div>
											{paidState ? errors.price && <span className="w-full text-red-600 font-semibold text-xs block mb-1 px-10">{errors.price.message}</span>: ''}
											<div className="block text-right">
												<div className="bg-gray-200 text-sm text-gray-500 leading-none border-2 border-gray-200 rounded-full inline-flex">
													<button 
														onClick={checktoggle} 
														className={"inline-flex items-center transition-colors focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-full px-3 py-2" + (is_checked ? " bg-white rounded-full text-blue-400" : " ")}>
														<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-screen-share w-4 h-4 mr-1" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
															<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
															<path d="M21 12v3a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h9" />
															<line x1="7" y1="20" x2="17" y2="20" />
															<line x1="9" y1="16" x2="9" y2="20" />
															<line x1="15" y1="16" x2="15" y2="20" />
															<path d="M17 4h4v4" />
															<path d="M16 9l5 -5" />
														</svg>
														<span>今すぐ配信</span>
													</button>
													<button 
														onClick={checktoggle} 
														className={"inline-flex items-center transition-colors focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-full px-3 py-2" + (is_checked ? " " : " bg-white rounded-full text-blue-400")}>
														<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-check w-4 h-4 mr-1" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
															<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
															<circle cx="12" cy="12" r="2" />
															<path d="M12 19c-4 0 -7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7c-.42 .736 -.858 1.414 -1.311 2.033" />
															<path d="M15 19l2 2l4 -4" />
														</svg>
														<span>配信予約</span>
													</button>
												</div>
											</div>
											{
												is_checked == false &&
													<div className="custom-number-input block">
														<label htmlFor="datepicker" className="font-bold mb-1 text-gray-700 block mt-5">予約日時</label>
														<Schedule/>
													</div>
											}
									</div>
									</div>
								</div>
							</div>
							<div className="text-center my-6">
								{
									is_checked ?
									<Button 
										size="streamingconfig" 
										type={disabled ? "disabled" : "blue"} 
										children="配信ページへ ▶" func={handleSubmit(settingLive)} 
										disabled={disabled} 
										is_loading={is_loading}
									/>
									:
									<Button 
										size="streamingconfig" 
										type={disabled ? "disabled" : "reserve"} 
										children="配信予約" 
										func={handleSubmit(reserveLive)} 
										disabled={disabled} 
										is_loading={is_loading}
									/>
								}
							</div>
						</div>
			</React.Fragment>
	)
}
export default LiveCog
