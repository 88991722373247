import React, { Suspense } from 'react';
import Footer from '../Organisms/footer.js';
import Header from '../Organisms/Header.js';
import AnalyticsTemp from '../Templates/analyticsTemp.js';
import UseFetchResource from '../../containers/fetchResource.js';
// amplify
import Amplify, { API, graphqlOperation} from "aws-amplify";

// DB関連
import * as gqlQueries from '../../graphql/queries'

// redux
import {useSelector} from 'react-redux';

function Analytics (){
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
	const [wrapPromise] = UseFetchResource();

	const streamingLists = API.graphql(graphqlOperation(gqlQueries.listStreamings,{
    filter:{
      user_id:{eq: 
        userInfo.id
      }
      }})
  )
  const fetchResource = wrapPromise(streamingLists)

  return(
			<React.Fragment>
				<Header />
          <AnalyticsTemp user={userInfo} resource={fetchResource}/>
				<Footer />
			</React.Fragment>
  )
}
export default Analytics