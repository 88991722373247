import config from "../../aws-exports";
import VideoPlayer from './video_player.js'

export default function Video(props){
  const streamingInfo = props.resource.read().data.getStreaming;
	// s3のディレクトリ
	const s3_dir = 'https://stacklive-bucket233022-production.s3-'+ config.aws_user_files_s3_bucket_region +'.amazonaws.com/recording/' + streamingInfo.archive_file;
  let hlsOptions = {
    "withCredentials": true,
    "overrideNative": true
  }
  const videoJsOptions = {
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    controls: true,
    autoplay: true,
    playsinline: true,
    "html5": {
      "nativeAudioTracks": false,
      "nativeVideoTracks": false,
      "hls": hlsOptions
    },
    sources: [
      {
        src: s3_dir,
        type: "video/mp4",
      },
    ],
  };
  return(
      <div className=" md:h-4/5 h-72">
        <VideoPlayer {...videoJsOptions} channel={streamingInfo}/>
      </div>
    )
}