/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAgoraToken = /* GraphQL */ `
  query GetAgoraToken($channel: String) {
    getAgoraToken(channel: $channel) {
      channel
      token
    }
  }
`;
export const lambdaTransaction = /* GraphQL */ `
  query LambdaTransaction(
    $event: String
    $id: String
    $userId: String
    $name: String
    $tablename: String
    $receive_user: String
    $send_user: String
    $remain_point: Int
    $get_point: Int
  ) {
    lambdaTransaction(
      event: $event
      id: $id
      userId: $userId
      name: $name
      tablename: $tablename
      receive_user: $receive_user
      send_user: $send_user
      remain_point: $remain_point
      get_point: $get_point
    ) {
      data
    }
  }
`;
export const stripeFunction = /* GraphQL */ `
  query StripeFunction(
    $event: String
    $amount: Int
    $custom_account: String
    $account_number: String
    $account_name: String
    $routing_number: Int
    $transfer: String
    $type: String
    $card_token: String
    $customer: String
    $paymentIntent: String
  ) {
    stripeFunction(
      event: $event
      amount: $amount
      custom_account: $custom_account
      account_number: $account_number
      account_name: $account_name
      routing_number: $routing_number
      transfer: $transfer
      type: $type
      card_token: $card_token
      customer: $customer
      paymentIntent: $paymentIntent
    ) {
      account
      id
      amount
      charges_enabled
      payouts_enabled
      account_link
      bank_name
      last4
      routing_number
      bank_holder_name
      year
      month
      brand
      source
      historyAmount
      historyCreated
    }
  }
`;
export const agoraRecording = /* GraphQL */ `
  query AgoraRecording(
    $event: String
    $token: String
    $channelId: String
    $resourceId: String
    $sid: String
  ) {
    agoraRecording(
      event: $event
      token: $token
      channelId: $channelId
      resourceId: $resourceId
      sid: $sid
    ) {
      resourceId
      sid
      filename
      error
    }
  }
`;
export const pushIfttt = /* GraphQL */ `
  query PushIfttt($channelId: String) {
    pushIfttt(channelId: $channelId) {
      data
    }
  }
`;
export const getStreaming = /* GraphQL */ `
  query GetStreaming($id: ID!) {
    getStreaming(id: $id) {
      id
      title
      summary
      is_live
      thumbnail
      user_id
      tec_id
      viewerCount
      max_viewerCount
      archive
      archive_file
      archiveCount
      reserve
      reserveDate
      announce_url
      reserve_peoples
      is_paid
      price
      createdAt
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      tec {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listStreamings = /* GraphQL */ `
  query ListStreamings(
    $filter: ModelStreamingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStreamings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        summary
        is_live
        thumbnail
        user_id
        tec_id
        viewerCount
        max_viewerCount
        archive
        archive_file
        archiveCount
        reserve
        reserveDate
        announce_url
        reserve_peoples
        is_paid
        price
        createdAt
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      user_id
      tec_id
      logininfo
      icon
      profile
      all_viewerCount
      createdAt
      updatedAt
      tec {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      buy_point
      receive_point
      custom_account
      customer
      twitter
      github
      homepage
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      uid
      channel
      comment
      icon
      gift
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uid
        channel
        comment
        icon
        gift
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTec = /* GraphQL */ `
  query GetTec($id: ID!) {
    getTec(id: $id) {
      id
      JavaScript
      Python
      Ruby
      SQL
      HTML
      Nextjs
      Vuejs
      Rails
      Go
      AWS
      TypeScript
      PHP
      Laravel
      Docker
      Rust
      COBOL
      R
      Java
      Swift
      Git
      React
      createdAt
      updatedAt
    }
  }
`;
export const listTecs = /* GraphQL */ `
  query ListTecs(
    $filter: ModelTecFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTecs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReserve = /* GraphQL */ `
  query GetReserve($id: ID!) {
    getReserve(id: $id) {
      id
      streaming_id
      user_id
      flag
      createdAt
      updatedAt
    }
  }
`;
export const listReserves = /* GraphQL */ `
  query ListReserves(
    $filter: ModelReserveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReserves(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        streaming_id
        user_id
        flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotices = /* GraphQL */ `
  query GetNotices($id: ID!) {
    getNotices(id: $id) {
      id
      receive_user
      send_user
      contents
      streaming_id
      flag
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      streaming {
        id
        title
        summary
        is_live
        thumbnail
        user_id
        tec_id
        viewerCount
        max_viewerCount
        archive
        archive_file
        archiveCount
        reserve
        reserveDate
        announce_url
        reserve_peoples
        is_paid
        price
        createdAt
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNoticess = /* GraphQL */ `
  query ListNoticess(
    $filter: ModelNoticesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNoticess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        receive_user
        send_user
        contents
        streaming_id
        flag
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        streaming {
          id
          title
          summary
          is_live
          thumbnail
          user_id
          tec_id
          viewerCount
          max_viewerCount
          archive
          archive_file
          archiveCount
          reserve
          reserveDate
          announce_url
          reserve_peoples
          is_paid
          price
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaid = /* GraphQL */ `
  query GetPaid($id: ID!) {
    getPaid(id: $id) {
      id
      streaming_id
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const listPaids = /* GraphQL */ `
  query ListPaids(
    $filter: ModelPaidFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaids(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        streaming_id
        user_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCreatedAt = /* GraphQL */ `
  query ByCreatedAt(
    $is_live: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStreamingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCreatedAt(
      is_live: $is_live
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        summary
        is_live
        thumbnail
        user_id
        tec_id
        viewerCount
        max_viewerCount
        archive
        archive_file
        archiveCount
        reserve
        reserveDate
        announce_url
        reserve_peoples
        is_paid
        price
        createdAt
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const byTrendSort = /* GraphQL */ `
  query ByTrendSort(
    $is_live: Int
    $viewerCount: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStreamingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTrendSort(
      is_live: $is_live
      viewerCount: $viewerCount
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        summary
        is_live
        thumbnail
        user_id
        tec_id
        viewerCount
        max_viewerCount
        archive
        archive_file
        archiveCount
        reserve
        reserveDate
        announce_url
        reserve_peoples
        is_paid
        price
        createdAt
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const byArchiveSort = /* GraphQL */ `
  query ByArchiveSort(
    $archive: Int
    $archiveCount: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStreamingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byArchiveSort(
      archive: $archive
      archiveCount: $archiveCount
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        summary
        is_live
        thumbnail
        user_id
        tec_id
        viewerCount
        max_viewerCount
        archive
        archive_file
        archiveCount
        reserve
        reserveDate
        announce_url
        reserve_peoples
        is_paid
        price
        createdAt
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const byArchiveCreated = /* GraphQL */ `
  query ByArchiveCreated(
    $archive: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStreamingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byArchiveCreated(
      archive: $archive
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        summary
        is_live
        thumbnail
        user_id
        tec_id
        viewerCount
        max_viewerCount
        archive
        archive_file
        archiveCount
        reserve
        reserveDate
        announce_url
        reserve_peoples
        is_paid
        price
        createdAt
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const byReserveDate = /* GraphQL */ `
  query ByReserveDate(
    $reserve: Int
    $reserveDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStreamingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byReserveDate(
      reserve: $reserve
      reserveDate: $reserveDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        summary
        is_live
        thumbnail
        user_id
        tec_id
        viewerCount
        max_viewerCount
        archive
        archive_file
        archiveCount
        reserve
        reserveDate
        announce_url
        reserve_peoples
        is_paid
        price
        createdAt
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const byUserRanking = /* GraphQL */ `
  query ByUserRanking(
    $logininfo: Int
    $all_viewerCount: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUserRanking(
      logininfo: $logininfo
      all_viewerCount: $all_viewerCount
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      nextToken
    }
  }
`;
export const customerByUserid = /* GraphQL */ `
  query CustomerByUserid(
    $user_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByUserid(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      nextToken
    }
  }
`;
export const createdAtNotice = /* GraphQL */ `
  query CreatedAtNotice(
    $receive_user: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNoticesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    createdAtNotice(
      receive_user: $receive_user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        receive_user
        send_user
        contents
        streaming_id
        flag
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        streaming {
          id
          title
          summary
          is_live
          thumbnail
          user_id
          tec_id
          viewerCount
          max_viewerCount
          archive
          archive_file
          archiveCount
          reserve
          reserveDate
          announce_url
          reserve_peoples
          is_paid
          price
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
