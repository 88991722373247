import React, {useState, useEffect } from 'react';
import Amplify, { API, graphqlOperation } from "aws-amplify";
import {Link} from 'react-router-dom';
import Header from '../Organisms/Header.js';
import Footer from '../Organisms/footer.js';
import BlackModal from '../Molecules/black-modal';
import LeftMenu from '../Organisms/LeftMenu.js';
import SmLeftMenu from '../Organisms/sm-leftMenu.js';

// Molecules
import SuccessPopup from '../Molecules/success-popup.js';
// redux
import {useDispatch ,useSelector} from 'react-redux';
import '../../style/setting.css';
import 'nprogress/nprogress.css'
import Spinner from '../Atoms/spinner';
import UseToggle from '../../containers/toggle.js';

function Point(){
	const [ is_loading, setLoading ] = useState(false);
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
  let buy_point = '';
  if(userInfo.buy_point){
    buy_point = userInfo.buy_point;
  }else{
    buy_point = 0;
  }
	//  successモーダルのトグル
	const [now_success, successView] = UseToggle(false);

	return(
			<React.Fragment>
			<BlackModal targetID='modal' />
			<Header/>
			<div className="flex min-h-screen pt-16">
				{/* PCレフトメニュー */}
				<LeftMenu/>
				{/* SMレフトメニュー */}
				<SmLeftMenu/>
				<div className="md:w-3/6 w-full md:mx-auto md:mt-20 md:mt-10 mt-0 h-full md:border-solid md:border md:border-gray-200 md:shadow-md rounded">
					<div className="h-auto">
						<h2 className="text-center font-semibold text-2xl md:my-8 mt-8 mb-4">ダッシュボード</h2>
						<div className="w-11/12 m-auto">
							<nav className="flex flex-row overflow-x-auto whitespace-nowrap hash_tag">
                <Link to="/dashboard" className="w-1/2 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-500 focus:outline-none">
                  報酬
                </Link>
								<Link to="/dashboard/point" className="w-1/2 text-gray-600 text-center py-3 md:px-4 px-2 block hover:text-blue-400 focus:outline-none border-b-2 font-medium border-blue-600 text-blue-600">
									StackPoint
                </Link>
							</nav>
							<div>
                <h2 className="text-center text-xl mt-8 my-4">現在のStackPoint</h2>
                  <div className="w-full px-6">
                      <div className="flex justify-center items-center px-5 py-6 shadow-sm rounded-md bg-gray-100">
                          <div className="p-2 rounded-full bg-yellow-500 bg-opacity-75">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-stripe" width="24" height="24" viewBox="0 0 24 24" strokeWidth="0" stroke="#ffffff" fill="#ffffff" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                              <path d="M11.453 8.056c0 -.623 .518 -.979 1.442 -.979c1.69 0 3.41 .343 4.605 .923l.5 -4c-.948 -.449 -2.82 -1 -5.5 -1c-1.895 0 -3.373 .087 -4.5 1c-1.172 .956 -2 2.33 -2 4c0 3.03 1.958 4.906 5 6c1.961 .69 3 .743 3 1.5c0 .735 -.851 1.5 -2 1.5c-1.423 0 -3.963 -.609 -5.5 -1.5l-.5 4c1.321 .734 3.474 1.5 6 1.5c2.004 0 3.957 -.468 5.084 -1.36c1.263 -.979 1.916 -2.268 1.916 -4.14c0 -3.096 -1.915 -4.547 -5.003 -5.637c-1.646 -.605 -2.544 -1.07 -2.544 -1.807z" />
                            </svg>
                          </div>
                          <div className="mx-5">
                              <h4 className="text-2xl font-semibold text-gray-700">{buy_point}</h4>
                          </div>
                      </div>
                  </div>
                <p className="text-gray-500 font-semibold text-sm mt-4 px-6">※購入したStackPointです。</p>
								<div className="text-center my-6">
                <Link to="/payment" className="main-background hover:opacity-75 text-white font-bold px-4 py-3 text-ml rounded focus:outline-none">
                  {is_loading ? 
                  <Spinner/> 
                  : 
                  "StackPoint購入"}
                </Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<SuccessPopup func={successView} toggle={now_success}>変更を保存しました。</SuccessPopup>
			</div>
			<Footer/>
			</React.Fragment>
	)
}
export default Point