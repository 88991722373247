import React, {useState, useTransition, useEffect,useMemo  } from 'react';
import Amplify, { Auth, Hub, API, graphqlOperation} from "aws-amplify";
// ログインしてからしか見れないページ
import PrivateRoute from './privateRoute.js';
import FirstRoute from './firstRoute.js';
// ログインする前しか入れないページ
import GuestRoute from './guestRoute.js';
// ページ遷移する際に一番上から表示させる為にラップする
import ScrollToTop from './components/Pages/scrollTop';
import {BrowserRouter, Route, Switch,useHistory, withRouter} from 'react-router-dom';
import Default from './components/Pages/Default.js';
// 独自のページを持つもの
import Login from './components/Login.js';
import Register from './components/Register.js';
import Viewing from './components/Pages/Viewing.js';
import Livestreaming from './components/Pages/Livestreaming.js';
import Welcome from './components/Pages/welcome.js';
import Profile from './components/Pages/profile.js';
import Tech from './components/tech.js';
import Forgot from './components/Forgot.js';
import Confirm from './components/Confirm.js';
import Chat from './components/Pages/Chat.js';
import Privacy from './components/Pages/privacy.js';
import Terms from './components/Pages/terms.js';
import Company from './components/Pages/company.js';
import About from './components/Pages/about.js';
import AboutCompany from './components/Pages/about_company';
import Dashboard from './components/Pages/dashboard.js';
import Point from './components/Pages/point.js';
import PlayArchive from './components/Pages/play_archive.js';
import Mypage from './components/Pages/Mypage.js';
import Analytics from './components/Pages/analytics.js';

// DB関連
import * as gqlQueries from './graphql/queries'
// Atoms
import Spinner from './components/Atoms/spinner';
// redux
import { useSelector } from 'react-redux';
// container
import UseCurrentUser  from './containers/currentUser.js';

const App = ()=> {
	// 新規登録するユーザーテーブル名を環境変数から取得。ローカル開発ではenvファイルを作成して定義する。本番環境ではAmplifyで定義してある。
	const tablename = process.env.REACT_APP_USER_TABLE;
	{/*storeから参照*/}
	const [bool,currentUser] = UseCurrentUser(false);
	// ユーザー状態が変更した事を検知
	const listener = useSelector(state => state.change_user.listener);
	// snsログインイベントを受け取る
	Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
				// ログインした後の処理
        case "signIn":
					Auth.currentUserInfo().then((user)=>{
						(async ()=>{
							const userInfo = await API.graphql(graphqlOperation(gqlQueries.getUser, { id: user.username }));
							// 新規登録か否か
							if(userInfo.data.getUser){
							}else{
								await API.graphql(graphqlOperation(gqlQueries.lambdaTransaction, { event: 'Put' , id: user.username, name: 'unknown', tablename: tablename}))
								//DBに値を入れ終わったタイミングでstateを更新する 
								currentUser();
							}
						})();
					});
          break;
        case "signOut":
          break;
				case 'cognitoHostedUI':
          break;
        case "customOAuthState":
      }
  });

	useEffect(()=>{
		// ログイン中か確認
		currentUser();
	},[listener]);
	return (
		<BrowserRouter>
			<React.Fragment>
				<ScrollToTop>
						{
							bool ?
							<Switch>
								<GuestRoute path="/login" children={<Login />} />
								<GuestRoute path="/register" children={<Register />} />
								<PrivateRoute path="/profile" children={<Profile />} />
								<PrivateRoute path="/dashboard" exact={true} children={<Dashboard/>} />
								<PrivateRoute path="/dashboard/point" exact={false} children={<Point/>} />
								<PrivateRoute path="/user/:user_id/edit" exact={false} children={<Default/>} />
								<PrivateRoute path="/user/:user_id/bank_account" exact={false} children={<Default/>} />
								<PrivateRoute path="/user/:user_id/card_info" exact={false} children={<Default/>} />
								<PrivateRoute path="/user/:user_id/transfer_history" exact={false} children={<Default/>} />
								<PrivateRoute path="/user/:user_id/analytics" exact={false} children={<Analytics/>} />
								<FirstRoute path="/welcome" children={<Welcome />} />
								{/* <Route path="/welcome" exact component={Welcome} /> */}
								<PrivateRoute path="/tech" children={<Tech />} />
								<Route path="/forgot" exact component={Forgot} />
								<Route path="/confirm" exact component={Confirm} />
								<Route path="/privacy" exact component={Privacy} />
								<Route path="/terms" exact component={Terms} />
								<Route path="/company" exact component={Company} />
								<Route path="/about" exact component={About} />
								<Route path="/lp/for_company" exact component={AboutCompany} />
								<Route path="/chat/:channelId" exact component={Chat} />
								<Route path="/viewing/:channelId" exact component={Viewing} />
								<Route path="/livestreaming/:channelId" exact component={Livestreaming} />
								<Route path="/user/:user_id/archive/:channelId" exact component={PlayArchive} />
								<Route path='/user/:user_id' component={Mypage} exact={true}/>
								<Route path='/user/:user_id/reserve' component={Mypage} exact={true}/>
								<Route path="/" component={Default} />
							</Switch>
							:
							<div className="h-screen flex">
								<Spinner size="lg"/>
							</div>
						}
				</ScrollToTop>
			</React.Fragment>
		</BrowserRouter>
	)
}

export default App;
