import List from '../Molecules/list';
import '../../style/menu.css';
// redux
import {useSelector, useDispatch } from 'react-redux';
import { boolean_menu } from '../../actions';

function LeftMenu(props){
	const dispatch = useDispatch();
	let menu = useSelector(state => state.booleanList.menu);
	const menuToggle = ()=>{
		dispatch(boolean_menu());
	}
	return(
		<div className={"hidden md:block z-10 bg-white" + (menu ?" w-1/6" : " ")} onMouseEnter={menuToggle} onMouseLeave={menuToggle} >
			<List toggle={menu}/>
		</div>
	)
}
export default LeftMenu
