import React, { Suspense } from 'react';
import ViewingTemp from '../Templates/ViewingTemp.js';
import { useParams } from "react-router";

// Organisms
import Header from '../Organisms/Header.js';
import LeftMenu from '../Organisms/LeftMenu.js';
import SmLeftMenu from '../Organisms/sm-leftMenu.js';
// amplify
import Amplify, { API, graphqlOperation} from "aws-amplify";
// redux
import {useSelector } from 'react-redux';
import UseFetchResource from '../../containers/fetchResource.js';
// DB関連
import * as gqlQueries from '../../graphql/queries'

function Viewing(props){
  // パラメータから現在アクセスしているチャンネルを取得
  let { channelId } = useParams();
	const [wrapPromise] = UseFetchResource();
  const streamingInfo = API.graphql(graphqlOperation(gqlQueries.getStreaming, { id: channelId }));
  const streamingResource = wrapPromise(streamingInfo)

	return(
		<React.Fragment>
      <Header/>
      <div className="max-h-screen pt-16">
        <div className="flex md:flex-row flex-col w-full h-full">
				  {/* SMレフトメニュー */}
          <SmLeftMenu/>
				  {/* PCレフトメニュー */}
          <LeftMenu />
          <Suspense fallback={<React.Fragment></React.Fragment>}>
            <ViewingTemp resource={streamingResource}/>
          </Suspense>
        </div>
      </div>
		</React.Fragment>
	)
}
export default Viewing
