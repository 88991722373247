// 投げ銭のメッセージなしギフト一覧
function NormalGift(props){
  return(
    <div className="w-full px-6 flex flex-wrap mt-5">
      {
        props.content.map((gift, key)=>
          <div className="w-1/3 my-4" key={key}>
            <div className="flex flex-col">
              <img className="h-10 m-auto cursor-pointer hover:opacity-75" src={"/images/giftlogo/"+(gift[0])} data-logo={gift[0]} data-point={gift[1]} data-message='0' onClick={props.func}></img>
              <p className="text-center mt-2">{gift[1]}pt</p>
            </div>
          </div>
        )
      }
    </div>
  )
}
export default NormalGift