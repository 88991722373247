function SuccessPopup({children, func, toggle}){
  return(
    <div className={"bg-green-100 rounded-md p-3 flex fixed bottom-5 md:right-4 right-2" + (toggle ? " block" : " hidden")}>
        <svg
            className="stroke-2 stroke-current text-green-600 md:h-6 md:w-6 h-5 w-5 md:mr-2 mr-1 flex-shrink-0"
            viewBox="0 0 24 24"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M0 0h24v24H0z" stroke="none" />
            <circle cx="12" cy="12" r="9" />
            <path d="M9 12l2 2 4-4" />
        </svg>
        <div className="text-green-700">
            <div className="font-bold md:text-base text-sm">{children}</div>
        </div>
          <svg className="md:w-6 md:h-6 h-5 w-5 cursor-pointer text-green-600 inline hover:opacity-75" onClick={func} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.8} d="M6 18L18 6M6 6l12 12" />
          </svg>
    </div>
  )
}
export default SuccessPopup