// redux
import {useSelector} from 'react-redux';
// フォームを扱うライブラリ
import { useForm } from "react-hook-form";
import Spinner from '../Atoms/spinner';

function SendGift(props){
	// react-hook-formで使用する
	const { register, handleSubmit, watch, errors } = useForm();
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
  return(
    <div className="w-full">
      <div className="w-full">
        <img className="h-10 m-auto" src={"/images/giftlogo/"+(props.click_logo)}></img>
        <p className="text-center">{props.click_point}pt</p>
        {
          props.is_message ?
            <div>
              <textarea 
                name="message"
                className={"focus:outline-none mt-4 border-gray-300 appearance-none rounded border-2 px-3 py-2 md:text-ml w-full h-72" + (errors.message ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
                ref={register({ required: true })} 
                placeholder="コメントを入力" 
              />
              {errors.message && <span className="text-red-600 font-semibold text-sm">コメントを入力してください</span>}
            </div>
          :
          <p></p>
        }
      </div>
      <p className="text-center mt-8 mb-5">所持ポイント：{userInfo.buy_point}→<span className="text-red-600 font-bold">{props.remain_point}</span></p>
      <button 
        className="flex m-auto main-background hover:opacity-75 text-white font-bold px-4 py-3 mt-3 text-ml rounded focus:outline-none cursor-pointer" 
        onClick={handleSubmit(props.func)}>
        {
          props.is_loading ? 
            <Spinner/>
          :
            "ギフトを送る"
        }
      </button>
    </div>
  )
}
export default SendGift