import MainLogo from '../Atoms/mainLogo/index.js'
import Span from '../Atoms/span/index.js'

export default function logo(props){
	return(
		<div className="flex text-lg font-semibold tracking-widest text-gray-900  hover:opacity-75 rounded-lg dark-mode:text-white focus:outline-none h-full cursor-pointer">
			<MainLogo size={props.size}/>
			{
				props.dark ?
					(
						<Span size={props.size} type="dark" style="main" children="StackLive"/>
					):
					(
						<Span size={props.size} type="basic" style="main" children="StackLive"/>
					)
			}
		</div>
	)
}
