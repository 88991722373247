import React, { Suspense } from 'react';
import AnalyticsStreamings from '../Organisms/analyticsStreamings';

function AnalyticsTemp(props){
	return(
			<React.Fragment>
          <div className="min-h-screen pt-16">
            <h2 className="w-full font-semibold text-2xl md:mt-6 px-10 pb-6 text-gray-800">分析</h2>
            <Suspense fallback={<p></p>}>
              <AnalyticsStreamings resource={props.resource}/>
            </Suspense>
          </div>
			</React.Fragment>
	)
}
export default AnalyticsTemp