import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

function GuestRoute(props) {
	const loginNow = useSelector(state => state.loginNow.bool);

  return loginNow ? <Redirect to="/" /> : <Route {...props} />;
}

export default GuestRoute;
