import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

function PrivateRoute(props) {
	const loginNow = useSelector(state => state.loginNow.bool);
  	//ログインしていないと入れないページ 渡された props をそのまま Route に設定する
	return loginNow ? <Route {...props} /> : <Redirect to="/login" />;
}

export default PrivateRoute;
