import { iconSize } from './theme.js';

export default function NewIcon({size, type, func}){
	const classNames = iconSize[size];
	return(
		<svg xmlns="http://www.w3.org/2000/svg" className={classNames} width="48" height="48" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<rect x="5" y="5" width="5" height="5" rx=".5" />
			<rect x="5" y="14" width="5" height="5" rx=".5" />
			<path d="M14 15l3 3l3 -3" />
			<path d="M17 18v-12" />
		</svg>
	)
}