// booleanを切り替える
import React, { useState } from 'react';

const UseToggle = (initialValue)=>{
	const [value, setValue] = useState(initialValue);
	const toggleValue = () => setValue(!value);

  	return [value, toggleValue];
}
export default UseToggle
