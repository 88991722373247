// 処理成功ポップアップ
import React, {useState, useEffect } from 'react';

function Success(props){
	const [ is_checked, setChecked ] = useState(false);
  useEffect(()=>{
    setTimeout(
      function () {
        setChecked(true)
      }, 
      "500"
    );
  },[])
  return(
    <div className="p-4 flex items-center">
        <div className="bg-green-50 p-4 rounded flex items-start text-green-600 my-4 shadow-lg max-w-xl mx-auto">
          <label className="toggleButton">					
          <input 
            type="checkbox"   
            checked={is_checked ? true : false}
            readOnly
          />					
          <div>
            <svg viewBox="0 0 44 44">
              <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
            </svg>					
          </div>
          </label>
            <div className=" px-3">
                <h3 className="text-green-800 font-semibold tracking-wider">
                    Success 
                </h3>
                <p className="py-2 text-green-700">
                  {
                    props.message
                  }
                </p>
            </div>
        </div>
    </div>
  )
}
export default Success