import React from 'react';
import {Link} from 'react-router-dom';
// 文字列内のURLをaタグに変換してくれる。
import Linkify from 'react-linkify';
//Atoms
import Img from '../Atoms/img';
import Button from '../Atoms/button';

function MypageCard(props){
	return(
		<div className="bg-white">
			<div className="md:px-0 px-5 md:py-24 pt-12 pb-4 container mx-auto md:w-3/4 w-full flex">
				{/*プロフィールアイコン*/}
				{props.user.icon ?
					<Img type="mypage" size="mypage" src={props.user.icon} />
						:
					<img src="/images/smiley-sticker.svg" className="rounded-full md:h-36 md:w-36 h-16 w-16" />
				}
				<div className="my-auto md:mx-5 ml-2 w-4/5">
					<p className="font-bold md:text-2xl text-xl my-2 text-gray-800">
						{props.user.name}
					</p>
					{/* PCのプロフィール欄 */}
					<Linkify
						componentDecorator={(decoratedHref, decoratedText, key) => (
						<a target="blank" href={decoratedHref} key={key}>
							{decoratedText}
						</a>
					)}>
						<p className="summary md:block hidden h-auto break-words whitespace-pre-wrap text-gray-800">
							{props.user.profile}
						</p>
					</Linkify>
					{/* PCで表示ソーシャルアイコン */}
					<div className="md:flex hidden mt-2">
						{props.user.github != false &&  
							props.user.github != null &&
							<a href={"https://github.com/" + props.user.github} target="_blank" className="mr-3">
								<svg 
								viewBox="0 0 27 27" 
								className="h-7 w-7 text-gray-500 hover:text-black">
									<path fill="currentColor" d="M13.4 1.2C7 1 1.8 6 1.7 12.4v.5c0 5.1 3.2 9.8 8.2 11.5.6.1.7-.2.7-.6v-2.9s-3.3.6-4-1.5c0 0-.6-1.3-1.3-1.8 0 0-1.1-.7.1-.7.7.1 1.5.6 1.8 1.2.6 1.2 2.2 1.7 3.4 1h.1c.1-.6.4-1.2.7-1.6-2.7-.4-5.4-.6-5.4-5.2 0-1.1.5-2.1 1.2-2.8 0-1.1 0-2.2.3-3.2 1-.4 3.3 1.3 3.3 1.3 2-.6 4-.6 6 0 0 0 2.2-1.6 3.2-1.2.5 1 .5 2.2.1 3.2.7.7 1.2 1.8 1.2 2.8 0 4.5-2.8 5-5.5 5.2.6.6.9 1.3.7 2.2v4c0 .5.2.6.7.6 4.9-1.7 8.2-6.2 8-11.5.1-6.4-5.1-11.6-11.6-11.6-.1-.1-.2-.1-.2-.1z">
									</path>
								</svg>
							</a>
						}
						{props.user.twitter != false && 
							props.user.twitter != null &&
							<a href={"https://twitter.com/" + props.user.twitter} target="_blank" className="mr-3">
								<img src="/images/twitter.svg" className="h-7 w-7 opacity-75 hover:opacity-100"/>
							</a>
						}
						{props.user.homepage != false && 
							props.user.homepage != null &&
							<a href={props.user.homepage} target="_blank">
								<svg className="h-7 w-7 text-gray-500 hover:text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
								</svg>
							</a>
						}
					</div>
				</div>
				{
					props.mypage == true && 
						<Link to={"/user/" + props.userId + "/edit"} className="m-auto">
							<Button size="config" type="white" children="設定"/>
						</Link>
				}
			</div>
				{/* スマホのプロフィール欄 */}
				<Linkify
					componentDecorator={(decoratedHref, decoratedText, key) => (
					<a target="blank" href={decoratedHref} key={key}>
						{decoratedText}
					</a>
				)}>
					<p className="summary whitespace-pre-wrap md:hidden block px-5 break-words overflow-ellipsis overflow-hidden text-gray-800">
						{props.user.profile}
					</p>
				</Linkify>
				{/* スマホで表示ソーシャルアイコン */}
				<div className="md:hidden flex px-5 pt-2">
					{props.user.github != false &&   
						props.user.github != null &&
						<a href={"https://github.com/" + props.user.github} target="_blank" className="mr-3">
							<svg 
							viewBox="0 0 27 27" 
							className="h-7 w-7 text-gray-500 hover:text-black">
								<path fill="currentColor" d="M13.4 1.2C7 1 1.8 6 1.7 12.4v.5c0 5.1 3.2 9.8 8.2 11.5.6.1.7-.2.7-.6v-2.9s-3.3.6-4-1.5c0 0-.6-1.3-1.3-1.8 0 0-1.1-.7.1-.7.7.1 1.5.6 1.8 1.2.6 1.2 2.2 1.7 3.4 1h.1c.1-.6.4-1.2.7-1.6-2.7-.4-5.4-.6-5.4-5.2 0-1.1.5-2.1 1.2-2.8 0-1.1 0-2.2.3-3.2 1-.4 3.3 1.3 3.3 1.3 2-.6 4-.6 6 0 0 0 2.2-1.6 3.2-1.2.5 1 .5 2.2.1 3.2.7.7 1.2 1.8 1.2 2.8 0 4.5-2.8 5-5.5 5.2.6.6.9 1.3.7 2.2v4c0 .5.2.6.7.6 4.9-1.7 8.2-6.2 8-11.5.1-6.4-5.1-11.6-11.6-11.6-.1-.1-.2-.1-.2-.1z">
								</path>
							</svg>
						</a>
					}
					{props.user.twitter != false && 
						props.user.twitter != null &&
						<a href={"https://twitter.com/" + props.user.twitter} target="_blank" className="mr-3">
							<img src="/images/twitter.svg" className="h-7 w-7 opacity-75 hover:opacity-100"/>
						</a>
					}
					{props.user.homepage != false && 
						props.user.homepage != null &&
						<a href={props.user.homepage} target="_blank" className="mr-3">
							<svg className="h-7 w-7 text-gray-500 hover:text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
							</svg>
						</a>
					}
				</div>
		</div>
	)
}
export default MypageCard
