// style
export const divSize = {
	md: 'px-4 py-2 text-sm',
	menu: 'px-4 py-4 text-sm',
	popup: 'px-4 py-4 text-sm',
	delete: 'px-2 text-sm',
}
export const divType = {
	md: 'cursor-pointer block hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline bg-transparent font-semibold',
	menu: 'cursor-pointer block text-gray-700 hover:text-blue-400 focus:bg-gray-200 focus:outline-none focus:shadow-outline bg-transparent font-semibold',
	smMenu: 'cursor-pointer md:hidden block text-gray-700 hover:text-blue-400 focus:bg-gray-200 focus:outline-none focus:shadow-outline bg-transparent font-semibold',
	pcMenu: 'cursor-pointer md:block hidden text-gray-700 hover:text-blue-400 focus:bg-gray-200 focus:outline-none focus:shadow-outline bg-transparent font-semibold',
	livemenu: 'block focus:outline-none focus:shadow-outline bg-transparent font-semibold',
	popup: 'fixed bottom-2 left-4 bg-gray-600 block text-white bg-transparent font-semibold fit-content',
}
