import React, {useState, useEffect } from 'react';
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { Link } from 'react-router-dom';
// フォームを扱うライブラリ
import { useForm } from "react-hook-form";
// Molecules
import SuccessPopup from '../Molecules/success-popup.js';
// redux
import {useDispatch ,useSelector} from 'react-redux';
import '../../style/setting.css';
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import { change_user } from '../../actions';
import Spinner from '../Atoms/spinner';
// Containers
import UseToggle from '../../containers/toggle.js';
// DB関連
import * as gqlMutations from '../../graphql/mutations'
import * as gqlQueries from '../../graphql/queries'

function AccountBank(){
	const inputRef = React.useRef();
	// reduxディスパッチ
	const dispatch = useDispatch();
	// react-hook-formで使用する
	const { register, handleSubmit, watch, errors } = useForm();
	const [ is_loading, setLoading ] = useState(false);
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
	//  successモーダルのトグル
	const [now_success, successView] = UseToggle(false);
	const [toggle, modalToggle] = UseToggle(false);

	const [holder_name, setBankHolderName ] =  useState('');
	const [name, setBankName ] =  useState('');
	const [bankLast4, setLast4 ] =  useState('');
	const [routing1, setRoutingNumber1 ] =  useState('');
	const [routing2, setRoutingNumber2 ] =  useState('');
	const [ stripe_url, setStripeUrl ] = useState('');

	useEffect(()=>{
		const f = async()=>{
			// どっちかがfalseだった場合アカウントリンクを作成する。
			const {data: {stripeFunction: {account_link}}}= await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { 
				event: 'createAccountLink', 
				custom_account: userInfo.custom_account, 
				type: 'account_onboarding'
			}));
			setStripeUrl(account_link);
			// 登録されているカスタムアカウントを取得する
			const {data:{stripeFunction: {bank_holder_name, bank_name,last4,routing_number}}} = await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { 
					event:'getBankAccount',
					custom_account: userInfo.custom_account
				}));
				setBankHolderName(bank_holder_name);
				setBankName(bank_name);
				setLast4(last4);
				setRoutingNumber1(routing_number.substring(0,4));
				setRoutingNumber2(routing_number.substring(4));
		}
		f();
	},[])

	// リアルタイムでフォームを監視
  watch('username')
  watch('profile')
	return(
			<React.Fragment>
				<div className="md:w-3/6 w-full md:mx-auto md:mt-20 md:mt-10 mt-0 h-full md:border-solid md:border md:border-gray-200 md:shadow-md rounded">
					<div className="h-auto">
						<h2 className="text-center font-semibold text-2xl md:my-8 mt-8 mb-4">アカウント設定</h2>
						<div className="w-11/12 m-auto">
							<nav className="flex flex-row overflow-x-auto whitespace-nowrap hash_tag">
								<Link to={"/user/" + (userInfo.user_id) + "/edit"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-400 focus:outline-none">
									プロフィール
								</Link>
								<Link to={"/user/" + (userInfo.user_id) + "/transfer_history"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-400 focus:outline-none">
									振込履歴
								</Link>
								<Link to={"/user/" + (userInfo.user_id) + "/card_info"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-400 focus:outline-none">
									カード情報
								</Link>
								<Link to={"/user/" + (userInfo.user_id) + "/bank_account"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-500 focus:outline-none text-blue-600 border-b-2 font-medium border-blue-600">
									お振込先
								</Link>
							</nav>
							<div>
								<div className="leading-loose">
									<form className="max-w-xl m-auto p-10 bg-white rounded">
										<div className="mb-3">
											<label className="block text-sm text-gray-600 mb-2" htmlFor="bank_code">銀行コード</label>
											<input 
												id="bank_code"
												className={"appearance-none opacity-100 w-20 px-5 py-1 md:text-gray-700 rounded focus:outline-none border bg-gray-100 border-gray-300"}
												name="bank_code" 
												type="text" 
												placeholder="0001" 
												value={routing1}
												disabled
												/>
										</div>
										<div className="mb-3">
											<label className="block text-sm text-gray-600 mb-2" htmlFor="bank_name">銀行名</label>
											<input 
												id="bank_name"
												className={"appearance-none opacity-100 w-full px-5 py-1 md:text-gray-700 rounded focus:outline-none border bg-gray-100 border-gray-300"}
												name="bank_name" 
												type="text" 
												placeholder="Stack銀行" 
												value={name}
												disabled
												/>
										</div>
										<div className="mb-3">
											<label className=" block text-sm text-gray-600 mb-2" htmlFor="branch_code">支店コード</label>
											<input 
												id="branch_code"
												className={"appearance-none opacity-100 w-20 px-5 py-1 md:text-gray-700 rounded focus:outline-none border bg-gray-100 border-gray-300"}
												name="branch_code" 
												type="text" 
												placeholder="123" 
												value={routing2}
												disabled
												/>
										</div>
										<div className="mb-3">
											<label className=" block text-sm text-gray-600 mb-2" htmlFor="bank_number">口座番号</label>
											<input 
												id="bank_number"
												className={"appearance-none opacity-100 w-full px-5 py-1 md:text-gray-700 rounded focus:outline-none border bg-gray-100 border-gray-300"}
												name="bank_number" 
												type="text" 
												placeholder="1234567" 
												value={'***' + bankLast4}
												disabled
												/>
										</div>
										<div className="mb-3">
											<label className=" block text-sm text-gray-600 mb-2" htmlFor="name_kana">口座名義カナ</label>
											<input 
												id="name_kana"
												className={"appearance-none opacity-100 w-full px-5 py-1 md:text-gray-700 rounded focus:outline-none border bg-gray-100 border-gray-300"}
												name="name_kana" 
												type="text" 
												placeholder="スタック　タロウ" 
												value={holder_name}
												disabled
												/>
										</div>
									</form>
								</div>
								<div className="text-center mb-6">
									<a href={stripe_url} className="text-blue-600 hover:opacity-75">銀行口座情報をアップデートする</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<SuccessPopup func={successView} toggle={now_success}>変更を保存しました。</SuccessPopup>
			</React.Fragment>
	)
}
export default AccountBank
