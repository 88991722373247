// アーカイブを視聴する
import React, { Suspense, useEffect, useRef, useState } from 'react';
// amplify
import Amplify, { API, graphqlOperation} from "aws-amplify";

// Organisms
import ViewingHeader from '../Organisms/viewing-header.js';
import LeftMenu from '../Organisms/LeftMenu.js';
import SmLeftMenu from '../Organisms/sm-leftMenu.js';
import Summary from '../Organisms/summary.js';
// Molecules
import BlackModal from '../Molecules/black-modal';
import Video from '../Molecules/video';

import Chat from '../Pages/Chat.js';
// Containers
import UseToggle from '../../containers/toggle.js';
import { useParams, Prompt } from "react-router";
// DB関連
import * as gqlQueries from '../../graphql/queries'

// Suspenseを使用するためにpromiseの状態を管理する。全てのTemplateで必要。カスタムコンポーネントにしてまとめたいがやり方が分からなかったからベタ打ちで対応。
const fetchTodoList = (fetch) => {
  const promise = fetch;
  return wrapPromise(promise);
};
const wrapPromise = (promise) => {
  let status = 'pending';
  let result;

  const suspender = promise.then(
    (r) => {
      status = 'fulfilled';
      result = r;
    },
    (e) => {
      status = 'rejected';
      result = e;
  });

  const read = () => {
    if(status === 'pending') {
      throw suspender;
    } else if(status === 'rejected') {
      throw result;
    } else {
      return result;
    }
  };
  return { read };
}

function PlayArchiveTemp(){
  // パラメータから現在アクセスしているチャンネルを取得
  let { channelId } = useParams();
  const streamingResource = fetchTodoList(API.graphql(graphqlOperation(gqlQueries.getStreaming, { id: channelId })));
  const [nowStreaming, changeStreaming] = useState(false);
	const [toggle, modalToggle] = UseToggle(false);
  const divEl = useRef(null);

    useEffect(()=>{
      function setHeight() {
        if(document.getElementById('video_content')){
          const view_height = window.innerHeight;
          // 動的にチャットの高さを取得
          const video_height = document.getElementById('video_content').clientHeight;
          const height2 = document.getElementById('height_2').clientHeight;
          let all_video_height = video_height + height2;
          document.getElementById('pc_chat_height').style.height = (view_height - 60) + 'px';
          let chat_height = view_height - all_video_height;
          document.getElementById('chat_height').style.height = chat_height + 'px';
        }
      }
      // 2.初期化
      setHeight();
      // 3.ブラウザのサイズが変更された時・画面の向きを変えた時に再計算する
      window.addEventListener('resize', setHeight);
    },[])
  return(
		<React.Fragment>
    <div className="max-h-screen">
			<BlackModal targetID='modal' />
      <ViewingHeader />
				{/* SMレフトメニュー */}
				<SmLeftMenu/>
			<div className="flex md:flex-row flex-col w-full h-full">
				{/* PCレフトメニュー */}
        <LeftMenu />
				<div ref={divEl} id="video_content" className="flex flex-col  md:w-9/12 w-full md:px-4">
          {/* 動画を再生する箇所 */}
          <Suspense fallback={<p></p>}>
            <Video  resource={streamingResource}/>
          </Suspense>
          {/* 概要欄 */}  
          <div id="height_2" className="h-14">
            <Suspense fallback={<p></p>}>
              <Summary resource={streamingResource} channelId={channelId}/>
            </Suspense>
          </div>
				</div>
        <div className="md:hidden block md:w-3/12 w-full" id="chat_height">
          <Chat qr={false} modalToggle={modalToggle} archive={true}/>
        </div>
        {/* コメント欄 */}
        <div className="md:block hidden md:w-3/12 w-full h-full" id="pc_chat_height">
          <Chat qr={false} modalToggle={modalToggle} archive={true}/>
        </div>
			</div>
    </div>
    </React.Fragment>
  )
}

export default PlayArchiveTemp
