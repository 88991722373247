import React, {useState, useEffect } from 'react';
import { Route } from 'react-router-dom'
import { useSelector} from 'react-redux';
import { useParams ,useLocation} from 'react-router';
// amplify
import Amplify, { API, graphqlOperation} from "aws-amplify";

//Molecules
import MypageCard from '../Molecules/mypageCard.js';
import MenuTab from '../Molecules/menuTab.js';
import NoResults from '../Molecules/no-results.js';

// Organisms
import ArchiveContent from '../Organisms/archive_content.js';
import ReserveContent from '../Organisms/reserve_content.js';
// Containers
import UseToggle from '../../containers/toggle.js';
import * as gqlQueries from '../../graphql/queries'
// Containers
import UseReserve from '../../containers/reserved_func.js';

function MypageTemp(){
	// 見ているページのユーザーid
	const userId = useParams().user_id;

	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
	// 今の日付と時間を管理
	const reserve_schedule = useSelector(state => state.now_schedule.date);
	const [mypage, setMypage] = useState(false);
	const [isUser, setIsUser] = useState(true);
	const [rendering, Rerendering ] = UseToggle(false);
	const [user, setUser] = useState([]);
	// アーカイブの管理
	const [stream, setStream] = useState([]);
	const [reserveList, setReserve] = useState([]);
	// 予約配信を配列にまとめる関数
	const [make_array_reserve] = UseReserve();

	useEffect(()=>{
		(async function () {
			const {data: {customerByUserid : {items: { 0: user}}}} = await API.graphql(graphqlOperation(gqlQueries.customerByUserid,{user_id: userId}));
			setUser(user);
			if(user){
				// 最新のアーカイブを取得する
				const { data: { byArchiveCreated: { items: itemsPage} } } = await API.graphql({query: gqlQueries.byArchiveCreated, variables: { archive: 1, filter: {user_id: {eq: user.id }}, sortDirection: "DESC"}});
				const { data: { byReserveDate: { items: reserveList} } } = await API.graphql({query: gqlQueries.byReserveDate,variables: {filter: {user_id: {eq: user.id}}, reserve: 1,reserveDate: {ge: reserve_schedule.today_str}, sortDirection: "ASC", limit:20}});

				const result = await make_array_reserve(reserveList);
				setStream([...itemsPage]);
				setReserve(result);
			}else{
				// いる場合の処理
				setIsUser(false);
				// アクセスしているページのuser_idをもつユーザーがいない場合の処理。
				return
			}
			// 今ログインしているユーザーのユーザーidと見ているページのユーザーidを比較して外部ユーザーか本人か確認
			if(userId == userInfo.user_id){
				setMypage(true);
			}
		}());
	},[userInfo,rendering,userId])

	const ArchiveContents = ()=>{
		return(
			<div className="flex mt-5 flex-wrap md:w-4/5 mx-auto">
				{						
					stream.map((channel,index)=>
						<ArchiveContent channel={channel} thumbnail={channel[1]} rendering={Rerendering} mypage={mypage} key={index} width="md:w-1/4"/>
					)
				}
			</div>
		)
	}
	const ReserveContents = ()=>{
		return(
			<div className="flex mt-5 flex-wrap md:w-4/5 mx-auto">
				{						
					reserveList.map((reserveList,index)=>
            <React.Fragment  key={index}>
                {
                  reserveList.year == reserve_schedule.year ? 
                    reserveList.day != false &&
                      <div className="w-full text-gray-700 md:m-6 mx-6 my-4 font-bold md:text-2xl text-xl">
                        {reserveList.day} ({reserveList.dayOfWeek})
                      </div>
                  :
                  <div className="w-full text-gray-700 m-6 font-bold md:text-2xl text-xl">
                    {reserveList.year}.{reserveList.day} ({reserveList.dayOfWeek})
                  </div>
                }
                <ReserveContent channel={reserveList.channel} thumbnail={reserveList.channel[1]} time={reserveList.time} width="md:w-1/2"/>
            </React.Fragment>
					)
				}
			</div>
		)
	}
	return(
			<React.Fragment>
				{
					isUser ?
						<div className="w-full min-h-screen bg-gray-100">
							<MypageCard mypage={mypage} userId={userId} user={user}/>
							<MenuTab mypage={mypage} userId={userId}/>
							<div className="bg-gray-100 mypage_content">
								<Route path='/user/:user_id' component={ArchiveContents} exact={true}/>
								<Route path='/user/:user_id/reserve' component={ReserveContents} exact={true}/>
							</div>
						</div>
					:
					<NoResults children="ユーザーが見つかりません...."/>
				}
			</React.Fragment>
	)
}
export default MypageTemp
