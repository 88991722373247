import {
	CONFIRM_EMAIL,
	USER_INFO,
	LOGIN_NOW
} from '../actions/index.js'

const initialState = {
	loginNow: '',
	userInfo:{
		id: '',
		name: '',
		icon: '',
		profile: '',
		user_id: '',
		logininfo: '',
		buy_point: '',
		receive_point: '',
		custom_account: '',
		customer: '',
		twitter: '',
		github: '',
		homepage: '' 
	},
	email: '',
};
function auth (state = initialState, action){
	switch (action.type){
		// インプットされたメールを保存
		case CONFIRM_EMAIL:
			return{ email: action.email }
		// ログイン中のユーザーの情報を取得
		case USER_INFO:
			return{
				userInfo:{
					id: action.params.id,
					name: action.params.name,
					icon: action.params.icon,
					profile: action.params.profile,
					user_id: action.params.user_id,
					logininfo: action.params.logininfo,
					buy_point: action.params.buy_point,
					receive_point: action.params.receive_point,
					custom_account:  action.params.custom_account,
					customer:  action.params.customer,
					twitter: action.params.twitter,
					github: action.params.github,
					homepage: action.params.homepage
				}
			}
		// まずはここでstateをセットする
		default:
			return state
	}
}

export default auth;
