import React, {useState} from 'react';
import Button from '../Atoms/button';
import { Link, useHistory} from 'react-router-dom';
import { useParams, useLocation} from 'react-router';

function MenuTab(props){
	const location = useLocation();
	let isPage = {'default': false, 'reserve': false,};
	// 現在のアクセスページがどこなのか判別する
	if (location.pathname.indexOf('reserve') != -1) {
		isPage.reserve = true;
	}else{
		isPage.default = true;
	}
	return(
		<div className="bg-white">
				<nav className="md:px-40 px-0 flex flex-row ">
					<Link to={"/user/" + props.userId}>
						<Button size="tab"  type={isPage.default ? "activetab": "tab"} children="アーカイブ"/>
					</Link>
					<Link to={"/user/" + props.userId + "/reserve"}>
						<Button size="tab" type={isPage.reserve ? "activetab": "tab"} children="予約配信"/>
					</Link>
				{/* {
					props.mypage == true && 
					<Button size="tab" type="tab" children="レター(開発中)"/>
				} */}
				</nav>
		</div>
	)
}

export default MenuTab
