import React, { useState } from 'react';
import { Link,useHistory } from 'react-router-dom';
import Amplify, { API, graphqlOperation, Storage } from "aws-amplify";
// フォームを扱うライブラリ
import { useForm } from "react-hook-form";
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
// redux
import {useDispatch, useSelector} from 'react-redux';
import { user_info, change_user } from '../../actions';
// Molecules
import Logo from '../Molecules/logo.js';
import ErrorCard from '../Molecules/error-card.js';
// Atoms
import Spinner from '../Atoms/spinner';
// DB関連
import * as gqlQueries from '../../graphql/queries'
import * as gqlMutations from '../../graphql/mutations'

function Welcome (){
	// react-hook-formで使用する
	const { register, handleSubmit, watch, errors } = useForm();
	const [ is_error, errorToggle ] = useState(false);
	const [ is_loading, setLoading ] = useState(false);
	const [error_message, setMessage] = useState('');
	let history = useHistory();
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
	const dispatch = useDispatch();
	// 新規登録するユーザーテーブル名を環境変数から取得。ローカル開発ではenvファイルを作成して定義する。本番環境ではAmplifyで定義してある。
	const tablename = process.env.REACT_APP_USER_TABLE;
	const putUserId = async (data)=>{
		setLoading(true);
    nprogress.start();
		// dynamodbでユーザーのユーザー名をUpdate(Lambdaでトランザクションを使って行う)
		await API.graphql(graphqlOperation(gqlQueries.lambdaTransaction, 
			{event: 'Update', id: userInfo.id, userId: data.user_id, name: data.username, tablename: tablename}
			)).then(value=>{
				setLoading(false);
				nprogress.done();
				// reduxのstoreを更新する
				const params ={
						id: userInfo.id,
						name: userInfo.name,
						icon: userInfo.icon,
						profile: userInfo.profile,
						user_id: data.user_id,
						logininfo: userInfo.loginingo 
				}
				dispatch(user_info(params));
				API.graphql(
					graphqlOperation(gqlMutations.updateUser, {
						input: {
							id: userInfo.id,
							logininfo: 1
						}
					})
				)
				// ユーザー状態が変更した事を発火
				dispatch(change_user());
				history.push("/profile");
			}).catch(e=>{
				console.log(e);
				nprogress.done();
				setLoading(false);
				setMessage('すでにユーザーIDが使用されています')
				errorToggle(true);
			})
	}
	// リアルタイムでフォームを監視
  watch('user_id')
	return(
			<div className="h-full">
				<div className="md:justify-between justify-center flex mt-6 md:ml-5 ml-0">
					<Link to="/">
						<Logo dark={false} size="md"/>
					</Link>
				</div>
				<div className="activation-form md:mx-auto md:mt-24 mt-12 md:w-2/5 w-full md:border-solid md:border md:border-gray-200  bg-white md:shadow-md rounded md:px-8 px-4 pt-6 pb-8 mb-4">
					{/*component*/}
					<div className="mx-auto w-full">
							<p className="font-bold text-gray-700 text-center mb-8 text-base">まずはユーザー名とユーザーIDを設定しましょう。</p>
							{/* エラーメッセージ文言 */}
							<ErrorCard toggle={is_error} children={error_message} func={()=> errorToggle(false)}/>
							<div className="mt-8 mb-4">
								{errors.username && <span className="text-red-600 font-semibold text-xs block text-right mb-1">{errors.username.message}</span>}
								<div className={"flex flex-wrap rounded items-stretch w-full relative mb-5" + (errors.username ? " " : " focus:border-blue-300")}>
									<div className="flex -mr-px">
										<span className="bg-gray-100 border-2 border-r-0 rounded-l-lg flex items-center leading-normal px-3 whitespace-no-wrap text-grey-dark text-base">ユーザー名</span>
									</div>	
									<input
										name="username"
										type="text"
										ref={register({ 
											required:{
												value: true,
												message: "ユーザー名を入力してください"
											}
										})}
										className={"focus:outline-none border-2 appearance-none w-px flex-1 h-10 rounded rounded-l-none px-3 relative"+ (errors.username ? " border border-red-600 focus:border-red-600" : " focus:border-blue-300")}
										placeholder="stack太郎"
									/>
								</div>

								{errors.user_id && <span className="text-red-600 font-semibold text-xs block text-right mb-1">{errors.user_id.message}</span>}
								<div className={"flex flex-wrap rounded items-stretch w-full relative" + (errors.user_id ? " " : " focus:border-blue-300")}>
									<div className="flex -mr-px">
										<span className="bg-gray-100 border-2 border-r-0 rounded-l-lg flex items-center leading-normal px-3 whitespace-no-wrap text-grey-dark text-base">https://stacklive.jp/</span>
									</div>	
									<input 
										name="user_id"
										type="text" 
										ref={register({ 
											required: {
												value: true,
												message: "ユーザーIDを入力してください"
											},
											pattern: {
												value: /[a-zA-Z0-9]/,
												message: "設定できない文字が入力されています。"
										}
										})} 
										className={"focus:outline-none border-2 appearance-none w-px flex-1 h-10 rounded rounded-l-none px-3 relative"+ (errors.user_id ? " border border-red-600 focus:border-red-600" : " focus:border-blue-300")}
										placeholder="foo"
										/>
								</div>	
								<span className="w-full inline-block text-right text-xs text-gray-500">※ユーザーIDは変更する事ができません。</span>
							</div>
							<div className="text-center">
								<button 
									id="activation-button" 
									className="inline-block w-20 m-auto main-background hover:opacity-75 text-white font-bold py-2 px-4 rounded" 
									type="button" 
									onClick={handleSubmit(putUserId)}>
									{is_loading ? 
									<Spinner/> 
									: 
									"次へ"}
								</button>
							</div>
						</div>
				</div>
			</div>
	)
}

export default Welcome;

