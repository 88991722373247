import ReactDOM from 'react-dom';
import React, {useState} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function ModalCircle(props){
  const [crop, setCrop] = useState({unit: '%', width:50, aspect: 1});
  const [imageRef, setImageRef ] =  useState('');

  const onImageLoaded = (image) => {
    setImageRef(image);
  };
  // チェンジすると走る
  const onCropChange = (crop, percentCrop)=>{
    // 横縦比を固定する
    // let XY　= {x: crop.x, y: crop.y, width: crop.width, height: crop.height, unit: 'px', aspect: 1};
    setCrop(crop);
  };
  async function makeClientCrop(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await trimming(imageRef,crop,'newFile.jpeg');
      props.setFileUrl(croppedImageUrl);
      props.modalToggle();
    }
  }
  // トリミングをする
  const trimming= (image, crop, fileName) => {

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(props.fileUrl);
        const newFile = window.URL.createObjectURL(blob)
        resolve(newFile);
      }, 'image/jpeg');       
    });

  }
  // ポータルを使用してモーダル要素に表示
  return ReactDOM.createPortal(
      <div className={"flex items-center justify-center fixed left-0  bottom-0 w-full h-full bg-opacity-80 bg-gray-600 z-50 bg-opacity-80" + (props.toggle ? ' block': ' hidden')}>
        <div className="bg-white rounded-lg md:w-1/2 w-full">
          <svg className="w-6 h-6 cursor-pointer text-gray-600 mt-3 ml-3 inline hover:opacity-75" onClick={props.modalToggle} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
          </svg>
          <div className="flex flex-col items-start p-4">
          <div className="flex justify-between border-b border-gray-100 m-auto" >
              {props.src && (
                <ReactCrop
                  src={props.src}
                  crop={crop}
                  onImageLoaded={onImageLoaded}
                  onChange={onCropChange}
                  circularCrop={true}
                />
              )}
          </div>   
            <button id=""  className="m-auto mt-5 bg-blue-600 hover:opacity-75 text-white font-bold px-4 py-2 text-ml rounded focus:outline-none" onClick={()=>makeClientCrop(crop)}  type="button">
                トリミング
            </button>
          </div>
        </div>
      </div>,
      document.getElementById(props.targetID)
  )
}
export default ModalCircle