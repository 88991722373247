import React, {useState, useEffect } from 'react';

function SelectBox(props){
	const [langs, setLangs] = useState([
		{name: "JavaScript", flag: false},
		{name: "Python", flag: false},
		{name: "Ruby", flag: false},
		{name: "SQL", flag: false},
		{name: "HTML", flag: false},
		{name: "Next.js", flag: false},
		{name: "Vue.js", flag: false},
		{name: "Rails", flag: false},
		{name: "Go", flag: false},
		{name: "AWS", flag: false},
		{name: "TypeScript", flag: false},
		{name: "PHP", flag: false},
		{name: "Laravel", flag: false},
		{name: "Docker", flag: false},
		{name: "Rust", flag: false},
		{name: "COBOL", flag: false},
		{name: "R", flag: false},
		{name: "Java", flag: false},
		{name: "Swift", flag: false},
		{name: "Git", flag: false},
		{name: "React", flag: false},
	]);
	// 言語を追加
	const add_tec = (e)=>{
		const value = e.currentTarget.dataset['tec'];
		let count = 0;
		langs.map(function(lang){
			count += 1 ;
			if(value == lang.name){
				let array = [...langs];
				count -= 1;
				array.splice(count, 1 ,{name: lang.name , flag: true});
				setLangs(array);
			}
		})
		props.setTec([...props.state, value]);
	};
	// 削除
	const delete_tec = (e)=>{
		const selectValue = e.currentTarget.dataset['select'];
		const index = props.state.indexOf(selectValue);
		let array = [...props.state];
		let status = [...langs];
		let count = 0;
		status.map(function(arr){
			count += 1 ;
			if(arr.name == selectValue){
				count -= 1;
				status.splice(count, 1 ,{name: arr.name , flag: false});
				setLangs(status);
			}
		});
		array.splice(index, 1);
		props.setTec(array);
	}
	return(
				<div className="w-full flex flex-col items-center">
						<div className="w-full flex flex-col items-center relative">
							<div className="w-full  svelte-1l8159u">
								<div className="my-2 p-1 flex border border-gray-300 bg-white rounded svelte-1l8159u">
									<div className="flex flex-auto flex-wrap">
										{
											props.state.map((tec,index) =>
												<div className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-blue-700 bg-blue-100 border border-blue-300" key={index}>
													<div className="text-xs font-normal leading-none max-w-full flex-initial">{tec}</div>
													<div className="flex flex-auto flex-row-reverse">
														<div>
															<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x cursor-pointer hover:text-blue-400 rounded-full w-4 h-4 ml-2" data-select={tec} onClick={delete_tec}>
																<line x1="18" y1="6" x2="6" y2="18"></line>
																<line x1="6" y1="6" x2="18" y2="18"></line>
															</svg>
														</div>
													</div>
												</div>
											)
										}
										<div className="flex-1">
											<input placeholder="" className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800" readOnly/>
										</div>
									</div>
									<div className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-300 svelte-1l8159u">
										<button className="w-6 h-6 text-gray-600 outline-none focus:outline-none" onClick={()=>props.selectToggle(!props.is_select)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up w-4 h-4">
												<polyline points="18 15 12 9 6 15"></polyline>
											</svg>
										</button>
									</div>
									</div>
									</div>
									<div 
										className={"absolute shadow top-100 bg-white z-40 w-full lef-0 rounded max-h-select overflow-y-auto svelte-5uyqqj" + (props.is_select ? " " : " hidden")}
									>
									<div className="flex flex-col w-full">
									{
										langs.map((lang,index) =>
											<div className={"cursor-pointer w-full border-gray-100 rounded-t hover:bg-blue-100" + (lang.flag ? " pointer-events-none" : " " )} data-tec={lang.name}  onClick={add_tec} key={index}>
												<div className={"flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative" + (lang.flag ? " border-blue-600 ": " " )}>
													<div className="w-full items-center flex">
														<div className="mx-2 leading-6" >{lang.name}</div>
													</div>
												</div>
											</div>
										)
									}
									</div>
								</div>
						</div>
			</div>
	)
}
export default SelectBox
