import React, {useState, useEffect } from 'react';

import {useDispatch ,useSelector} from 'react-redux';
import { schedule_change } from '../../actions';

function Schedule(props){
  let yearName  = 'year',       // 年セレクトボックスのname属性値
      monthName = 'month',      // 月セレクトボックスのname属性値
      dayName   = 'days',        // 日セレクトボックスのname属性値
      timeName   = 'time';        // 時間セレクトボックスのname属性値
	const dispatch = useDispatch();
	// 今の日付と時間を管理
	const now_schedule = useSelector(state => state.now_schedule.date);
  let yyyy = now_schedule.year;
  let mm = now_schedule.month;
  let dd = now_schedule.day;
  let times = now_schedule.times;

  useEffect(()=>{
    document.getElementById(yearName).querySelector("option[value='"+ yyyy +"']").setAttribute("selected", "selected");
    document.getElementById(monthName).querySelector("option[value='"+ mm +"']").setAttribute("selected", "selected");
    document.getElementById(dayName).querySelector("option[value='"+ dd +"']").setAttribute("selected", "selected");
    document.getElementById(timeName).querySelector("option[value='"+ times +"']").setAttribute("selected", "selected");
  },[])

  // 選択された月のマックスの日にちを計算する
  const changeDay = ()=>{
    let yearObj = document.getElementById(yearName),
        monthObj = document.getElementById(monthName),
        dayObj   = document.getElementById(dayName),
        timeObj   = document.getElementById(timeName),
        selectY  = yearObj.options[yearObj.selectedIndex].value,
        selectM  = monthObj.options[monthObj.selectedIndex].value,
        selectD  = dayObj.options[dayObj.selectedIndex].value,
        selectT  = timeObj.options[timeObj.selectedIndex].value,
        dateObj  = new Date(selectY, selectM, 0),
        today = new Date(),
        maxDays  = dateObj.getDate(),
        date_str = today.toISOString();

    let state = {
      year : selectY,
      month: selectM,
      day: selectD,
      hours: selectT.split(':')[0],
      minutes: selectT.split(':')[1],
      times: selectT,
      today_str: date_str
    }     
    // stateを更新する
    dispatch(schedule_change(state))

    dayObj.length = 0;
    for (var i = 1; i <= maxDays; i++) {
        dayObj.options[i] = new Option(i, i);
    }
    dayObj.removeChild(dayObj.options[0]);
    if (selectD > dayObj.length) {
        dayObj.options[dayObj.length - 1].selected = true;
    } else {
        dayObj.options[selectD - 1].selected = true;
    }
  }


	return(
    <div className="flex">
      <div className="relative inline-flex self-center">
          <svg className="absolute top-0 right-0 m-2 pointer-events-none p-2 rounded h-7 w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 22" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-539.000000, -199.000000)" fill="black" fillRule="nonzero">
                    <g transform="translate(538.000000, 183.521208)">
                        <polygon transform="translate(20.000000, 18.384776) rotate(135.000000) translate(-20.000000, -18.384776) " points="33 5.38477631 33 31.3847763 29 31.3847763 28.999 9.38379168 7 9.38477631 7 5.38477631"/>
                    </g>
                </g>
            </g>
          </svg>
          <select 
            name="year" 
            className="text-lg font-bold text-gray-600 border pr-10 border-gray-300 rounded h-12 pl-5 hover:border-blue-300 focus:outline-none appearance-none"
            onChange={()=>changeDay()}
            id="year"
            >
              <option value="2021">2021</option>
              <option value="2022">2022</option>
          </select>
      </div>
      <span className="font-bold text-gray-700 mx-3 my-auto">年</span>
      <div className="relative inline-flex self-center">
          <svg className="absolute top-0 right-0 m-2 pointer-events-none p-2 rounded h-7 w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 22" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-539.000000, -199.000000)" fill="black" fillRule="nonzero">
                    <g transform="translate(538.000000, 183.521208)">
                        <polygon transform="translate(20.000000, 18.384776) rotate(135.000000) translate(-20.000000, -18.384776) " points="33 5.38477631 33 31.3847763 29 31.3847763 28.999 9.38379168 7 9.38477631 7 5.38477631"/>
                    </g>
                </g>
            </g>
          </svg>
          <select 
          name="month" 
          id="month" 
          onChange={()=>changeDay()}
          className="text-lg font-bold text-gray-600 border pr-10 border-gray-300 rounded h-12 pl-5 hover:border-blue-300 focus:outline-none appearance-none"
          >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
          </select>
      </div>
      <span className="font-bold text-gray-700 mx-3 my-auto">月</span>
      <div className="relative inline-flex self-center">
          <svg className="absolute top-0 right-0 m-2 pointer-events-none p-2 rounded h-7 w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 22" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-539.000000, -199.000000)" fill="black" fillRule="nonzero">
                    <g transform="translate(538.000000, 183.521208)">
                        <polygon transform="translate(20.000000, 18.384776) rotate(135.000000) translate(-20.000000, -18.384776) " points="33 5.38477631 33 31.3847763 29 31.3847763 28.999 9.38379168 7 9.38477631 7 5.38477631"/>
                    </g>
                </g>
            </g>
          </svg>
          <select 
          name="days" 
          id="days" 
          onChange={()=>changeDay()}
          className="text-lg font-bold text-gray-600 border pr-10 border-gray-300 rounded h-12 pl-5 hover:border-blue-300 focus:outline-none appearance-none"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="31">31</option>
          </select>
      </div>
      <span className="font-bold text-gray-700 mx-3 my-auto">日</span>
      <div className="relative inline-flex self-center">
          <svg className="absolute top-0 right-0 m-2 pointer-events-none p-2 rounded h-7 w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 22" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-539.000000, -199.000000)" fill="black" fillRule="nonzero">
                    <g transform="translate(538.000000, 183.521208)">
                        <polygon transform="translate(20.000000, 18.384776) rotate(135.000000) translate(-20.000000, -18.384776) " points="33 5.38477631 33 31.3847763 29 31.3847763 28.999 9.38379168 7 9.38477631 7 5.38477631"/>
                    </g>
                </g>
            </g>
          </svg>
          <select 
            name="time" 
            className="text-lg font-bold text-gray-600 border pr-10 border-gray-300 rounded h-12 pl-5 hover:border-blue-300 focus:outline-none appearance-none"
            id="time"
            onChange={()=>changeDay()}
          >
          <option value="0:00">
            0:00
          </option><option value="0:30">
            0:30
          </option><option value="1:00">
            1:00
          </option><option value="1:30">
            1:30
          </option><option value="2:00">
            2:00
          </option><option value="2:30">
            2:30
          </option><option value="3:00">
            3:00
          </option><option value="3:30">
            3:30
          </option><option value="4:00">
            4:00
          </option><option value="4:30">
            4:30
          </option><option value="5:00">
            5:00
          </option><option value="5:30">
            5:30
          </option><option value="6:00">
            6:00
          </option><option value="6:30">
            6:30
          </option><option value="7:00">
            7:00
          </option><option value="7:30">
            7:30
          </option><option value="8:00">
            8:00
          </option><option value="8:30">
            8:30
          </option><option value="9:00">
            9:00
          </option><option value="9:30">
            9:30
          </option><option value="10:00">
            10:00
          </option><option value="10:30">
            10:30
          </option><option value="11:00">
            11:00
          </option><option value="11:30">
            11:30
          </option><option value="12:00">
            12:00
          </option><option value="12:30">
            12:30
          </option><option value="13:00">
            13:00
          </option><option value="13:30">
            13:30
          </option><option value="14:00">
            14:00
          </option><option value="14:30">
            14:30
          </option><option value="15:00">
            15:00
          </option><option value="15:30">
            15:30
          </option><option value="16:00">
            16:00
          </option><option value="16:30">
            16:30
          </option><option value="17:00">
            17:00
          </option><option value="17:30">
            17:30
          </option><option value="18:00">
            18:00
          </option><option value="18:30">
            18:30
          </option><option value="19:00">
            19:00
          </option><option value="19:30">
            19:30
          </option><option value="20:00">
            20:00
          </option><option value="20:30">
            20:30
          </option><option value="21:00">
            21:00
          </option><option value="21:30">
            21:30
          </option><option value="22:00">
            22:00
          </option><option value="22:30">
            22:30
          </option><option value="23:00">
            23:00
          </option><option value="23:30">
            23:30
          </option></select>
      </div>
    </div>
	)
}

export default Schedule