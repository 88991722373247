// エラー文言
function ErrorCard({children, func, toggle}){
  return(
		<div className={"bg-red-200 border border-red-800 text-red-900 md:px-4 px-2 py-2 mb-5 rounded relative flex justify-between" + (toggle ? " block" : " hidden")} role="alert">
			<div className="flex">
				<strong className="font-bold">
					<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
					</svg>
				</strong>
				<span className="block sm:inline text-sm">{children}</span>
			</div>
			<svg className="w-5 h-5 cursor-pointer hover:opacity-75" onClick={func} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
				<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
			</svg>
		</div>
  )
}
export default ErrorCard