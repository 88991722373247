import React from 'react';
import Footer from '../Organisms/footer.js';
import Header from '../Organisms/Header.js';
// Molecules
import BlackModal from '../Molecules/black-modal';
import SmLeftMenu from '../Organisms/sm-leftMenu.js';


function Privacy(props){
	return(
		<React.Fragment>
				<BlackModal targetID='modal' />
				<Header />          
        <div className="md:w-3/5 w-full md:m-auto pt-16">
					{/* SMレフトメニュー */}
					<SmLeftMenu/>
	<div className="md:border md:border-gray-300 rounded my-10 md:px-10 px-8 py-5">
				<div className="text-center my-8">
					<h2 className="font-bold text-2xl">プライバシーポリシー</h2>
				</div>
				<div className="m-auto">
					<div className="text-center">
						<p className="text-left">StackLive運営者（以下「運営者」といいます。）は、StackLive（以下「本サービス」といいます。）の運営において取り扱う利用者の個人情報について、個人情報保護法その他法令を遵守し、細心の注意を払って取り扱うために、以下のとおりプライバシーポリシーを定めます。</p>
					</div>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">1. 運営者が取得する情報とその取得方法</h4>
					</div>
					<div className="">
						<p>本サービスにおいて運営者が取得する情報は次のとおりです。なお、本サービスのご利用内容・提供内容によっては取得しない情報もあります。</p>
					</div>
					<div className="text-left flex my-2">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">1</span>
            <h5 className="my-auto font-semibold">利用者にご提供いただく情報</h5>
					</div>
					<div className="">
						<p>本サービスのご利用にあたり、利用者が運営者にご提供いただく情報は次のとおりです。</p>
						<p>・氏名、生年月日、性別、顔写真等・連絡先（住所、電話番号およびメールアドレス等）</p>
            <p>・決済情報（銀行口座情報、クレジットカード情報、および、電子マネー情報等）</p>
            <p>・その他、当サイトが定める入力フォームに利用者が入力する情報</p>
          </div>
					<div className="text-left flex my-2">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">2</span>
						<h5 className="my-auto font-semibold">その他</h5>
					</div>
					<div className="">
						<p>すべての利用者において、本サービスにアクセスした時に、以下の情報を自動的に取得します。なお、以下の情報には個人を特定できる情報を含みません。</p>
						<p>・Cookie（クッキー）情報・ユーザーエージェント情報・IPアドレス</p>
					</div>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">2. 利用目的</h4>
					</div>
					<p>運営者は、取得した利用者の情報を以下の目的のために利用します。</p>
					<div className="text-left flex my-2">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">1</span>
            <p className="my-auto">本サービスの提供・運営のため</p>
					</div>
					<div className="text-left flex my-2">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">2</span>
            <p className="my-auto">利用者の本人確認および認証のため</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						  <span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">3</span>
            </div>
            <p className="my-auto">利用者からの問い合わせに対応するため</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
              <span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">4</span>
            </div>
            <p className="my-auto">本サービスの利用料金の計算、決済、返金等のため</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
              <span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">5</span>
            </div>
            <p className="my-auto">本サービスに関連する、新機能、更新情報、キャンペーン等の案内メールを送付するため</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">6</span>
            </div>
            <p className="my-auto">不正行為または違法となる可能性のある行為を防止し、本サービスの円滑な運営と利用規約の施行のため</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">7</span>
            </div>
            <p className="my-auto">システムメンテナンスやサービス不具合の対応のため</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">8</span>
            </div>
            <p className="my-auto">利用者を識別できない形式に加工した統計データを作成するため</p>
					</div>
					<div className="text-left flex my-2">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">9</span>
            <p className="my-auto">上記の利用目的に付随する目的</p>
					</div>
          <p>
            本サービスでは、コンテンツの決済をStripeに委託しています。クレジットカード情報の取り扱いに関しては<a src="https://stripe.com/jp/privacy" className="text-blue-500">Stripeのプライバシーポリシー</a>が適用されます。
          </p>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">3. 利用目的の変更</h4>
					</div>
					<p>・運営者は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。</p>
					<p>・利用目的の変更を行った場合には，変更後の目的について，運営者所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。</p>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">4. 個人情報の第三者への提供</h4>
					</div>
					<p>運営者は、以下の場合を除き、取得した個人情報を第三者に開示・提供・共有することはございません。</p>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">1</span>
            </div>
            <p className="my-auto">利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">2</span>
            </div>
            <p className="my-auto">利用者本人の同意を得た場合</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">3</span>
            </div>
            <p className="my-auto">利用者を識別することができない状態で統計的なデータとして開示・提供するとき</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">4</span>
            </div>
            <p className="my-auto">法令に基づき公的機関または政府機関から開示を要求された場合</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">5</span>
            </div>
            <p className="my-auto">人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき</p>
					</div>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">5.個人情報の保護</h4>
					</div>
					<p>運営者は、利用者の個人情報の紛失、盗用、悪用、不正アクセス、改ざんおよび破損を防ぐために、合理的範囲内で技術的および物理的措置を講じています。</p>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">6.個人情報の開示</h4>
					</div>
					<p>運営者は、利用者本人から要請があった場合は、本人であることを確認後合理的期間内に、保管している個人情報を開示いたします。ただし、その要請が不合理に繰り返されたものである場合、非現実的なものである場合、他者の権利を害するおそれのある場合、または関連する法令により別段要求されない場合は、お断りすることがあります。</p>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">7.個人情報の修正・更新</h4>
					</div>
					<p>利用者は、ご自身のマイページから個人情報を変更することができます。</p>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">8.個人情報の破棄</h4>
					</div>
					<p>運営者は、利用者から直接取得した情報について、利用者としての登録抹消や本サービスの終了など、合理的に不要だと判断される時点で破棄いたします。</p>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">9.プライバシーポリシーの改定</h4>
					</div>
					<p>運営者は、事前の通知をすることなくプライバシーポリシーを随時変更することがあり、運営者のウェブサイトに掲載した時点から適用されるものとします。ただし、利用目的が大きく変更される場合は、改めて利用者から同意を得るものとします。
					</p>
					</div>
		</div>
	</div>
			<Footer/>
		</React.Fragment>
	)
}
export default Privacy