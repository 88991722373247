import React, { useState, useEffect } from "react";
// クレジットカード
function CreditCard (props){
  const [imgfile, setimg] = useState('');
  useEffect(()=>{
    switch(props.array.brand){
      case 'Visa':
        setimg('visa');
        break;
      case 'American Express':
        setimg('american-express');
        break;
      case 'JCB':
        setimg('jcb');
        break;
      case 'MasterCard':
        setimg('mastercard');
        break;
    }
  },[props.array]);
  return(
      <div className="flex justify-center items-center">
        <div className="space-y-16">
          <div className="md:w-96 md:h-56 w-80 h-48 m-auto bg-gray-400 border border-gray-300 rounded-xl relative text-white shadow-md">
            {/* <img className="relative object-cover w-full h-full rounded-xl" src="https://i.imgur.com/kGkSg1v.png"/> */}
            <div className="w-full pl-8 pr-4 absolute md:top-14 top-9">
              <div className="flex">
                <div className="pt-1">
                  <p className="font-light text-sm">
                    カード番号
                  </p>
                  <p className="font-medium tracking-more-wider font-mono">
                      <strong>●●●● ●●●● ●●●●</strong><span className="px-2 py-1 font-bold">{props.array.last4}</span>
                  </p>
                </div>
              </div>
              <div className="pt-6 pr-6">
                <div className="flex justify-between relative">
                  <div className="">
                    <p className="font-light text-xs text-xs">
                      有効期限
                    </p>
                    <p className="font-medium tracking-wider text-sm font-mono">
                      {props.array.year}年{props.array.month}月
                    </p>
                  </div>
                  <img src={"/images/cardBrand/" + imgfile + ".svg"} className="w-14 absolute right-0"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
export default CreditCard