import {
	SUCCEEDED,
	BUYPOINT
} from '../actions/index.js'

const initialState = {
	succeeded: false,
	buy_point_succeeded: false
};
function progress_state (state = initialState, action){
	switch (action.type){
		case  SUCCEEDED:
			return{ succeeded: action.bool}
		case  BUYPOINT:
			return{ buy_point_succeeded: action.bool}
		// まずはここでstateをセットする
		default:
			return state
	}
}

export default progress_state;
