import React, { useState, useEffect } from 'react';
// 文字列内のURLをaタグに変換してくれる。
import Linkify from 'react-linkify';
import { Link, useHistory} from 'react-router-dom';
// Containers
import UseToggle from '../../containers/toggle.js';
import Img from '../Atoms/img';

// 概要欄
function Summary (props){
  const [toggle, openSummary] = UseToggle(false);
  const [smtoggle, openSmSummary] = UseToggle(false);
  const [is_url, setUrl] = useState(true);
  const streamingInfo = props.resource.read().data.getStreaming;

	let history = useHistory();
	useEffect(()=>{
    if(streamingInfo.archive){
		  // アーカイブ画面へリダイレクト
      history.push("/user/" + streamingInfo.user.user_id + "/archive/" + streamingInfo.id);
    }
    // 告知したいURLがあるかどうか確認
    if(!streamingInfo.announce_url){
      setUrl(false);
    }
  },[])
  // スマホで概要欄をトグルさせる
  const sm_toggle_summary =()=>{
    openSmSummary();
  }
  return(
		<React.Fragment>
      <div className={"w-full md:my-3 my-0 md:py-4 md:px-6 rounded border relative" + (props.dark ? " bg-gray-300": " bg-gray-100")} >
          <div 
          className={"relative flex items-center justify-between md:border-0 border-b border-gray-200 px-3 py-4" + (smtoggle ? " ": " triangle-summary")}
          onClick={sm_toggle_summary}
          >
            <div className="w-full flex justify-between">
              <div className="">
                  <span className="hidden md:block font-bold mb-4 mx-2 inline-block">
                    {streamingInfo.title}
                  </span>
                  <Link to={"/user/" + streamingInfo.user.user_id} className="flex hover:opacity-75 cursor-pointer fit-content">
                    {/*プロフィール画像*/}
                    {streamingInfo.user.icon == null && 
                      <img src="/images/smiley-sticker.svg" className="h-8 w-auto rounded-full"/>
                    }
                    {streamingInfo.user.icon != null && 
                      <Img size="md" type="md" src={streamingInfo.user.icon} />
                    }
                  <p className="font-semibold my-auto ml-2 text-sm">{streamingInfo.user.name}</p>
                  </Link>
              </div>
              {
                is_url == true && 
                <a href={streamingInfo.announce_url} 
                className="flex main-background rounded-lg py-2 px-3 text-white font-bold hover:opacity-75 my-auto text-sm"
                target="_blank"
                >
                  Check
                  <svg 
                    className="w-5 h-5 ml-2" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24" 
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" 
                      />
                  </svg>
                </a>
              }
            </div>
          </div>
          <div 
          className={"flex-col md:mt-2 whitespace-pre-wrap overflow-y-auto md:max-h-auto max-h-96 md:static w-full absolute z-10" + (smtoggle ? " flex bg-white" + (props.dark ? " md:bg-gray-300" : " md:bg-gray-100") : " md:flex hidden")}
          >
            <div className="flex md:hidden my-2 mx-4 py-1 border-l-2 border-blue-300">
              <span className="text-lg font-bold mx-2 inline-block 5/6">
                {streamingInfo.title}
              </span>
            </div>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}>
              <span className={"summary break-all text-sm px-4 pb-2 md:pt-8 pt-4" + (toggle ? " ": " md:summary-4-line")}>
                {streamingInfo.summary}
              </span>  
              {
                toggle ?
                  <button 
                    className="hidden md:block text-white bg-gray-700 hover:opacity-75 cursor-pointer fit-content m-auto mt-2 px-4 py-1 text-sm rounded-full"
                    onClick={openSummary}
                  >
                      閉じる
                  </button>
                :
                  <button 
                    className="hidden md:block text-white bg-gray-700 hover:opacity-75 cursor-pointer fit-content m-auto mt-2 px-4 py-1 text-sm rounded-full"
                    onClick={openSummary}
                  >
                      続きを読む
                  </button>
              }
              {/* スマホでの概要欄の閉じるボタン */}
              {
                smtoggle == true &&
                  <button 
                    className="block md:hidden text-white bg-gray-700 hover:opacity-75 cursor-pointer fit-content m-auto mt-2 mb-5 px-4 py-1 text-sm rounded-full"
                    onClick={openSmSummary}
                  >
                      閉じる
                  </button>
              }
            </Linkify>
          </div>
      </div>
    </React.Fragment>

  )
}

export default Summary