import React, {useState, useEffect } from 'react';
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { Link } from 'react-router-dom';
// フォームを扱うライブラリ
import { useForm } from "react-hook-form";
// Molecules
import SuccessPopup from '../Molecules/success-popup.js';
// redux
import {useDispatch ,useSelector} from 'react-redux';
import '../../style/setting.css';
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import { change_user } from '../../actions';
// Molecules
import ModalCircle from '../Molecules/modalCircle.js';
// Atoms
import Img from '../Atoms/img';
import Spinner from '../Atoms/spinner';
// Containers
import UseUuid from '../../containers/uuid.js';
import Uses3Upload from '../../containers/s3-upload.js';
import UseToggle from '../../containers/toggle.js';
// DB関連
import * as gqlMutations from '../../graphql/mutations'

function Edit(){
	const inputRef = React.useRef();
	// reduxディスパッチ
	const dispatch = useDispatch();
	// react-hook-formで使用する
	const { register, handleSubmit, watch, errors } = useForm();
	const [ is_loading, setLoading ] = useState(false);
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
	//  successモーダルのトグル
	const [now_success, successView] = UseToggle(false);
	const [toggle, modalToggle] = UseToggle(false);

	const [src, setSrc] = useState(null);
	const [filetype, setType] = useState(null);
	const [fileUrl, setFileUrl ] =  useState('');
	// s3にアップロード
  const [upload] = Uses3Upload();

	async function updateUser(input){
		const user = await API.graphql(
			graphqlOperation(gqlMutations.updateUser, {
				input: input
		})).then((data) =>{
			console.log(data);
			// 成功処理
			if(data != null){
				nprogress.done();
				setLoading(false);
				successView();
				// ユーザー状態が変更した事を発火
				dispatch(change_user());
			}
		}).catch(e=>{
				nprogress.done();
				setLoading(false);
				console.log(e);
		});
		return user
	}
	//s3にファイルを送信&自己紹介文を保存
	const pushFile = async (data)=>{
		setLoading(true);
    nprogress.start()
		// プロフィール画像を変更してない時の処理
		if(fileUrl == ''){
			let input = {
					id: userInfo.id,
					name: data.username,
					profile: data.profile,
					twitter: data.twitter,
					github: data.github,
					homepage: data.homepage
			}
			updateUser(input);
		}else{
			// プロフィール画像を変更した時の処理
			// uuidを作成
			const [uuid] = await UseUuid();
			let imageData = await fetch(fileUrl);
			let blobData = await imageData.blob();
			// s3にアップロードする
      await upload(uuid,blobData,filetype);
			let input = {
					id: userInfo.id,
					name: data.username,
					profile: data.profile,
					icon: uuid,
					twitter: data.twitter,
					github: data.github,
					homepage: data.homepage
			}
			let result = updateUser(input);
			result.then(data=>{
				// 成功処理
				if(data != null){
					nprogress.done();
					setLoading(false);
					successView();
				}
			}).catch(e=>{
					nprogress.done();
					setLoading(false);
					console.log(e);
			})
		}
	}
	// inputを擬似クリック
	const handleClick = ()=> inputRef.current.click();
	// ファイルがアップされて走る処理。トリミングをしないと保存できない。
	const handleChangeFile = (e) => {
		const files = Object.values(e.target.files).concat();
		files.filter((file) => {
			// ファイルをバリデーションする
      if (
        ![
          "image/jpeg",
          "image/png",
          "image/bmp",
          "image/svg+xml",
        ].includes(file.type)
      ){
				alert('jpeg, png, svg以外のファイル形式は表示されません');
        return;
      }else{
				if (e.target.files && e.target.files.length > 0) {
					let selectedFile = e.target.files[0];
					setType(selectedFile.type)
					const reader = new FileReader();
					let imgtag = document.getElementById("myimage");
					imgtag.title = selectedFile.name;
					reader.addEventListener('load', () =>
						setSrc(reader.result)
					);
					reader.readAsDataURL(e.target.files[0]);
					modalToggle();
					// valueを初期化
					let inputfile = document.getElementById("inputfile");
					inputfile.value = '';
				}
			}
		});
  };
	// リアルタイムでフォームを監視
  watch('username')
  watch('profile')
	return(
			<React.Fragment>
				<div className="md:w-3/6 w-full md:mx-auto md:my-20 md:my-10 mt-0 h-full md:border-solid md:border md:border-gray-200 md:shadow-md rounded">
					<ModalCircle src={src} targetID='modal' modalToggle={modalToggle} toggle={toggle} setFileUrl={setFileUrl} fileUrl={fileUrl}/>
					<div className="h-auto">
						<h2 className="text-center font-semibold text-2xl md:my-8 mt-8 mb-4">アカウント設定</h2>
						<div className="w-11/12 m-auto">
							<nav className="flex flex-row overflow-x-auto whitespace-nowrap hash_tag">
								<button className="w-1/4 text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-500 focus:outline-none text-blue-600 border-b-2 font-medium border-blue-600">
									プロフィール
								</button>
								<Link to={"/user/" + (userInfo.user_id) + "/transfer_history"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-400 focus:outline-none">
									振込履歴	
								</Link>
								<Link to={"/user/" + (userInfo.user_id) + "/card_info"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-400 focus:outline-none">
									カード情報
								</Link>
								<Link to={"/user/" + (userInfo.user_id) + "/bank_account"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-400 focus:outline-none">
									お振込先
								</Link>
							</nav>
							<div>
								<div className="container m-auto md:flex md:pt-12 pt-8">
									<div className="relative setting-img mb-4" onClick={handleClick}>
										{
											fileUrl ?
													<img className="object-cover rounded-full flex items-center justify-center cursor-pointer hover:opacity-75  h-32 w-32 m-auto" id="myimage" alt="Crop" src={fileUrl} />
											: userInfo.icon ?
													<Img size="lg" type="icon" src={userInfo.icon} id="myimage"/>
											:
													<img src="/images/smiley-sticker.svg" id="myimage" className="w-auto h-32 w-32 m-auto rounded-full opacity-50 hover:opacity-75 cursor-pointer"/>
										}
									</div>
									<input id="inputfile" className="hidden" type="file" ref={inputRef} onChange={handleChangeFile} />
									<div className="my-auto md:mx-5 md:w-4/6 w-full">
									<label className="text-gray-900 font-bold text-sm">名前</label>
									<input
										name="username"
										className={"focus:outline-none border border-gray-300 appearance-none rounded px-3 py-2 mt-1 w-full md:text-sm text-base" + (errors.username ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
										ref={register({ 
											required: 'ユーザー名を入力してください' 
										})}
										type="text"
										placeholder="ユーザー名"
										defaultValue={userInfo.name}
									/>
									{errors.username && <span className="text-red-600 font-semibold text-xs h-5">{errors.username.message}</span>}
									<label className="text-gray-900 font-bold text-sm mt-4 block">自己紹介</label>
										<textarea
											name="profile"
											className={"focus:outline-none mt-1 border-gray-300 appearance-none rounded border px-3 py-2 md:text-ml w-full h-40" + (errors.profile ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
											ref={register({ 
												required: '自己紹介を入力してください',
												maxLength:{value: 170, message: '170文字以内にしてください。'}

											})}
                      placeholder="自己紹介"
											defaultValue={userInfo.profile}
										/>
									{errors.profile && <span className="text-red-600 font-semibold text-xs h-5">{errors.profile.message}</span>}
									<label className="text-gray-900 font-bold text-sm mt-4 block">GitHubアカウント</label>
									<div className="flex -mr-px mt-1">
										<span className="text-gray-500 font-semibold bg-gray-100 border-2 border-r-0 rounded-l-lg flex items-center px-3 whitespace-no-wrap text-grey-dark text-sm">
											https://github.com/
										</span>
										<input
											name="github"
											className={"focus:outline-none border border-gray-300 appearance-none rounded-l-none rounded-r-lg px-3 py-2 w-full md:text-sm text-base" + (errors.github ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
											ref={register()}
											type="text"
											placeholder="GitHub ID"
											defaultValue={userInfo.github}
										/>
									</div>
									{errors.github && <span className="text-red-600 font-semibold text-xs h-5">{errors.github.message}</span>}
									<label className="text-gray-900 font-bold text-sm mt-4 block">Twitterアカウント</label>
									<div className="flex -mr-px mt-1">
										<span className="text-gray-500 font-semibold bg-gray-100 border-2 border-r-0 rounded-l-lg flex items-center px-3 whitespace-no-wrap text-grey-dark text-sm">
											https://twitter.com/
										</span>
										<input
											name="twitter"
											className={"focus:outline-none border border-gray-300 appearance-none rounded-l-none rounded-r-lg px-3 py-2 w-full md:text-sm text-base" + (errors.twitter ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
											ref={register()}
											type="text"
											placeholder="StackLive_jp"
											defaultValue={userInfo.twitter}
										/>
									</div>
									{errors.twitter && <span className="text-red-600 font-semibold text-xs h-5">{errors.twitter.message}</span>}
									<label className="text-gray-900 font-bold text-sm mt-4 block">ホームページ</label>
									<input
										name="homepage"
										className={"focus:outline-none border border-gray-300 appearance-none rounded px-3 py-2 mt-1 w-full md:text-sm text-base" + (errors.homepage ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
										ref={register()}
										type="text"
										placeholder="https://stacklive.jp"
										defaultValue={userInfo.homepage}
									/>
									{errors.homepage && <span className="text-red-600 font-semibold text-xs h-5">{errors.homepage.message}</span>}
									</div>
								</div>
								<div className="text-center my-6">
										<button
										id=""
										className="main-background hover:opacity-75 text-white font-bold px-4 py-2 text-ml rounded focus:outline-none"
										onClick={handleSubmit(pushFile)}
										type="button">
											{is_loading ?
											<Spinner/>
											:
											"保存"}
										</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<SuccessPopup func={successView} toggle={now_success}>変更を保存しました。</SuccessPopup>
			</React.Fragment>
	)
}
export default Edit
