import { imgSize, imgType } from './theme.js';
import config from "../../../aws-exports";

export default function Img({size, type, src, func, id}){
	// s3のディレクトリ
	const s3_dir = 'https://'+ config.aws_user_files_s3_bucket +'.s3-'+ config.aws_user_files_s3_bucket_region +'.amazonaws.com/public/'+src;
	const classNames = imgSize[size] + " " + imgType[type];
	return(
		<img src={s3_dir} id={id} className={classNames} alt="" onClick={func}/>
	)
}
