// モーダルでポイントを購入する
import React, {useState, useEffect } from 'react';
import PaymentModal from '../Molecules/paymentModal';
// Containers
import UseToggle from '../../containers/toggle.js';
import PaymentIcon from '../Atoms/paymentIcon';

// 決済のフォーム
import CheckoutForm from "./checkoutForm";

function BuyModal(props){
  const stackPoints = [
    {price: 550, point: 500 },
    {price: 1100, point: 1000 },
    {price: 3300, point: 3000 },
    {price: 5500, point: 5000 },
    {price: 11000, point: 10000 },
  ]
	const [toggle, modalToggle] = UseToggle(false);
	const [amount, setAmount] = useState('');
  const handlePayment = async(e)=>{
    const price = e.target.getAttribute('data-price');
    setAmount(price);
		modalToggle();
  }
	return(
    <React.Fragment>
      {
        toggle ?
          <CheckoutForm amount={amount}/>
        :
          <div className="w-full">
            <h1 className="font-bold text-xl pl-2 ml-4 md:my-4">StackPoint 購入</h1>
              <div className="container mb-2 flex mx-auto w-full items-center justify-center">
                <ul className="w-11/12 md:flex flex-wrap w-full rounded-lg md:py-5">
                    {stackPoints.map((stackPoint, index) => (
                      <li className="border-gray-400 flex flex-row mb-2 md:w-1/2 w-4/5 m-auto" key={index}  >
                        <div className="select-none justify-between rounded-md flex flex-1 items-center ease-in-out rounded-2xl border-2 py-4 px-4 mt-3 border-blue-300"> 
                          <div className="flex">
                            <div className="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">
                              <PaymentIcon size="history"/>
                            </div>
                            <div className="font-medium m-auto">
                              {stackPoint.point}pt
                            </div>
                          </div>
                          <div 
                            className="md:w-1/3 w-2/5 cursor-pointer hover:opacity-75 text-wrap text-center text-white text-bold flex flex-col rounded-md main-background justify-center items-center p-2" 
                            data-price={stackPoint.price}
                            onClick={handlePayment}>
                            {stackPoint.price}円
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
          </div>
      }
    </React.Fragment>
	)
}
export default BuyModal;