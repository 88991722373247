import ReactDOM from 'react-dom';
import React, {useEffect, useState } from 'react';
import Success from '../Molecules/success.js';

import BuyModal from './buy_modal';
import PaidConfirm from "./paid_confirm";
// container
import UseCurrentUser  from '../../containers/currentUser.js';
// redux
import {useDispatch ,useSelector} from 'react-redux';

import { change_user, succeeded_state ,paid_toggle, buy_point_succeeded_state} from '../../actions';
import Amplify, { API, graphqlOperation } from "aws-amplify";
import * as gqlQueries from '../../graphql/queries'
import * as gqlMutations from '../../graphql/mutations'


function PaidModal(props){
	const succeeded = useSelector(state => state.progress_state.succeeded);
	const [ buy_page, setBuyPage ] = useState(false);
	const [ lackPoint, setLackPoint ] = useState(false);
	const [ is_loading, setLoading ] = useState(false);
	const [ remain_point, setRemain ] = useState(0);
	const [ paid_confirm_page, setConfirmPage ] = useState(false);
	const [bool,currentUser] = UseCurrentUser(false);
	// 新規登録するユーザーテーブル名を環境変数から取得。ローカル開発ではenvファイルを作成して定義する。本番環境ではAmplifyで定義してある。
	const tablename = process.env.REACT_APP_USER_TABLE;
	// reduxディスパッチ
	const dispatch = useDispatch();

  const stateReset =  ()=>{
    props.modalToggle();
    setBuyPage(false);
    setConfirmPage(false);
    // succeededのステートを変更
    dispatch(succeeded_state(false));
  }
  useEffect(()=>{
    // 所持ポイントが不足しているか確認
    if(props.streamingInfo.price > props.userInfo.buy_point){
      setLackPoint(true);
    }else{
      // 所持しているポイントの残高計算
      const mypoint = props.userInfo.buy_point - props.streamingInfo.price;
      setLackPoint(false);
      // 不足していなかったら、支払った場合の残りのポイントを計算
      setRemain(mypoint);  
    }
  },[props.userInfo])
  // ポイントを使って視聴する
  const paid_point = async()=>{
    const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    setLoading(true);
    // ポイントを受け取った場合の数を計算する ※手数料を10％もらう計算
    const new_receive_point = props.streamingInfo.user.receive_point + (props.streamingInfo.price * 0.9);
    try{
			 // dynamodbにポイントをUpdate(Lambdaでトランザクションを使って行う)
      await API.graphql(graphqlOperation(gqlQueries.lambdaTransaction, 
        { 
        event: 'send_point',
        send_user: props.userInfo.id,
        receive_user: props.streamingInfo.user.id,
        remain_point: remain_point,
        get_point: Math.round(new_receive_point),
        tablename: tablename
      }));

			// 支払い済ユーザーに登録
			await API.graphql(
			graphqlOperation(gqlMutations.createPaid, {
        input: {
          streaming_id: props.streamingInfo.id,
          user_id: props.userInfo.id
        }
        })
      )
      // succeededのステートを変更
      dispatch(succeeded_state(true));
      setLoading(false);
      // 2秒成功のUIを表示
			await _sleep(2000);
      // 払ったステータスに変更
      dispatch(paid_toggle(true));
      // ユーザー状態を変更する
      currentUser();
    }catch(e){
      setLoading(false);
      alert('ポイント送付時にエラーが起きました。時間をおいてもう一度お試しください')
      console.log(e);
    }
  }
  // ポータルを使用してモーダル要素に表示
  return ReactDOM.createPortal(
      <div className={"flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-opacity-80 bg-gray-600 z-50 bg-opacity-80" + (props.toggle ? ' block': ' hidden')}>
        <div className="bg-white rounded-lg md:w-2/5 w-11/12">
          <svg className="w-6 h-6 cursor-pointer text-gray-600 mt-3 ml-3 inline hover:opacity-75" onClick={stateReset} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
          </svg>
          <div className="flex flex-col items-start p-4 w-full">
            {
              succeeded ?
                <div className="w-full">                   
                  <Success succeeded={succeeded} message="ポイントを支払いました"/>
                </div>
              :
              <React.Fragment>
                {
                  lackPoint ?
                    buy_page ?
                      // モーダルでポイントを購入するぺーじ
                      <BuyModal />
                    :
                      <div className="w-11/12 m-auto">
                        <label className="mb-3 block text-gray-500 font-bold text-center">支払い情報</label>
                        <label className="mb-3 block text-gray-700 font-bold md:text-lg">支払い額：<span className="text-red-500">{props.streamingInfo.price}pt</span></label>
                        <p className="my-5 text-gray-700 font-bold md:text-lg">現在の所持ポイント：{props.userInfo.buy_point}pt</p>
                            <div className="text-center">
                              <p className="my-3 text-red-600 font-semibold md:text-base text-sm">所持ポイントが不足しています。<span className="inline-block">ポイントを購入してください。</span></p>
                              <button 
                                className="cursor-pointer main-background hover:opacity-75 text-white font-bold px-4 py-3 text-ml rounded focus:outline-none" 
                                onClick={()=>setBuyPage(true)}>
                                StackPoint購入
                              </button>
                            </div>
                      </div>
                      :
                      paid_confirm_page ?
                        <PaidConfirm myPoint={props.userInfo.buy_point} remain_point={remain_point} setConfirmPage={setConfirmPage} is_loading={is_loading} func={paid_point}/>
                      :
                      <div className="w-11/12 m-auto">
                        <label className="mb-3 block text-gray-500 font-bold text-center">支払い情報</label>
                        <label className="mb-3 block text-gray-700 font-bold md:text-lg">支払い額：<span className="text-red-500">{props.streamingInfo.price}pt</span></label>
                        <p className="my-5 text-gray-700 font-bold md:text-lg">現在の所持ポイント：{props.userInfo.buy_point}pt</p>
                            <button 
                              className="flex mx-auto cursor-pointer main-background hover:opacity-75 text-white font-bold px-4 py-3 md:text-base text-sm rounded focus:outline-none" 
                              onClick={()=>setConfirmPage(true)}>
                                ポイントを使う
                            </button>
                      </div>
                    }
              </React.Fragment>
            }
          </div>
        </div>
      </div>,
      document.getElementById(props.targetID)
  )
}
export default PaidModal