import React from 'react';
import { Switch} from 'react-router-dom';
import Router from '../../router'
// 共通で表示する箇所
import Header from '../Organisms/Header.js';
import Footer from '../Organisms/footer.js';
import BlackModal from '../Molecules/black-modal';
import LeftMenu from '../Organisms/LeftMenu.js';
import SmLeftMenu from '../Organisms/sm-leftMenu.js';
// ページごとに変化するコンテンツ
import HomeTemp from '../Templates/Home.js';
import Trend from '../Pages/Trend.js';
import New from '../Pages/new.js';
import Reserves from '../Pages/reserves.js';
import Ranking from '../Pages/Ranking.js';
import Mypage from '../Pages/Mypage.js';
import Edit from '../Pages/edit.js';
import AccountBank from '../Pages/account_bank.js';
import CardInfo from '../Pages/card_info.js';
import TransferHistory from '../Pages/transfer_history.js';
import Liveconfig from '../Pages/Liveconfig.js';
import Search from '../Pages/search.js';
import Payment from '../Pages/payment.js';
import Payout from '../Pages/payout.js';
import Archive from '../Pages/archive.js';
import Reserve from '../Pages/reserve.js';


function Default(props){
	// suspenseするためのルーティング
const routes = [
  {path: '/',component: HomeTemp,exact: true},
  {path: '/trend',component: Trend,exact: true},
  {path: '/new',component: New,exact: true},
  {path: '/ranking',component: Ranking,exact: false},
  {path: '/reserves',component: Reserves,exact: false},
  // {path: '/user/:user_id',component: Mypage,exact: true},
  // {path: '/user/:user_id/recruit',component: Mypage, exact: true},
  {path: '/user/:user_id/edit',component: Edit,exact: true},
  {path: '/user/:user_id/bank_account',component: AccountBank,exact: true},
  {path: '/user/:user_id/card_info',component: CardInfo,exact: true},
  {path: '/user/:user_id/transfer_history',component: TransferHistory,exact: true},
  {path: '/liveconfig',component: Liveconfig,exact: false},
  {path: '/search',component: Search,exact: true},
  {path: '/search/:keyword',component: Search,exact: false},
  {path: '/payment', component: Payment, exact: false},
  {path: '/payout', component: Payout, exact: false},
  {path: '/return', component: Payout, exact: false},
  {path: '/reauth', component: Payout, exact: false},
  {path: '/archive', component: Archive, exact: false},
  {path: '/reserve/:streaming_id',component: Reserve, exact: false},
]
	return(
		<React.Fragment>
			<BlackModal targetID='modal' />
			<Header/>
			<div className="flex min-h-screen pt-16">
				{/* PCレフトメニュー */}
				<LeftMenu/>
				{/* SMレフトメニュー */}
				<SmLeftMenu/>
				<Switch>
					{routes.map((route, index) => (
						<Router key={index} {...route} />
					))}
				</Switch>
			</div>
			<Footer/>
		</React.Fragment>
	)
}
export default Default
