import { buttonSize ,buttonType } from './theme.js';
import Spinner from '../spinner';

export default function Button(props){
	const classNames = buttonSize[props.size] + ' ' + buttonType[props.type];
	return(
		<button id="" className={classNames} type="button" onClick={props.func} data-value={props.data} disabled ={props.disabled ? true : false}>
			{
				props.is_loading ?
					<Spinner/>
				:
				(props.children)
			}
		</button>
	)
}
