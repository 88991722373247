import React, {useState} from 'react';
// amplify
import Amplify, {API, graphqlOperation} from "aws-amplify";
// DB関連
import * as gqlMutations from '../../graphql/mutations'
//Atoms
import Img from '../Atoms/img';
import { Link } from 'react-router-dom';
// Molecules
import YesNoModal from '../Molecules/yes_no_modal';
// Containers
import UseToggle from '../../containers/toggle.js';

function ArchiveContent(props){
  const [ yesNoBool, switchYesNo ] = UseToggle(false);
	const tec_data =  props.channel.tec;
	delete tec_data['id'];
	delete tec_data['createdAt'];
	delete tec_data['updatedAt'];
  // 連想配列名を取得する
	let arrays = Object.keys(tec_data); 
	let new_arrays = ['StackLive'];

  // 配列のnull以外の値で新しい配列を作成する
  arrays.map(object => {
    if(tec_data[object] != null){
			new_arrays.unshift(tec_data[object]);
    }
	})

	//アーカイブを消す 
	const deleteArchive = async()=>{
		// アーカイブのフラグを下げる
		const result = await API.graphql(
			graphqlOperation(gqlMutations.updateStreaming, {
				input: {
					id: props.channel.id,
					archive: 0,
				}
			})
		)
		if(result.data.updateStreaming){
			switchYesNo();
			// 再レンダリングをする
			props.rendering();
		}
	}
	return(
			<React.Fragment >
          {/* 終了確認モーダル */}
					<YesNoModal targetID='modal' modalToggle={switchYesNo} toggle={yesNoBool} func={deleteArchive} children='アーカイブを削除します。よろしいですか？' />
					<div className={"w-1/2 md:px-2 px-1 mb-5 relative " + props.width}>
            <div className="absolute right-2 bg-gray-50 opacity-75 top-2 right-4 p-1 text-gray-600 rounded flex items-center z-10">
						{
							props.mypage ?
								<React.Fragment >
									<svg className="w-5 h-5 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" onClick={switchYesNo}>
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
									</svg>
								</React.Fragment>
							:
                <div className="user-logo flex">
								{props.channel.user.icon ?
									<Img size="content" src={props.channel.user.icon} />
										:
									<img src="/images/smiley-sticker.svg" className="rounded-full mr-1 h-4 w-auto"/>
								}
									<p className="text-xs tracking-tighter m-auto">
										{props.channel.user.name} 
									</p>
                </div>
						}
            </div>
						<div className="rounded-lg shadow-2xl">
						<Link to={"/user/" + props.channel.user.user_id + "/archive/" + props.channel.id} className="  tracking-wide" >
						<div className="md:flex-shrink-0 relative hover:opacity-75">
						<span className="flex text-gray-600 absolute bg-gray-50 opacity-75 rounded text-xs top-2 left-2 p-1">
							<svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" /></svg>
							{props.channel.archiveCount}
						</span>
						{											
							props.channel.thumbnail != null &&
							<Img size="thumbnail" src={props.channel.thumbnail} alt="thumbnail" />
						}
						{											
							props.channel.thumbnail == null &&
							<img src="/images/home-logo3.png" className="object-cover w-full md:h-48 h-36 rounded-lg rounded-b-none rounded-lg shadow-lg antialiased cursor-pointer" alt="thumbnail"/>
						}
						</div>
					</Link>
					{/* ハッシュタグ */}
						<div className="flex mt-1 overflow-scroll hash_tag">
							{
								new_arrays.map((tech,index)=>
									<div className="justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-blue-700 bg-blue-100 border border-blue-300" key={index}>
										<div className="text-xs font-normal leading-none max-w-full flex-initial">#{tech}</div>
									</div>
								)
							}
						</div>
						<div className="h-28 px-4 pt-1 pb-2 mt-1">
							<h2 className="max-h-12 overflow-clip overflow-hidden font-bold text-base text-gray-800 tracking-normal">
								{props.channel.title}
							</h2>
                <p className="max-h-12 text-xs mt-1 break-words overflow-ellipsis overflow-hidden text-gray-700">
									{props.channel.summary}
                </p>
						</div>
				</div>
    </div>
			</React.Fragment>
	)
}
export default ArchiveContent
