import React, {useState, useEffect } from 'react';
import SearchTemp from '../Templates/searchTemp.js';
// amplify
import Amplify, { API, graphqlOperation} from "aws-amplify";
import { useParams } from 'react-router';
// DB関連
import * as gqlQueries from '../../graphql/queries'

const fetchTodoList = (fetch) => {
  const promise = fetch;
  return wrapPromise(promise);
};
const wrapPromise = (promise) => {
  let status = 'pending';
  let result;

  const suspender = promise.then(
    (r) => {
      status = 'fulfilled';
      result = r;
    },
    (e) => {
      status = 'rejected';
      result = e;
  });

  const read = () => {
    if(status === 'pending') {
      throw suspender;
    } else if(status === 'rejected') {
      throw result;
    } else {
      return result;
    }
  };
  return { read };
}
function Search(props){
	// 検索キーワードを取得
	let keyword = useParams().keyword;
  if(keyword == null){
    keyword = '';
  }
	const searchResource = fetchTodoList(API.graphql(graphqlOperation(gqlQueries.listStreamings,{
    filter:{
      or: [
        { title: { contains: keyword }},
        { summary: { contains: keyword }}, 
      ],
      is_live: {eq: 1}
      },
      limit: 50}))
  );


	return(
		<React.Fragment>
			<SearchTemp resource={searchResource} keyword={keyword}/>
		</React.Fragment>
	)
}
export default Search
