const UseReserve = ()=>{
  //予約配信を取得したときに年と年月で分ける関数　 
	const make_array_reserve = async(streamings)=>{
    let reserveList_date = [];
    let years = [];
    let days = [];

    streamings.map((channel,index)=> {
      const reserve_date = new Date(channel.reserveDate);
      let yyyy = reserve_date.getFullYear();
      let mm = reserve_date.getMonth()+1;
      let dd = reserve_date.getDate();
      let hours = reserve_date.getHours();
      let minutes = reserve_date.getMinutes();
      let dayOfWeek = reserve_date.getDay() ;
      let dayOfWeekStr = [ "日", "月", "火", "水", "木", "金", "土" ][dayOfWeek] ;
      let times = '';

      if(minutes == 0){
        times = hours + ':' + '0'+ minutes
      }else{
        times = hours + ':' + minutes
      }

    
      if(years.includes(yyyy)){
        if(days.includes(mm + '/' + dd)){
          reserveList_date.push({year: yyyy, day: false, channel: channel, dayOfWeek: dayOfWeekStr,time: times});
        }else{
          days.push(mm + '/' + dd);
          reserveList_date.push({year: yyyy, day: mm + '/' + dd, channel: channel, dayOfWeek: dayOfWeekStr,time: times});
        }
      }else{
        years.push(yyyy);
        days.push(mm + '/' + dd);
        reserveList_date.push({year: yyyy, day: mm + '/' + dd, channel: channel, dayOfWeek: dayOfWeekStr,time: times});
      }
    })
    return reserveList_date
  }
  return [make_array_reserve];
}
export default UseReserve