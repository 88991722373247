// LP用のヘッダー
import React, {useState, useEffect } from 'react';
import { Link, useHistory} from 'react-router-dom';
import '../../style/header.css';
// redux
import {useSelector, useDispatch } from 'react-redux';
import { boolean_sm_menu } from '../../actions';

// Molecules
import Logo from '../Molecules/logo.js';
import HeaderList from '../Molecules/headerList.js';

function LP_Header(props){
	const dispatch = useDispatch();
	const sm_menu = useSelector(state => state.smMenu.sm_menu);
	const smMenuToggle = ()=>{
		dispatch(boolean_sm_menu());
	}
	return(
		<header className={"border-gray-200"}>
			<div className={"w-full px-4 text-gray-700 z-20 fixed top-0 shadow border-b bg-white"}>
				<div className="md:w-9/12 flex mx-auto">
					<div className="py-4 flex justify-between w-full">
							{/*StackLiveロゴ*/}
							<Link to="#top" className="md:m-0">
								<Logo dark={props.dark} size="md"/>
							</Link>
						<ul className="flex">
              <a href='#about' className="md:flex hidden place-content-center">
                <li className="font-bold text-black mx-4 my-auto">
                  StackLiveとは
                </li>
              </a>
              <a href='#price' className="md:flex hidden place-content-center">
                <li className="font-bold text-black mx-4 my-auto">
                  料金プラン
                </li>
              </a>
              <a href='#faq' className="md:flex hidden place-content-center">
                <li className="font-bold text-black mx-4 my-auto">
                  よくあるご質問
                </li>
              </a>
              <li className="block">
                <Link to='/register' className="inline-block flex place-content-center">
                  <div className="main-background hover:opacity-75 text-white font-bold py-3 px-4 rounded mr-2 text-sm">
                    無料ではじめる
                  </div>
                </Link>
              </li>
						</ul>
					</div>
				</div>
			</div>
		</header>
	)
}
export default LP_Header;
