/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStreaming = /* GraphQL */ `
  mutation CreateStreaming(
    $input: CreateStreamingInput!
    $condition: ModelStreamingConditionInput
  ) {
    createStreaming(input: $input, condition: $condition) {
      id
      title
      summary
      is_live
      thumbnail
      user_id
      tec_id
      viewerCount
      max_viewerCount
      archive
      archive_file
      archiveCount
      reserve
      reserveDate
      announce_url
      reserve_peoples
      is_paid
      price
      createdAt
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      tec {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateStreaming = /* GraphQL */ `
  mutation UpdateStreaming(
    $input: UpdateStreamingInput!
    $condition: ModelStreamingConditionInput
  ) {
    updateStreaming(input: $input, condition: $condition) {
      id
      title
      summary
      is_live
      thumbnail
      user_id
      tec_id
      viewerCount
      max_viewerCount
      archive
      archive_file
      archiveCount
      reserve
      reserveDate
      announce_url
      reserve_peoples
      is_paid
      price
      createdAt
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      tec {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteStreaming = /* GraphQL */ `
  mutation DeleteStreaming(
    $input: DeleteStreamingInput!
    $condition: ModelStreamingConditionInput
  ) {
    deleteStreaming(input: $input, condition: $condition) {
      id
      title
      summary
      is_live
      thumbnail
      user_id
      tec_id
      viewerCount
      max_viewerCount
      archive
      archive_file
      archiveCount
      reserve
      reserveDate
      announce_url
      reserve_peoples
      is_paid
      price
      createdAt
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      tec {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      user_id
      tec_id
      logininfo
      icon
      profile
      all_viewerCount
      createdAt
      updatedAt
      tec {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      buy_point
      receive_point
      custom_account
      customer
      twitter
      github
      homepage
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      user_id
      tec_id
      logininfo
      icon
      profile
      all_viewerCount
      createdAt
      updatedAt
      tec {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      buy_point
      receive_point
      custom_account
      customer
      twitter
      github
      homepage
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      user_id
      tec_id
      logininfo
      icon
      profile
      all_viewerCount
      createdAt
      updatedAt
      tec {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      buy_point
      receive_point
      custom_account
      customer
      twitter
      github
      homepage
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      uid
      channel
      comment
      icon
      gift
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      uid
      channel
      comment
      icon
      gift
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      uid
      channel
      comment
      icon
      gift
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTec = /* GraphQL */ `
  mutation CreateTec(
    $input: CreateTecInput!
    $condition: ModelTecConditionInput
  ) {
    createTec(input: $input, condition: $condition) {
      id
      JavaScript
      Python
      Ruby
      SQL
      HTML
      Nextjs
      Vuejs
      Rails
      Go
      AWS
      TypeScript
      PHP
      Laravel
      Docker
      Rust
      COBOL
      R
      Java
      Swift
      Git
      React
      createdAt
      updatedAt
    }
  }
`;
export const updateTec = /* GraphQL */ `
  mutation UpdateTec(
    $input: UpdateTecInput!
    $condition: ModelTecConditionInput
  ) {
    updateTec(input: $input, condition: $condition) {
      id
      JavaScript
      Python
      Ruby
      SQL
      HTML
      Nextjs
      Vuejs
      Rails
      Go
      AWS
      TypeScript
      PHP
      Laravel
      Docker
      Rust
      COBOL
      R
      Java
      Swift
      Git
      React
      createdAt
      updatedAt
    }
  }
`;
export const deleteTec = /* GraphQL */ `
  mutation DeleteTec(
    $input: DeleteTecInput!
    $condition: ModelTecConditionInput
  ) {
    deleteTec(input: $input, condition: $condition) {
      id
      JavaScript
      Python
      Ruby
      SQL
      HTML
      Nextjs
      Vuejs
      Rails
      Go
      AWS
      TypeScript
      PHP
      Laravel
      Docker
      Rust
      COBOL
      R
      Java
      Swift
      Git
      React
      createdAt
      updatedAt
    }
  }
`;
export const createReserve = /* GraphQL */ `
  mutation CreateReserve(
    $input: CreateReserveInput!
    $condition: ModelReserveConditionInput
  ) {
    createReserve(input: $input, condition: $condition) {
      id
      streaming_id
      user_id
      flag
      createdAt
      updatedAt
    }
  }
`;
export const updateReserve = /* GraphQL */ `
  mutation UpdateReserve(
    $input: UpdateReserveInput!
    $condition: ModelReserveConditionInput
  ) {
    updateReserve(input: $input, condition: $condition) {
      id
      streaming_id
      user_id
      flag
      createdAt
      updatedAt
    }
  }
`;
export const deleteReserve = /* GraphQL */ `
  mutation DeleteReserve(
    $input: DeleteReserveInput!
    $condition: ModelReserveConditionInput
  ) {
    deleteReserve(input: $input, condition: $condition) {
      id
      streaming_id
      user_id
      flag
      createdAt
      updatedAt
    }
  }
`;
export const createNotices = /* GraphQL */ `
  mutation CreateNotices(
    $input: CreateNoticesInput!
    $condition: ModelNoticesConditionInput
  ) {
    createNotices(input: $input, condition: $condition) {
      id
      receive_user
      send_user
      contents
      streaming_id
      flag
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      streaming {
        id
        title
        summary
        is_live
        thumbnail
        user_id
        tec_id
        viewerCount
        max_viewerCount
        archive
        archive_file
        archiveCount
        reserve
        reserveDate
        announce_url
        reserve_peoples
        is_paid
        price
        createdAt
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNotices = /* GraphQL */ `
  mutation UpdateNotices(
    $input: UpdateNoticesInput!
    $condition: ModelNoticesConditionInput
  ) {
    updateNotices(input: $input, condition: $condition) {
      id
      receive_user
      send_user
      contents
      streaming_id
      flag
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      streaming {
        id
        title
        summary
        is_live
        thumbnail
        user_id
        tec_id
        viewerCount
        max_viewerCount
        archive
        archive_file
        archiveCount
        reserve
        reserveDate
        announce_url
        reserve_peoples
        is_paid
        price
        createdAt
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotices = /* GraphQL */ `
  mutation DeleteNotices(
    $input: DeleteNoticesInput!
    $condition: ModelNoticesConditionInput
  ) {
    deleteNotices(input: $input, condition: $condition) {
      id
      receive_user
      send_user
      contents
      streaming_id
      flag
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      streaming {
        id
        title
        summary
        is_live
        thumbnail
        user_id
        tec_id
        viewerCount
        max_viewerCount
        archive
        archive_file
        archiveCount
        reserve
        reserveDate
        announce_url
        reserve_peoples
        is_paid
        price
        createdAt
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPaid = /* GraphQL */ `
  mutation CreatePaid(
    $input: CreatePaidInput!
    $condition: ModelPaidConditionInput
  ) {
    createPaid(input: $input, condition: $condition) {
      id
      streaming_id
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const updatePaid = /* GraphQL */ `
  mutation UpdatePaid(
    $input: UpdatePaidInput!
    $condition: ModelPaidConditionInput
  ) {
    updatePaid(input: $input, condition: $condition) {
      id
      streaming_id
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const deletePaid = /* GraphQL */ `
  mutation DeletePaid(
    $input: DeletePaidInput!
    $condition: ModelPaidConditionInput
  ) {
    deletePaid(input: $input, condition: $condition) {
      id
      streaming_id
      user_id
      createdAt
      updatedAt
    }
  }
`;
