// 個人向けの紹介ページ
import React from 'react';
import Footer from '../Organisms/footer.js';
import Header from '../Organisms/Header.js';
// Molecules
import BlackModal from '../Molecules/black-modal';
import SmLeftMenu from '../Organisms/sm-leftMenu.js';
import { Link } from 'react-router-dom';
function About(props){
	return(
		<React.Fragment>
				<BlackModal targetID='modal' />
				<Header />
        <div className="pt-16">
					{/* SMレフトメニュー */}
					<SmLeftMenu/>
            <div className="container md:flex md:my-40 my-24 mx-auto">
              <div className="md:mt-28 mb-10 md:ml-20 about_color">
                <h1 className="2xl:text-5xl md:text-4xl text-3xl md:font-bold font-black text-center">
                  <span className="text-blue-600">エンジニア</span>向け
                  <br/>ライブ配信<span className="inline-block">プラットフォーム</span></h1>
                <p className="text-center font-bold my-5">ライブ配信で技術と生活を豊かに。</p>
								<Link to="/" className="md:flex hidden fit-content m-auto mt-10">
                  <p className="main-background hover:opacity-75 font-bold py-3 px-4 rounded md:text-lg text-sm text-white">
                    今すぐ始める
                  </p>
								</Link>
              </div>
              <div className="block md:w-1/2 w-full md:h-auto h-72">
                <img src="/images/pc_phone.png" className="mx-auto h-full"/>
              </div>
            </div>
            <div className="w-full about_background md:mt-0 mt-10">
              <div className="w-full container mx-auto">
                <div className="md:flex md:mx-10 mx-0 md:py-28 py-10">
                  <div className="w-full p-3 h-auto about_color mx-auto">
                    <h1 className="font-bold md:text-4xl text-3xl text-center">StackLiveの<span className="text-6xl text-blue-600">3</span>つの特徴</h1>
                    <ul className="md:mt-16 mt-8 md:flex">
                      <li className="md:w-1/3 md:mx-4 mx-2 md:my-0 my-6 p-4 bg-white rounded-lg">
                        <h2 className="text-blue-600 font-bold text-xl text-center">エンジニア限定のプラットフォーム</h2>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-14 mx-auto my-5" fill="#091159" viewBox="0 0 256 256">
                          <rect width="256" height="256" fill="none"></rect>
                          <circle cx="127.88745" cy="44" r="28" fill="none" stroke="#091159" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></circle>
                          <path d="M153.91044,134.715,188.657,161.74012a12,12,0,1,0,16.97056-16.97057l-39.598-50.91169A19.93738,19.93738,0,0,0,151.88746,88h-48a19.93739,19.93739,0,0,0-14.14213,5.85786l-39.598,50.91169A12,12,0,1,0,67.1179,161.74012L101.86446,134.715,74.13088,222.3089a12,12,0,0,0,21.75139,10.14284l32.0052-50.37108h0l32.0052,50.37108A12,12,0,0,0,181.644,222.3089Z" fill="none" stroke="#091159" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
                        </svg>
                        <div className="about_color font-medium text-sm w-10/12 mx-auto">エンジニア限定のプラットフォームなので、発信した情報を効率よく届けることができます。</div>
                      </li>
                      <li className="md:w-1/3 md:mx-4 mx-2 md:my-0 my-6 p-4 bg-white rounded-lg">
                        <h2 className="text-blue-600 font-bold text-xl text-center">スキルや知見を報酬に還元</h2>
                        <img src="/images/exchange.svg" className="mx-auto h-16 my-5"/>
                        <div className="about_color font-medium text-sm w-10/12 mx-auto">スキルや知見をライブ配信することで投げ銭や有料配信により報酬を得ることができます。</div>
                      </li>
                      <li className="md:w-1/3 md:mx-4 mx-2 md:my-0 my-6 p-4 bg-white rounded-lg">
                        <h2 className="text-blue-600 font-bold text-xl text-center">画面共有なので顔出しなし</h2>
                        <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-14 my-5" fill="#091159" viewBox="0 0 256 256">
                          <rect width="256" height="256" fill="none"></rect>
                          <path d="M31.99414,96V56a8,8,0,0,1,8-8h176a8,8,0,0,1,8,8V200a8,8,0,0,1-8,8h-72" fill="none" stroke="#091159" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M31.99414,192a16,16,0,0,1,16,16" fill="none" stroke="#091159" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M31.99414,160a48,48,0,0,1,48,48" fill="none" stroke="#091159" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
                          <path d="M31.99414,128a80,80,0,0,1,80,80" fill="none" stroke="#091159" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
                        </svg>
                        <div className="about_color font-medium text-sm w-10/12 mx-auto">PCの画面を映すだけでライブ配信をすることができます。顔出しするのに抵抗がある方でも気軽に始めることができます。</div>
                      </li>
                    </ul>
                    {/* 三角 */}
                    {/* <div className="w-full flex justify-center my-12">
                      <span className="inline-block triangle-about"></span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
              <div className="container w-full mx-auto md:py-20 pt-14">
                <div className="font-bold md:text-4xl text-3xl text-center about_color">StackLiveでできること</div>
                <div className="md:flex md:mx-10 mx-0 md:my-14 my-20">
                  <div className="md:w-1/2 w-full p-3 h-auto about_color m-auto shadow-lg rounded-lg relative border border-gray-100">
                    <span className="text-blue-600 md:text-8xl text-6xl font-bold absolute md:-top-12 -top-8">01</span>
                    <p className="text-gray-600 mb-3 text-center">Screen Share</p>
                    <h1 className="font-bold md:text-3xl text-2xl text-center">画面共有で簡単にライブ配信</h1>
                    <p className="text-gray-600 md:text-base text-sm my-5 md:px-8 px-4">別途ソフトを用意する必要はなく、簡単に画面共有でライブ配信を始めることができます。顔を出す必要もなく誰でも気軽に配信をすることができます。</p>
                    {/* スマホで表示 */}
                    <div className="md:w-1/2 w-full md:hidden block">
                      <img src="/images/live_pc_2.png" className="inline-block"/>
                    </div>
                  </div>
                  {/* pcで表示 */}
                  <div className="md:w-1/2 w-full md:block hidden">
                    <img src="/images/live_pc_2.png" className="inline-block"/>
                  </div>
                </div>
                <div className="md:flex md:mx-10 mx-0 md:my-14 my-20">
                  {/* pcで表示 */}
                  <div className="md:w-1/2 w-full text-center md:block hidden">
                    <img src="/images/gift_mock.png" className="mx-auto h-100"/>
                  </div>
                  <div className="md:w-1/2 w-full p-3 h-auto about_color m-auto shadow-lg rounded-lg relative border border-gray-100">
                    <span className="text-blue-600 md:text-8xl text-6xl font-bold absolute md:-top-12 -top-8">02</span>
                    <p className="text-gray-600 mb-3 text-center">Receive Gift</p>
                    <h1 className="font-bold md:text-3xl text-2xl text-center">投げ銭機能で報酬をGet</h1>
                    <p className="text-gray-600 md:text-base text-sm my-5 md:px-8 px-4">視聴者から投げ銭をもらい、報酬に還元する事ができます。培った技術や知見はライブ配信で報酬に還元しましょう。</p>
                    {/* スマホで表示 */}
                    <div className="md:w-1/2 w-full text-center md:hidden block">
                      <img src="/images/gift_mock.png" className="mx-auto h-72"/>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:mx-10 mx-0 md:my-14 my-20">
                  <div className="md:w-1/2 w-full p-3 h-auto about_color m-auto shadow-lg rounded-lg relative border border-gray-100">
                    <span className="text-blue-600 md:text-8xl text-6xl font-bold absolute md:-top-12 -top-8">03</span>
                    <p className="text-gray-600 mb-3 text-center">Paid LiveStreaming</p>
                    <h1 className="font-bold md:text-3xl text-2xl text-center">有料配信で視聴者と深いつながりを</h1>
                    <p className="text-gray-600 sm:text-base my-5 md:px-8 px-4">濃い内容の配信は有料にすることでより多くの報酬を得ることができます。需要のある配信をして、しっかりと対価を獲得しましょう。</p>
                    {/* スマホで表示 */}
                    <div className="md:w-1/2 w-full justify-center md:hidden flex">
                      <img src="/images/live_pc_2.png" className="inline-block"/>
                    </div>
                  </div>
                  {/* pcで表示 */}
                  <div className="md:w-1/2 w-full justify-center md:flex hidden">
                    <img src="/images/live_pc_2.png" className="inline-block"/>
                  </div>
                </div>
              </div>
          <div className="about_background pt-10">
            <h1 className="md:text-4xl text-2xl font-bold about_color mb-4 text-center">2ステップで簡単ライブ配信</h1>
            <p className="text-gray-600 w-4/5 mx-auto mt-4 md:text-center ">ライブ配信には別のソフトを用意していただいたり、審査などはありません。<br className="md:block hidden"/>2ステップでいつでも簡単にライブ配信を行うことができます。
            </p>
            <div className="flex justify-center">
              <div className="my-10">
                <span className="circle_background text-white mx-auto rounded-full md:h-10 md:w-10 h-8 w-8 flex items-center justify-center my-10">1</span>
                <img src="/images/iphone.png" className="mx-auto md:h-48 h-32"/>
                <p className="text-gray-800 font-bold text-center my-5">会員登録</p>
              </div>
                <svg xmlns="http://www.w3.org/2000/svg" className="my-auto md:mx-16 mx-6 icon icon-tabler icon-tabler-arrow-big-right" width="44" height="44" viewBox="0 0 24 24" strokeWidth="0" stroke="#1e7198" fill="#1e7198" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M4 9h8v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1z" />
                </svg>
              <div className="my-10">
                <span className="circle_background text-white mx-auto rounded-full md:h-10 md:w-10 h-8 w-8 flex items-center justify-center my-10">2</span>
                <img src="/images/human.png" className="mx-auto md:h-48 h-32"/>
                <p className="text-gray-800 font-bold text-center my-5">配信</p>
              </div>
            </div>
          </div>
          {/* <div className="about_background py-20">
            <h1 className="md:text-4xl text-2xl font-bold about_color text-center">より良い技術のサイクルを</h1>
            <p className="text-gray-600 md:text-center my-6 font-semibold px-6">エンジニアがLIVE配信でより深いコミュニケーションができること。
            <br className="md:block hidden"/>共有した知見やアイデアに対してコミュニケーションを通じ、より良いものにしていくこと。
            <br className="md:block hidden"/>共有した人にはしっかりと対価が払われること。
            <p className="my-5">私たちはこれらの思いをStackLiveに込めています。</p>
            <p className="my-5">あなたの貴重な知見を共有しよりよい技術のサイクルを回していきましょう。</p>
            </p>
          </div> */}
          <div className="py-20">
            <h1 className="md:text-4xl text-2xl font-bold about_color mb-4 text-center">よくある質問</h1>
            <ul className="py-2 flex md:justify-around overflow-scroll overflow-auto hash_tag">
              <li className="md:w-1/6 md:mx-0 mx-2 p-2 py-4 about_background rounded-lg">
                <p className="font-bold py-2 md:w-auto w-52">
                  <span className="text-main text-2xl mr-2">Q</span>
                  StackLiveとはなんですか？
                </p>
                <p className="text-sm text-gray-600">StackLiveはエンジニア向けのライブ配信サービスです。
                <br/>画面共有をしながら知見を共有しコミュニケーションをとる事ができます。
                </p>
              </li>
              <li className="md:w-1/6 md:mx-0 mx-2 p-2 py-4 about_background rounded-lg">
                <p className="font-bold py-2 md:w-auto w-52">
                  <span className="text-main text-2xl mr-2">Q</span>
                  どんな使い方ができますか？
                </p>
                <p className="text-sm text-gray-600">あくまで一例ではありますが、このような使い方をしていただいています。
                <br/>・QiitaやZennなどに投稿した記事の解説。
                <br/>・普段の作業風景を配信。
                </p>
              </li>
              <li className="md:w-1/6 md:mx-0 mx-2 p-2 py-4 about_background rounded-lg">
                <p className="font-bold py-2 md:w-auto w-52">
                  <span className="text-main text-2xl mr-2">Q</span>
                  投げ銭はできますか？
                </p>
                <p className="text-sm text-gray-600">
                  メッセージ付きギフトとノーマルギフトを送ることができます。
                  <br/>
                  ギフトの種類には1pt〜10,000ptまで種類豊富に揃えております。
                </p>
              </li>
              <li className="md:w-1/6 md:mx-0 mx-2 p-2 py-4 about_background rounded-lg">
                <p className="font-bold py-2  md:w-auto w-52">
                  <span className="text-main text-2xl mr-2">Q</span>
                  LIVE配信をする為に用意するものはありますか？
                </p>
                <p className="text-sm text-gray-600">
                  現時点では内蔵マイクや別付けのマイクの環境が必要です。
                  <br/> 今後ミュート機能を対応予定です。
                </p>
              </li>
              <li className="md:w-1/6 md:mx-0 mx-2 p-2 py-4 about_background rounded-lg">
                <p className="font-bold py-2 md:w-auto w-52">
                  <span className="text-main text-2xl mr-2">Q</span>
                  LIVE配信ができる動作環境はなんですか？
                </p>
                <p className="text-sm text-gray-600">現時点で動作する環境は以下になります。
                <br/>・Google Chrome58以降(水準)
                <br/>・Firefox56以降
                <br/>・Edge Browser80以降
                <br/>・Opera45以降
                </p>
              </li>
            </ul>
          </div>
        </div>
			<Footer/>
		</React.Fragment>
    )
}
export default About

