//処理を待ってもらうためのモーダル 
import ReactDOM from 'react-dom';
import React, {useState} from 'react';

function WaitModal(props){
  return ReactDOM.createPortal(
    <div className={"flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-opacity-80 bg-gray-600 z-20 bg-opacity-80" + (props.toggle ? ' block': ' hidden')}>
      <div className="bg-white border p-5 rounded-lg flex items-center flex-col">
        <div className="loader-dots block relative w-20 h-5 mt-2">
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
        </div>
        <div className="text-gray-500 text-xs font-light mt-4 text-center">
          {props.children}
        </div>
      </div>
    </div>,
      document.getElementById(props.targetID)
  )
}
export default WaitModal