import React, {useState, useEffect } from 'react';
// redux
import {useDispatch ,useSelector} from 'react-redux';
import Amplify, {API, graphqlOperation, loadingBarFill} from "aws-amplify";
import InfiniteScroll from 'react-infinite-scroller';
import ReserveContent from '../Organisms/reserve_content.js'; 
import NoResults from '../Molecules/no-results.js';
// Atoms
import Spinner from '../Atoms/spinner';
// DB関連
import * as gqlQueries from '../../graphql/queries'
// Containers
import UseReserve from '../../containers/reserved_func.js';

// DynamoDBから降順でuser_id,viewerCountを取得する
function Reserves(props){
	const [ token, setNextToken ] = useState('');
	// ライブ配信中のチャンネルの管理
	const [stream, setStream] = useState([]);
	const [bool, setHasMore] = useState(true);  
	const [noContent, setContent] = useState(true);  
	// 予約配信を配列にまとめる関数
	const [make_array_reserve] = UseReserve();
	// 今の日付と時間を管理
	const reserve_schedule = useSelector(state => state.now_schedule.date);

	const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
	useEffect(()=>{
    // 予約配信の20件を取得する。
		(async function dblistStream(){
            const  { data: { byReserveDate : { items: itemsPage1, nextToken } } } = await API.graphql({query: gqlQueries.byReserveDate,variables: { reserve: 1,reserveDate: {ge: reserve_schedule.today_str}, sortDirection: "ASC", limit:18}});
						if(itemsPage1.length == 0){
							setContent(false);
						}else{
							let result = await make_array_reserve(itemsPage1);
							setNextToken(nextToken);
							setStream(result);
							// 最初の読み込みの段階で残りのコンテンツが5件以上なければ終了
							if(nextToken == null){
									setHasMore(false);
							}
						}
		})();
	},[]
	)
	// 次のページを読み込む
  async function loadmore(){
			await _sleep(500);
			if(bool == true){
				//次のコンテンツを読み込む
        const  { data: { byReserveDate : { items: itemsPage2, nextToken } } } = await API.graphql({query: gqlQueries.byReserveDate,variables: { reserve: 1,reserveDate: {ge: reserve_schedule.today_str}, sortDirection: "ASC", limit:6, nextToken: token}});
				let nextList = await make_array_reserve(itemsPage2);
				setStream([...stream,...nextList]);
				setNextToken(nextToken);
				// 読み込みを終了 
				if(nextToken == null){
						setHasMore(false);
				}
			}
	}
	// 追加コンテンツ
const items = (
	<div className="flex mx-auto flex-wrap">
		{						
			stream.map((reserveList,index)=>
            <React.Fragment  key={index}>
                {
                  reserveList.year == reserve_schedule.year ? 
                    reserveList.day != false &&
                      <div className="w-full text-gray-700 md:m-6 mx-6 my-4 font-bold md:text-2xl text-xl">
                        {reserveList.day} ({reserveList.dayOfWeek})
                      </div>
                  :
                  <div className="w-full text-gray-700 m-6 font-bold md:text-2xl text-xl">
                    {reserveList.year}.{reserveList.day} ({reserveList.dayOfWeek})
                  </div>
                }
                <ReserveContent channel={reserveList.channel} thumbnail={reserveList.channel[1]} time={reserveList.time} width="md:w-1/3"/>
            </React.Fragment>
				// <ReserveContent channel={channel} thumbnail={channel[1]} key={index} width="md:w-1/3"/>
			)
		}
	</div>
	);
	// 追加したコンテンツを読み込み中ローディング
	const loading = (
		<div key="1" className="my-5">
			<Spinner/>
		</div>	
	);
	return(
			<React.Fragment>
				<div className="w-full">
					<h1 className="font-bold text-xl pl-2 ml-4 my-4">予約配信</h1>
					{
						noContent ? 
							<div className="flex mx-auto flex-wrap justify-center">
							<InfiniteScroll
									loadMore={loadmore}
									hasMore={bool}
									initialLoad={false}
									style={{width: '100%'}}
									loader={loading}
									>
									{items}
							</InfiniteScroll>
							</div>
						:
							<NoResults children="予約中の配信がありません...."/>
					}
				</div>
			</React.Fragment>
	)
}
export default Reserves;