import React, {useState, useEffect } from 'react';
// フォームを扱うライブラリ
import { useForm } from "react-hook-form";
import { Link, useHistory} from 'react-router-dom';
import Amplify, { Auth, API, graphqlOperation, Hub} from "aws-amplify";
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
// redux
import {useDispatch } from 'react-redux';
import { login_now, change_user } from '../actions';
// Molecules
import Logo from './Molecules/logo.js';
import ErrorCard from './Molecules/error-card.js';
import GitHubLogin from './Molecules/github_login.js';
// Containers
import UseSignIn from '../containers/signIn.js';

// Atoms
import Spinner from './Atoms/spinner';
// DB関連
import * as gqlQueries from '../graphql/queries'
import * as gqlMutations from '../graphql/mutations'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

function Login (props){
	// reduxディスパッチ
	const dispatch = useDispatch();
	// react-hook-formで使用する
	const { register, handleSubmit, watch, errors } = useForm();
	const [ is_error, errorToggle ] = useState(false);
	const [ is_loading, setLoading ] = useState(false);
	const [error_message, setMessage] = useState('');
	// ログイン
	const [ signIn,updateUser ] = UseSignIn();

	let history = useHistory();
	// 最初のログインか確認
	async function firstLogin(userId, count){
		// 初回ログイン処理
		if(count == 0){
			// authにリダイレクト
			history.push("/welcome");
		}else{
			// ホームへリダイレクト
			history.push('/');
		};
	}
	async function onSubmit(data){
		setLoading(true);
    nprogress.start();
    await Auth.signIn(data.email, data.password).then(async(result)=>{
			const user = await updateUser(result.username);
			await firstLogin(user.id, user.logininfo);
			nprogress.done();
			setLoading(false);
			dispatch(login_now(true));
			// ユーザー状態が変更した事を発火
			dispatch(change_user());
		}).catch((err)=>{
			console.log(err);
			nprogress.done();
			setLoading(false);
			setMessage('メールアドレスかパスワードが間違っています')
			errorToggle(true);
			return;
		});
	};
	// リアルタイムでフォームを監視
  watch('email')
  watch('password')

	// googleでのログイン処理
	const googleLogin = async()=>{
		await Auth.federatedSignIn({ provider: 'Google' })
	}
	return(
				<div className="min-h-screen pt-16 md:pt-20 bg-gray-100 flex flex-col">
					<div className="md:px-10 xs:p-0 mx-auto md:w-full md:max-w-lg w-10/12">
					<h1 className="font-bold text-center text-2xl mb-5">
						{/*StackLiveロゴ*/}
						<Link to="/" className="inline-block">
							<Logo dark={props.dark} size="lx"/>
						</Link>
					</h1>
					{/* エラーメッセージ文言 */}
					<ErrorCard toggle={is_error} children={error_message} func={()=> errorToggle(false)}/>
					<div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
					<form onSubmit={handleSubmit(onSubmit)} className="py-12 w-10/12 m-auto">
						<label className="font-semibold text-gray-600 pb-1 block md:text-base text-sm" htmlFor="mailaddress">メールアドレス</label>
						<input
							name="email"
							type="text"
							className={"focus:outline-none border appearance-none rounded-lg px-3 py-2 mt-1 w-full md:text-sm text-base" + (errors.email ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
							ref={register({
								required: {
									value: true,
									message: "メールアドレスを入力してください"
								},
								pattern: {
									value: /^[A-Za-z0-9]{1}[A-Za-z0-9\._+-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
									message: 'メールアドレスの形式が正しくありません',
								},
							})}
							placeholder="info@stacklive.jp"
							/>
						{errors.email && <span className="text-red-600 font-semibold text-xs">{errors.email.message}</span>}
						<label className="font-semibold text-gray-600 pb-1 block md:text-base text-sm mt-6" htmlFor="password">パスワード</label>
						<input
							name="password"
							className={"focus:outline-none border appearance-none rounded-lg px-3 py-2 mt-1 w-full md:text-sm text-base" + (errors.password ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
							ref={register({ required: true })}
							type="password"
							placeholder="******************"
						/>
						{errors.password && <span className="text-red-600 font-semibold text-xs">パスワードを入力してください</span>}
						<button
							type="submit"
							className="cursor-pointer hover:opacity-75 m-auto mt-4 py-2.5 font-semibold flex items-center justify-center text-white rounded-lg shadow-md bg-gray-100 main-background md:w-4/6 w-full"
						>
						{is_loading ?
						<Spinner/>
						:
						"ログイン"}
						</button>
						{/* googleログインボタン */}
            <div onClick={googleLogin} className="cursor-pointer hover:opacity-75 md:w-4/6 w-full py-1 m-auto mt-4 flex items-center justify-center text-white rounded-lg shadow-md bg-gray-100">
                <div className="px-3 py-2">
                    <svg className="h-6 w-6" viewBox="0 0 40 40">
                        <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#FFC107"/>
                        <path d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z" fill="#FF3D00"/>
                        <path d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z" fill="#4CAF50"/>
                        <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#1976D2"/>
                    </svg>
                </div>
                <h1 className="px-4 py-2 w-5/6 text-center text-gray-600 font-bold">Sign in with Google</h1>
            </div>
					</form>
						<div className="py-5">
							<div className="grid grid-cols-2 gap-1">
							<div className="text-center sm:text-left whitespace-nowrap">
								<Link to="/forgot" className="mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-gray-500 focus:outline-none">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4 inline-block align-text-top">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
									</svg>
									<span className="inline-block ml-1 hover:text-gray-700">パスワードを忘れた方</span>
								</Link>
								</div>
								<div className="text-center sm:text-right  whitespace-nowrap">
								<Link to="/register" className="mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-gray-500 focus:outline-none">
									<span className="inline-block ml-1 text-main font-bold hover:opacity-75">新規登録</span>
								</Link>
								</div>
							</div>
						</div>
					</div>
					</div>
				</div>
	)
}

export default Login;
