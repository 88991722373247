import { imgSize, imgType } from './theme.js';

export default function Input({css, type, placeholder, func, value}){
	const classNames = imgSize[css];
	return(
    <input className={classNames}
    type={type}
    name={type} 
    placeholder={placeholder} 
    onChange={func}
    defaultValue={value}
    />
	)
}
