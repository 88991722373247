// spinners
import { SpinnerCircularFixed } from 'spinners-react';

// spinner処理
function Spinner(props){
	let size = 0;
	if(props.size == 'lg'){
			size = 35;
	}else{
			size = 20;
	}
	return(
		<SpinnerCircularFixed className="mx-auto" size={size} thickness={180} speed={100} color="rgba(255, 255, 255, 1)" secondaryColor="rgba(51, 150, 255, 1)" />
	)
}
export default Spinner
