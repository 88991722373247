// ライブ配信を開始する際のモーダル
import ReactDOM from 'react-dom';
import React, {useState} from 'react';
import UseToggle from '../../containers/toggle.js';
import Button from '../Atoms/button';

function LiveStartModal(props){
	const [is_checked, checkToggle] = UseToggle(true);

  return ReactDOM.createPortal(
      <div className={"flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-opacity-80 bg-gray-600 z-20 bg-opacity-80" + (props.toggle ? ' block': ' hidden')}>
        <div className="bg-white rounded-lg w-1/2">
          <svg className="w-6 h-6 cursor-pointer text-gray-600 mt-3 ml-3 inline hover:opacity-75" onClick={props.modalToggle} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
          </svg>
          <div className="">
            <div className="flex flex-col text-center items-center text-gray-800 font-bold mt-5">
              いつでも見返せるように、アーカイブを残してみましょう！
              {/* <p className="text-gray-600 text-sm text-red-600">※今後アーカイブは視聴回数によって報酬が支払われる予定です。</p> */}
              <p className="inline-flex items-center mt-3">
                  <input type="checkbox" className="inline-block h-5 w-5 text-blue-600 cursor-pointer" checked={is_checked} onClick={checkToggle} ref={props.inputRef} />
                  <span className="ml-2 text-gray-700 m-auto">配信映像のアーカイブを残す</span>
              </p>
            </div>
          </div>
          <div className="flex">
            <Button size="liveConfig" type="abled" children="配信"  func={props.startStream} />
          </div>
        </div>
      </div>,
      document.getElementById(props.targetID)
  )
}
export default LiveStartModal