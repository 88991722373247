import React from 'react';
import Button from '../Atoms/button';

function AnalyticsTab(props){
	return(
		<div className="bg-gray-100 border">
				<nav className="w-9/12 mx-auto flex flex-row ">
          <Button size="tab"  type="activetab" children="レポート"/>
				</nav>
		</div>
	)
}

export default AnalyticsTab 