//thanksモーダル 
import ReactDOM from 'react-dom';
import React, {useState} from 'react';
// snsシェアボタンを作ってくれている
import {
    TwitterShareButton,
    TwitterIcon
} from 'react-share';

function ThanksModal(props){
  const url = window.location.protocol + '//' + window.location.host;
	const archiveUrl = url + '/user/' + props.user_id + '/archive/' + props.channelId;
  return ReactDOM.createPortal(
      <div className={"flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-opacity-80 bg-gray-600 z-20 bg-opacity-80" + (props.toggle ? ' block': ' hidden')}>
        <div className="bg-white rounded-lg w-1/2 pb-5">
          <svg className="w-6 h-6 cursor-pointer text-gray-600 mt-3 ml-3 inline hover:opacity-75" onClick={props.modalToggle} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
          </svg>
          <div className="text-center">
            <span className="text-7xl">&#x1f609;</span>
            <p className="text-center text-gray-800 font-bold my-5">
              配信おつかれさまでした！
              <br/>
              {
                props.archive ?
                  <React.Fragment>
                    <span>アーカイブをシェアしてみませんか？</span>
                    <TwitterShareButton url={archiveUrl} title={props.title} hashtags={['stacklive']} className="w-7 h-7 block mx-auto mt-5">
                          <TwitterIcon size={35} borderRadius={5} />
                    </TwitterShareButton>
                  </React.Fragment>
                :
                  <React.Fragment>
                    <span>配信の感想をシェアしてみませんか？</span>
                    <TwitterShareButton url='https://stacklive.jp' hashtags={['stacklive']} className="w-7 h-7 block mx-auto mt-5">
                          <TwitterIcon size={35} borderRadius={5} />
                    </TwitterShareButton>
                  </React.Fragment>
              }
            </p>
          </div>
        </div>
      </div>,
      document.getElementById(props.targetID)
  )
}
export default ThanksModal