import React from 'react';

import { Link } from 'react-router-dom';
//Atoms
import Img from '../Atoms/img';

function AnalyticsContent(props){
	return(
			<React.Fragment >
				<div className={"mx-auto mb-5 relative " + props.width}>
          <div className="w-full h-full bg-white rounded-md shadow-md border">
              <div className="flex py-5 px-5 w-full">
                <div className="my-auto w-28">
                  <div>2022年</div>
                  <div>3月20日</div>
                </div>
                <Link to={"/reserve/" + props.channel.id} className="w-32 mr-7 hover:opacity-75 cursor-pointer">
                {											
                  props.channel.thumbnail != null &&
                  <Img size="reserve_thumbnail" src={props.channel.thumbnail} alt="thumbnail" />
                }
                {											
                  props.channel.thumbnail == null &&
                  <img src="/images/home-logo.png" 
                    className="h-24 w-32 rounded-md object-cover" 
                    alt="thumbnail"
                  />
                }
                </Link>
                <div className="my-auto w-96">
                  <Link to=""  
                  className="block hover:opacity-75 text-base font-semibold max-h-12 overflow-ellipsis overflow-hidden">
                    {props.channel.title}ここにタイトルここにタイトルここにタイトルここにタイトルここにタイトルここにタイトル
                  </Link>
                </div>
                <div className="my-auto w-44 text-center">
                  0
                </div>
                <div className="my-auto w-44 text-center">
                  0
                </div>
                <div className="my-auto w-44 text-center">
                  0
                </div>
                <div className="my-auto w-44 text-center">
                  0
                </div>
              </div>
          </div>
        </div>
			</React.Fragment>
	)
}
export default AnalyticsContent 