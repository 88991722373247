// Reducer群を管理する
import { combineReducers } from 'redux'
import auth from './auth.js'
import loginNow from './loginNow.js'
import booleanList from './boolean.js'
import streaming from './streaming.js'
import smMenu from './sm-menu.js'
import change_user from './change-user.js'
import progress_state from './progress.js'
import countNotices from './notifications.js'
import modal_state from './modal_state.js'
import now_schedule from './schedule.js'
import paid_state from './paid.js'

export default combineReducers(
	{ auth, loginNow, booleanList, streaming ,smMenu, change_user, progress_state,countNotices,modal_state,now_schedule, paid_state}
)

