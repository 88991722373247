import React, {useState, useEffect } from 'react';
import Amplify, {API, graphqlOperation, loadingBarFill} from "aws-amplify";
import InfiniteScroll from 'react-infinite-scroller';
import Content from '../Organisms/content.js';
import NoResults from '../Molecules/no-results.js';
// Atoms
import Spinner from '../Atoms/spinner';
// DB関連
import * as gqlQueries from '../../graphql/queries'

// DynamoDBから降順でuser_id,viewerCountを取得する
function New(props){
	const [ token, setNextToken ] = useState('');
	// ライブ配信中のチャンネルの管理
	const [stream, setStream] = useState([]);
	const [bool, setHasMore] = useState(true);  
	const [noContent, setContent] = useState(true);  

	const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
	useEffect(()=>{
		// ライブ配信中の最初の15件を取得する。
		(async function dblistStream(){
	          // 新着順の15件を早期フェッチする。
            const  { data: { byCreatedAt: { items: itemsPage1, nextToken } } } = await API.graphql({query: gqlQueries.byCreatedAt,variables: { is_live: 1, sortDirection: "DESC", limit:15}});
						if(itemsPage1.length == 0){
							setContent(false);
						}else{
							setNextToken(nextToken);
							setStream([...itemsPage1]);
							// 最初の読み込みの段階で残りのコンテンツが5件以上なければ終了
							if(nextToken == null){
									setHasMore(false);
							}
						}
		})();
	},[]
	)
	// 次のページを読み込む
  async function loadmore(){
			await _sleep(500);
			if(bool == true){
				//次のコンテンツを読み込む
        const  { data: { byCreatedAt: { items: itemsPage2, nextToken } } } = await API.graphql({query: gqlQueries.byCreatedAt,variables: { is_live: 1, sortDirection: "DESC", limit:5, nextToken: token}});
				setStream([...stream,...itemsPage2]);
				setNextToken(nextToken);
				// 読み込みを終了 
				if(nextToken == null){
						setHasMore(false);
				}
			}
	}
	// 追加コンテンツ
const items = (
	<div className="flex mx-auto flex-wrap">
		{						
			stream.map((channel,index)=>
				<Content channel={channel} thumbnail={channel[1]} key={index}/>
			)
		}
	</div>
	);
	// 追加したコンテンツを読み込み中ローディング
	const loading = (
		<div key="1" className="my-5">
			<Spinner/>
		</div>	
	);
	return(
			<React.Fragment>
				<div className="w-full">
					<h1 className="font-bold text-xl pl-2 ml-4 my-4">新着ライブ</h1>
					{
						noContent ? 
							<div className="flex mx-auto flex-wrap justify-center">
							{/* トレンド */}
							<InfiniteScroll
									loadMore={loadmore}
									hasMore={bool}
									initialLoad={false}
									style={{width: '100%'}}
									loader={loading}
									>
									{items}
							</InfiniteScroll>
							</div>
						:
							<NoResults children="配信中のライブがありません...."/>
					}
				</div>
			</React.Fragment>
	)
}
export default New;
