import React, {Suspense} from 'react';
import ReserveTemp from '../Templates/reserveTemp.js';
import { useParams } from "react-router";
// amplify
import Amplify, { API, graphqlOperation} from "aws-amplify";
import UseFetchResource from '../../containers/fetchResource.js';
// DB関連
import * as gqlQueries from '../../graphql/queries'
import { login_now } from '../../actions/index.js';

function Reserve(props){
  // パラメータから現在アクセスしているチャンネルを取得
  let { streaming_id } = useParams();
	const [wrapPromise] = UseFetchResource();
	// ストリーミング情報を早期フェッチする。
  const streamingInfo = API.graphql(graphqlOperation(gqlQueries.getStreaming, { id: streaming_id }));
  const streamingResource = wrapPromise(streamingInfo)
	return(
		<React.Fragment>
      <Suspense fallback={<p></p>}>
        <ReserveTemp resource={streamingResource}/>
      </Suspense>
		</React.Fragment>
	)
}
export default Reserve
