import React, { useState } from 'react';
import { Link, useHistory} from 'react-router-dom';

import Amplify, { Auth, API, graphqlOperation} from "aws-amplify";
import '../style/auth.css';
// Molecules
import Logo from './Molecules/logo.js';

// redux
import {useSelector } from 'react-redux';

import * as gqlMutations from '../graphql/mutations'

function Tech (){
	// ログイン中のユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
	const [langs, setLangs] = useState({
		JavaScript: false,
		Python: false,
		Ruby: false,
		SQL: false,
		HTML: false,
		Vuejs : false,
		Rails: false,
		Go: false,
		AWS: false,
		TypeScript: false,
		PHP: false,
		Laravel: false,
		Docker: false,
		Java: false,
		Swift: false,
	})

	const history = useHistory();
	const handleChange =(e)=>{
			var val = e.currentTarget.getAttribute('data-num')
			setLangs(state => ({...state, [val]: !langs[val]}));
		}

	// ユーザー情報に気になる言語を登録
  const updateUser = async()=>{
		const keys = Object.keys(langs);
		const input ={
			JavaScript: null,
			Python: null,
			Ruby: null,
			SQL: null,
			HTML: null,
			Nextjs: null,
			Vuejs : null,
			Rails: null,
			Go:null,
			AWS: null,
			TypeScript: null,
			PHP: null,
			Laravel: null,
			Docker: null,
			Rust: null,
			COBOL: null,
			R: null,
			Java: null,
			Swift: null,
			Git: null,
			React: null,
		} 
		keys.map(val=>{
			if(langs[val] == false){
				input[val] = null
			}else if(val == 'Vuejs'){
				input[val] = 'Vue.js' 
			}else{
				input[val] = val
			}
		});
		// お気に入りの技術tec情報を作成
		const tec_data = await API.graphql(
		graphqlOperation(gqlMutations.createTec, {
			input: input
		})
		)
		// ユーザー情報をアップデート
		API.graphql(
			graphqlOperation(gqlMutations.updateUser, {
					input: {
					id: userInfo.id,
					tec_id: tec_data.data.createTec.id
				}
		}));
		// ホームへリダイレクト
		history.push('/');
	}
	return(
			<div>
				<div className="md:justify-between justify-center flex mt-6 md:ml-5 ml-0">
					<Link to="/">
						<Logo dark={false} size="md"/>
					</Link>
				</div>
					<div className="activation-form md:mt-24 mt-8 md:w-8/12 md:mx-auto w-full md:border-solid md:border md:border-gray-200  bg-white md:shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
						<div className="mb-4 mt-4">
							<p className="font-bold text-gray-700 text-center">興味のある分野を選びましょう。</p>
							<ul className="flex w-full mt-5 flex-wrap">
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center cursor-pointer mb-5" + (langs['JavaScript'] ? " " : " gray_out") } onClick={handleChange} data-num='JavaScript'>
									<div className="w-1/5 h-10 my-auto">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/javascript-1.svg`} className="w-full h-full" alt="JavaScript"/>
									</div>
									<span className="my-auto ml-2 mr-4 font-bold text-gray-700">JavaScript</span>
								</li>
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center cursor-pointer mb-5" + (langs['Python'] ? " " : " gray_out") } onClick={handleChange} data-num="Python">
									<div className="w-1/5 h-16 my-auto">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/python-powered-h.svg`} className="h-full" alt="Python"/>
									</div>
									<span className="my-auto mx-2 font-bold text-gray-700">Python</span>
								</li>
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center cursor-pointer mb-5"  + (langs['Ruby'] ? " " : " gray_out") } onClick={handleChange} data-num="Ruby">
									<div className="w-1/5 h-8 my-auto mx-0">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/ruby.png`} className="h-full m-auto" alt="Ruby"/>
									</div>
									<span className="my-auto ml-2 mr-12 font-bold text-gray-700">Ruby</span>
								</li>
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center cursor-pointer mb-5" + (langs['SQL'] ? " " : " gray_out") } onClick={handleChange} data-num="SQL">
									<div className="w-1/4 h-8 my-auto mx-0">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/powered-by-mysql-88x31.png`} className="h-full w-full" alt="SQL"/>
									</div>
									<span className="my-auto ml-2 mr-6 font-bold text-gray-700">MySQL</span>
								</li>
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center cursor-pointer mb-5" + (langs['HTML'] ? " " : " gray_out")} onClick={handleChange} data-num="HTML">
									<div className="w-1/5 h-10 my-auto">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/HTML5_Badge_32.png`} className="h-full" alt="HTML"/>
									</div>
									<span className="my-auto ml-2 md:mr-0 mr-8 font-bold text-gray-700">HTML5</span>
								</li>
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center cursor-pointer mb-5" + (langs['Vuejs'] ? " " : " gray_out")} onClick={handleChange} data-num="Vuejs">
									<div className="w-1/5 h-12 my-auto mx-0">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/vue-js-1.svg`} className="h-full" alt="Vuejs"/>
									</div>
									<span className="my-auto mx-2 font-bold text-gray-700">Vue.js</span>
								</li>
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center cursor-pointer mb-5" + (langs['Rails'] ? " " : " gray_out")} onClick={handleChange} data-num="Rails">
									<div className="w-1/5 h-10 my-auto">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/rails-1.svg`} className="h-full" alt="Rails"/>
									</div>
									<span className="my-auto ml-2 mr-12 font-bold text-gray-700">Rails</span>
								</li>
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center cursor-pointer mb-5" + (langs['Go'] ? " " : " gray_out")} onClick={handleChange} data-num="Go">
									<div className="w-1/5 h-12 my-auto mx-0">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/Go-Logo_LightBlue.png`} className="h-full" alt="Go"/>
									</div>
									<span className="my-auto ml-2 mr-12 font-bold text-gray-700">Go</span>
								</li>
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center cursor-pointer mb-5" + (langs['AWS'] ? " " : " gray_out")} onClick={handleChange} data-num="AWS">
									<div className="w-1/5 h-12 my-auto mx-0">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/aws-2.svg`} className="h-full" alt="AWS"/>
									</div>
									<span className="my-auto ml-2 md:mr-4 mr-10 font-bold text-gray-700">AWS</span>
								</li>
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center  cursor-pointer mb-5" + (langs['TypeScript'] ? " " : " gray_out")} onClick={handleChange} data-num="TypeScript">
									<div className="w-1/5 h-10 md:ml-2 ml-8 my-auto">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/typescript.svg`} className="h-full" alt="TypeScript"/>
									</div>
									<span className="my-auto mx-2 font-bold text-gray-700">TypeScript</span>
								</li>
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center  cursor-pointer mb-5" + (langs['PHP'] ? " " : " gray_out")} onClick={handleChange} data-num="PHP">
									<div className="w-1/5 h-8 my-auto mx-0">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/new-php-logo.png`} className="h-full" alt="PHP"/>
									</div>
									<span className="my-auto ml-2 mr-12 md:mr-4 font-bold text-gray-700">PHP</span>
								</li>
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center cursor-pointer mb-5" + (langs['Laravel'] ? " " : " gray_out")} onClick={handleChange} data-num="Laravel">
									<div className="w-1/5 h-10 ml-2 my-auto">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/laravel-1.svg`} className="h-full" alt="laravel"/>
									</div>
									<span className="my-auto mx-2 font-bold text-gray-700">Laravel</span>
								</li>
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center cursor-pointer mb-5" + (langs['Docker'] ? " " : " gray_out")} onClick={handleChange} data-num="Docker">
									<div className="w-1/5 h-12 my-auto">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/vertical-logo-monochromatic.png`} className="h-full" alt="Docker"/>
									</div>
									<span className="my-auto ml-2 mr-10 font-bold text-gray-700">Docker</span>
								</li>
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center  cursor-pointer mb-5" + (langs['Java'] ? " " : " gray_out")} onClick={handleChange} data-num="Java">
									<div className="w-1/5 h-12 my-auto mx-0">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/java.svg`} className="h-full" alt="Java"/>
									</div>
									<span className="my-auto mx-2 font-bold text-gray-700">Java</span>
								</li>
								<li className={"tech-list flex md:w-1/3 w-1/2 h-20 justify-center   cursor-pointer mb-5" + (langs['Swift'] ? " " : " gray_out")} onClick={handleChange} data-num="Swift">
									<div className="w-1/5 h-10 ml-2 my-auto">
										<img src={`${process.env.PUBLIC_URL}/images/techlogo/Swift_logo_color.svg`} className="h-full" alt="Swift"/>
									</div>
									<span className="my-auto ml-2 mr-12 md:mr-2 font-bold text-gray-700">Swift</span>
								</li>
							</ul>
						<div className="flex m-auto fit-content mt-8">
								<button id="activation-button" className="block main-background hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" onClick={updateUser} type="button">
									始める
								</button>
						</div>
						<div className="text-center mt-4">
							<Link to="/" className="text-gray-600 hover:text-gray-400 font-bold text-sm cursor-pointer">
								スキップ
							</Link>
						</div>
							</div>
					</div>
			</div>

	)
}

export default Tech;
