import {
	SCHEDULE,
} from '../actions/index.js'

let today = new Date();
let yyyy = today.getFullYear();
let mm = today.getMonth()+1;
let dd = today.getDate();
let hours = today.getHours();
let minutes = today.getMinutes();
// yyyy-MM-ddTHH:mm:ss の形に変換する
let date_str = today.toISOString();

let times = '';

if(minutes >= 30){        
  let nexthour = hours + 1
  if(nexthour == 24){
    times = '0:00';
  }else{
    times = (hours + 1) + ':00' 
  }
}else{
  times = hours + ':30' 
}
const initialState = {
  date:{
    year: yyyy,
    month: mm,
    day: dd,
    hours: times.split(':')[0],
    minutes: times.split(':')[1],
    times: times,
    today_str: date_str
  }
};

function now_schedule (state = initialState, action){
	switch (action.type){
		case SCHEDULE:
			return{
          date:{
            year: action.props.year,
            month: action.props.month,
            day: action.props.day,
            hours: action.props.hours,
            minutes: action.props.minutes,
            times: action.props.times,
            today_str: action.props.today_str
          }
      }
		// まずはここでstateをセットする
		default:
			return state
	}
}

export default now_schedule;