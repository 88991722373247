import React, { useState } from 'react';
// amplify
import Amplify, { Auth, API, graphqlOperation } from "aws-amplify";
// redux
import { useDispatch } from 'react-redux';
import { user_info,login_now } from '../actions';

// DB関連
import * as gqlQueries from '../graphql/queries'
import * as gqlMutations from '../graphql/mutations'

// サインアウト処理
const UseCurrentUser = (initialValue)=>{
	const [bool, setBool] = useState(initialValue);
	// store
	const dispatch = useDispatch();
	// graphqlからユーザー情報を取得する
	async function dbgetUser(userId){
		const userInfo = await API.graphql(graphqlOperation(gqlQueries.getUser, { id: userId }));
		return userInfo.data.getUser;
	}

	async function updateUser(input){
		await API.graphql(
			graphqlOperation(gqlMutations.updateUser, {
				input: input
		}))
	}
	// 現在ログイン中か否か確認
	const currentUser = ()=>Auth.currentUserInfo().then((user)=>{
		if(user != null){
			dbgetUser(user.username).then(async function(value){
				// ログインはしているがまだDBに値がない状態
				if(value){
					let params ={
						id: value.id,
						name: value.name,
						icon: value.icon,
						profile: value.profile,
						user_id: value.user_id,
						logininfo: value.logininfo,
						buy_point: value.buy_point,
						receive_point: value.receive_point,
						custom_account: value.custom_account,
						customer: value.customer,
						twitter: value.twitter,
						github: value.github,
						homepage: value.homepage
					}
					// Stripe アカウントが無い場合作成
					if(!value.custom_account){
						const result = await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { event: 'accountCreate' }));
						let input = {
								id: value.id,
								custom_account: result.data.stripeFunction.account
						}
						// ユーザーデータベースを更新
						updateUser(input);
						params['custom_account'] = result.data.stripeFunction.account;
					}
					dispatch(user_info(params));
					dispatch(login_now(true));
					}
					setBool(true);
			});
		}else{
				const params ={
					id: '',
					name: '',
					icon: '',
					profile: '',
					user_id: '',
					logininfo: '',
					buy_point: '',
					receive_point: '',
					custom_account: '',
					customer: '',
					twitter: false,
					github: false,
					homepage: false 
				}
				dispatch(user_info(params));
				// ログイン中に変更
				dispatch(login_now(false));
        setBool(true);
    }
	});
  return [bool, currentUser];
}
export default UseCurrentUser
