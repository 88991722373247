import { Link } from 'react-router-dom';
// Molecules
import Logo from '../Molecules/logo.js';
function Footer(){
  return(
      <footer className="bg-gray-800 pt-10 pt-10">
          <div className="max-w-6xl m-auto text-gray-800 flex flex-wrap justify-center">
              <div className="hidden md:block my-auto px-5 md:w-2/5">
                {/*StackLiveロゴ*/}
                <Link to="/" className="fit-content mx-auto block">
                  <Logo dark={true} size="footer"/>
                  <p className="text-gray-300 text-sm mt-1">エンジニア向けライブ配信サービス</p>
                </Link>
              </div>
              <div className="md:w-3/5 w-11/12 flex">
                <div className="p-5 w-2/6 md:w-1/3">
                    <div className="text-xs uppercase text-gray-400 font-medium mb-6">
                      menu
                    </div>
                    <ul>
                      <li className="mb-2">
                        <Link to="/" className="inline-block text-gray-300 hover:opacity-75 text-sm font-medium duration-700">
                          Home
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/trend" className="inline-block text-gray-300 hover:opacity-75 text-sm font-medium duration-700">
                          トレンド
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/ranking" className="inline-block text-gray-300 hover:opacity-75 text-sm font-medium duration-700">
                          ランキング
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/login" className="inline-block text-gray-300 hover:opacity-75 text-sm font-medium duration-700">
                          ログイン
                        </Link>
                      </li>
                    </ul>
                </div>

                <div className="p-5 w-3/6 md:w-1/3">
                    <div className="text-xs uppercase text-gray-400 font-medium mb-6">
                        about
                    </div>
                    <ul>
                      <li className="mb-2">
                        <Link to="/lp/for_company" className="inline-block text-gray-300 hover:opacity-75 text-sm font-medium duration-700">
                          企業様向けはこちら
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/about" className="inline-block text-gray-300 hover:opacity-75 text-sm font-medium duration-700">
                          個人様向けはこちら
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/privacy" className="inline-block text-gray-300 hover:opacity-75 text-sm font-medium duration-700">
                          プライバシーポリシー
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/terms" className="inline-block text-gray-300 hover:opacity-75 text-sm font-medium duration-700">
                          利用規約
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/company" className="inline-block text-gray-300 hover:opacity-75 text-sm font-medium duration-700">
                          運営者情報
                        </Link>
                      </li>
                      <li className="mb-2">
                        <a href="https://www.notion.so/StackLive-726d6cd633854b1b8174961e6285f024" className="inline-block text-gray-300 hover:opacity-75 text-sm font-medium duration-700">
                          よくある質問
                        </a>
                      </li>
                      <li className="mb-2">
                          <a 
                            href="https://docs.google.com/forms/d/e/1FAIpQLSeH4ybcAQXIHTO7cerPSDYAFxEqbUCDgSkHCVMY6a9V46Mpjg/viewform?usp=sf_link" 
                            className="inline-block text-gray-300 hover:opacity-75 text-sm font-medium duration-700"
                            target="_blank"
                          >
                            お問い合わせ
                          </a>
                      </li>
                    </ul>
                </div>

                <div className="p-5 w-1/6 md:w-1/3">
                    <div className="text-xs uppercase text-gray-400 font-medium mb-6">
                        SNS
                    </div>
                    <div className="h-full">
                      <a href="https://twitter.com/StackLive_jp" target="_blank" rel="noopener noreferrer" className="h-6 w-6 my-auto inline-block text-gray-300 hover:opacity-50 text-sm font-medium duration-700">                            
                        <img src={`${process.env.PUBLIC_URL}/images/twitter.svg`} className="h-full" alt="twitter"/>
                      </a>
                    </div>
                </div>
                </div>
              <div className="block md:hidden md:w-2/5 my-10 px-5 ">
                {/*StackLiveロゴ*/}
                <Link to="/" className="fit-content mx-auto block">
                  <Logo dark={true} size="footer"/>
                  <p className="text-gray-300 text-sm mt-1">エンジニア向けライブ配信サービス</p>
                </Link>
              </div>
          </div>

          <div className="pt-2">
              <div className="flex pb-5 px-3 m-auto pt-5 
                  border-t border-gray-500 text-gray-400 text-sm 
                  flex-col md:flex-row max-w-6xl justify-center text-center">
                    2021〜
              </div>
          </div>
      </footer>
  )
}
export default Footer