// style
export const imgSize = {
	xs: 'h-4 w-auto',
	md: 'h-8 w-auto',
	lg: 'h-32 w-32',
	mypage: 'md:h-36 md:w-36 h-16 w-16',
	full: 'w-full h-full',
	ranking: 'h-16 w-16 rounded-full mr-1',
	thumbnail: 'w-full md:h-48 rounded-lg rounded-b-none',
	pickup_thumbnail: 'w-full xl:h-64 md:h-44 h-48 rounded-lg rounded-b-none rounded-lg shadow-lg antialiased cursor-pointer',
	content:'rounded-full mr-1 h-5 w-auto',
	reserve_icon: 'md:w-8 md:h-8 w-6 h-6 rounded-full mr-1',
	reserve_home_icon: 'md:w-6 md:h-6 w-6 h-6 rounded-full mr-1',
	reserve_thumbnail: 'h-24 w-32 rounded-md object-cover',
	reserve_content: 'md:h-64 h-56 rounded-md object-cover md:mr-10 mx-auto md:mx-0',
	notice_icon: 'h-8 w-auto mr-3',
}
export const imgType = {
	md: 'bg-blue-600 rounded-full hover:opacity-75 cursor-pointer',
	icon: "rounded-full flex items-center justify-center cursor-pointer hover:opacity-75 mx-auto",
	mypage: 'mt-2 rounded-full',
	normal: 'bg-blue-600 rounded-full',
	contenticon: 'bg-blue-600 rounded-full mr-1',
	thumbnail: 'rounded-lg shadow-lg antialiased cursor-pointer hover:opacity-50',
	chat:'flex items-center justify-center h-8 w-8 rounded-full bg-indigo-500 flex-shrink-0'
}
