import Amplify, { Storage } from "aws-amplify";
// s3にファイルをアップロードする
const Uses3Upload =(uuid, file ,filetype)=>{
    const upload = async (uuid, file, filetype)=>{
      let key = await Storage.put(uuid, file, {contentType: filetype})
      return key
    }
    return [upload]
}

export default Uses3Upload