import Auth from "@aws-amplify/auth"
// redux
import {useSelector, useDispatch } from 'react-redux';
import { login_now, change_user } from '../actions';

// サインアウト処理
const UseSignOut = ()=>{
	// store
	const dispatch = useDispatch();
	const signOut = ()=> Auth.signOut().then((data)=>{
		// state変更
		dispatch(login_now(false));
		// ユーザー状態が変更した事を発火
		dispatch(change_user());
	})
  return [signOut];
}
export default UseSignOut

