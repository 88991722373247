import React, {useState, useEffect} from 'react';

import { Link } from 'react-router-dom';
import API, { graphqlOperation } from '@aws-amplify/api';
//Atoms
import Img from '../Atoms/img';
// DB関連
import * as gqlQueries from '../../graphql/queries'

function ReserveContent(props){
	const [ reserve_count, setCount] = useState(0);
	const [ width, setWidth] = useState(0);
	const tec_data =  props.channel.tec;
	delete tec_data['id'];
	delete tec_data['createdAt'];
	delete tec_data['updatedAt'];
  // 連想配列名を取得する
	let arrays = Object.keys(tec_data); 
	let new_arrays = ['StackLive'];

  // 配列のnull以外の値で新しい配列を作成する
  arrays.map(object => {
    if(tec_data[object] != null){
			new_arrays.unshift(tec_data[object]);
    }
	})
  let progress_width= {width: width + '%'};

  // 視聴予約をしているか確認
	useEffect(()=>{
    (async function (){
      try{
          const reserveList = await API.graphql(graphqlOperation(gqlQueries.listReserves,{filter:{streaming_id: {eq: props.channel.id}}}));
          // 応募数を取得しstateに入れる
          setCount(reserveList.data.listReserves.items.length);
          let reserve_progress = reserveList.data.listReserves.items.length / props.channel.reserve_peoples * 100;
          if(reserve_progress > 100){
            reserve_progress = 100;
          }
          setWidth(Math.round(reserve_progress));
      }catch(e){
        console.log(e);
      }
    }());
	},[]);
  

	return(
			<React.Fragment >
				<div className={"w-full px-2 mb-5 relative " + props.width}>
          <div className="absolute top-1 right-4">
            {
              props.channel.is_paid == true &&  
                <span className="bg-yellow-500 rounded text-white font-semibold px-2 py-0.5 mr-2 text-sm">
                  ¥{props.channel.price}
                </span>
            }
            <span className="bg-gray-600 rounded text-white font-semibold px-2 py-0.5 text-sm">
              {props.time}
            </span>
          </div>
          <div className="w-full h-full bg-white px-3 rounded-md shadow-md border">
            <div className="flex pt-6 mb-2">
              <Link to={"/reserve/" + props.channel.id} className="hover:opacity-75 cursor-pointer">
              {											
                props.channel.thumbnail != null &&
                <Img size="reserve_thumbnail" src={props.channel.thumbnail} alt="thumbnail" />
              }
              {											
                props.channel.thumbnail == null &&
                <img src="/images/home-logo.png" 
                  className="h-24 w-32 rounded-md object-cover" 
                  alt="thumbnail"
                />
              }
              </Link>
              <div className="flex flex-col pl-3 w-3/4">
                <div className="my-auto">
                  <Link to={"/reserve/" + props.channel.id} className="block hover:underline hover:opacity-75 text-base font-semibold max-h-12 overflow-ellipsis overflow-hidden" >
                    {props.channel.title}
                  </Link>
                  <p className="text-gray-800 mt-1 text-sm overflow-hidden-2line-leader">{props.channel.summary}</p>
                </div>
              </div>
            </div>
            {/* ハッシュタグ */}
            <div className="flex mt-1 overflow-scroll hash_tag">
              {
              new_arrays.map((tech,index)=>
                <div className="justify-center items-center mx-1 font-medium py-1 px-2 bg-white rounded-full text-blue-700 bg-blue-100 border border-blue-300" key={index}>
                  <div className="text-xs font-normal leading-none max-w-full flex-initial">#{tech}</div>
                </div>
              )
              }
            </div>
            <div className="flex my-2 ">
              <Link to={"/user/" + (props.channel.user.user_id)} className="flex w-1/2 hover:opacity-75">
                {props.channel.user.icon ?
                  <Img size="reserve_home_icon" src={props.channel.user.icon} />
                  :
                  <img src="/images/smiley-sticker.svg" className="w-6 h-6 rounded-full mr-1"/>
                }
              <p className="text-sm my-auto overflow-ellipsis overflow-hidden whitespace-nowrap">
                {props.channel.user.name} 
              </p>
              </Link>
              <div className="flex text-sm text-gray-500">
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
                <p>視聴予約数：{reserve_count}人</p>
              </div>
            </div>
          </div>
        </div>
			</React.Fragment>
	)
}
export default ReserveContent 