import Amplify, { Auth, API, graphqlOperation} from "aws-amplify";
import { useHistory} from 'react-router-dom';
// redux
import { useDispatch } from 'react-redux';
import { login_now, user_info} from '../actions';
// DB関連
import * as gqlQueries from '../graphql/queries'

// ログイン処理
const UseSignIn = ()=>{
	// store
	const dispatch = useDispatch();
  // ログイン処理
	const signIn = async(email, pass)=>{
    const cognitoUser = await Auth.signIn(email, pass);
    dispatch(login_now(true));
    return(cognitoUser);
  }
  // ユーザー情報をアップデート
  const updateUser = async(userId)=>{
    const userInfo = await API.graphql(graphqlOperation(gqlQueries.getUser, { id: userId }));
      const params ={
        id: userInfo.data.getUser.id,
        name: userInfo.data.getUser.name,
        icon: userInfo.data.getUser.icon,
        profile: userInfo.data.getUser.profile,
        user_id: userInfo.data.getUser.user_id,
        logininfo: userInfo.data.getUser.logininfo
      }
      dispatch(user_info(params));
      return userInfo.data.getUser;
} 
  return [signIn,updateUser];
}
export default UseSignIn