import React, {useState, useEffect } from 'react';
// import 'font-awesome/css/font-awesome.css';
// import 'bootstrap-social/bootstrap-social.css';

function GithubLogin(){

  useEffect(()=>{
    const oauthScript = document.createElement("script");
    oauthScript.src = "https://cdn.rawgit.com/oauth-io/oauth-js/c5af4519/dist/oauth.js";

    document.body.appendChild(oauthScript);
  },[]);

  const handleClick = (e)=>{
    // ページのリロードを防止する。
    e.preventDefault();

    //　　API keyを使ってOAuth.ioを初期化する。
    // OAuth.io登録後にメモした、あなたのAPIkeyを'YOUR_OAUTH_API_KEY'に記入します。
    window.OAuth.initialize('YOUR_OAUTH_API_KEY');

    // ポップアップウインドウを表示し、Githubの承認する。
    window.OAuth.popup('github').then((provider) => {

      // 'welcome'メッセージをユーザの名前と一緒に表示する。
      // 取得したユーザーのデータをコンソールで確認する。
      provider.me().then((data) => {
        console.log("data: ", data);
        alert("Welcome " + data.name + "!");
      });

      // また、.get()にてGithub's APIから取得も出来ます。
      provider.get('/user').then((data) => {
         console.log('self data:', data);
      });

    });
  }

    return(
      <div className="fit-content m-auto mt-4">
        <a href="" onClick={handleClick} className="my-2 px-5 py-2 rounded btn btn-social btn-github">
          <span className="fa fa-github"></span> Sign in with Github
        </a>
      </div>
    ) 
}

export default GithubLogin