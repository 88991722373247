import ReactDOM from 'react-dom';
// redux
import {useSelector, useDispatch } from 'react-redux';
import { boolean_sm_menu } from '../../actions';

function BlackModal(props){
	const dispatch = useDispatch();
	const sm_menu = useSelector(state => state.smMenu.sm_menu);
	const smMenuToggle = ()=>{
		dispatch(boolean_sm_menu());
	}
  return ReactDOM.createPortal(
      // モーダル出現時の黒い背景
      <div className={"md:hidden h-screen fixed w-full bg-black z-20 bg-opacity-70" + (sm_menu ? " sm:block" : " hidden")} onClick={smMenuToggle}>
      </div>,
      document.getElementById(props.targetID)
  )
}
export default BlackModal