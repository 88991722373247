import React, { useState } from 'react';
import { Link} from 'react-router-dom';
import Amplify, { Auth } from "aws-amplify";
// フォームを扱うライブラリ
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import config from "../aws-exports";
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
// Molecules
import Logo from './Molecules/logo.js';
import ErrorCard from './Molecules/error-card.js';
// Atoms
import Spinner from './Atoms/spinner';

function Confirm (props){
	Amplify.configure(config);
	const inputEmail = useSelector(state => state.auth.email);
	// ユーザー情報
	// react-hook-formで使用する
	const { register, handleSubmit, watch, errors } = useForm();
	const [ is_error, errorToggle ] = useState(false);
	const [ is_loading, setLoading ] = useState(false);
	const [error_message, setMessage] = useState('');
	// 成功したときにビューを切り替える
	const [ is_success, setSuccess ] = useState(false);


	// 認証
	const  onSubmit = async(data)=>{
		setLoading(true);
    nprogress.start();
		// メールアドレスを認証する
		await Auth.confirmSignUp(data.email,data.code)
		.then(() => {
			setLoading(false);
			nprogress.done();
			setSuccess(true);
		})
		.catch((err) => {
			switch(err.code){
				case 'UserNotFoundException':
					setMessage('メールアドレスが存在しません')
				case 'CodeMismatchException':
					setMessage('認証コードが正しくありません')
			}
			console.log(err);
			nprogress.done();
			setLoading(false);
			errorToggle(true);
			return;
		});
	}
	// リアルタイムでフォームを監視
  watch('code')
  watch('email')
	return(
				<div className="min-h-screen pt-16 md:pt-20 bg-gray-100 flex flex-col">						
					<div className="md:px-10 xs:p-0 mx-auto md:w-full md:max-w-lg w-10/12">						
					<h1 className="font-bold text-center text-2xl mb-5">
						{/*StackLiveロゴ*/}
						<Link to="/" className="inline-block">
							<Logo dark={props.dark} size="lx"/>
						</Link>
					</h1>  						
					{/* エラーメッセージ文言 */}
					<ErrorCard toggle={is_error} children={error_message} func={()=> errorToggle(false)}/>
					{
						is_success ? 
					<div>
						<div className="bg-green-100 rounded-md p-3 flex">
								<svg
									className="stroke-2 stroke-current text-green-600 h-6 w-6 mr-2 flex-shrink-0"
									viewBox="0 0 24 24"
									fill="none"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<path d="M0 0h24v24H0z" stroke="none" />
										<circle cx="12" cy="12" r="9" />
									<path d="M9 12l2 2 4-4" />
								</svg>
								<div className="text-green-700">
									<div className="font-bold text-base">認証されました</div>
								</div>
							</div>
					<div className="bg-white w-full rounded-lg p-8 text-center mt-5">						
							<div>
								<Link to="/login" className="">
									<p className="text-blue-500">ログイン画面へ</p>
								</Link>								
							</div>
					</div>						
					</div>						

						:
					<div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200">						
						<form onSubmit={handleSubmit(onSubmit)} className="py-12 w-10/12 m-auto">
							<label className="font-semibold text-gray-600 pb-1 block md:text-base text-sm" htmlFor="mailaddress">メールアドレス</label>						
							<input 
								name="email" 
								type="text" 
								className={"focus:outline-none border appearance-none rounded-lg px-3 py-2 mt-1 w-full md:text-sm text-base" + (errors.email ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
								defaultValue={inputEmail}
								ref={register({ 
									required: {
										value: true,
										message: "メールアドレスを入力してください"
									},
									pattern: {
									value: /^[A-Za-z0-9]{1}[A-Za-z0-9\._+-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
										message: 'メールアドレスの形式が正しくありません',
									},
								})} 
								placeholder="info@stacklive.jp"
								/>						
							{errors.email && <span className="text-red-600 font-semibold text-xs">{errors.email.message}</span>}
							<label className="font-semibold text-gray-600 pb-1 block md:text-base text-sm mt-6" htmlFor="code">認証コード</label>						
							<input 
								name="code" 
								className={"focus:outline-none border appearance-none rounded-lg px-3 py-2 mt-1 w-full md:text-sm text-base" + (errors.code ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
								ref={register({ required: true })} 
								type="code" 
								placeholder="123456"
							/>						
							{errors.code && <span className="text-red-600 font-semibold text-xs">認証コードを入力してください</span>}
							<button 
								type="submit"
								className="cursor-pointer mt-6 main-background hover:bg-blue-400 transform_scale text-lg text-white w-full py-2.5 focus:outline-none rounded-lg text-sm shadow-sm font-semibold text-center inline-block"									
							>
							{is_loading ? 
							<Spinner/> 
							: 
							"認証"}
							</button>
						</form>
					</div>						
					}
					</div>
				</div>
	)
}

export default Confirm;
