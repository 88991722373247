/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateStreaming = /* GraphQL */ `
  subscription OnCreateStreaming {
    onCreateStreaming {
      id
      title
      summary
      is_live
      thumbnail
      user_id
      tec_id
      viewerCount
      max_viewerCount
      archive
      archive_file
      archiveCount
      reserve
      reserveDate
      announce_url
      reserve_peoples
      is_paid
      price
      createdAt
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      tec {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onUpdateStreaming = /* GraphQL */ `
  subscription OnUpdateStreaming {
    onUpdateStreaming {
      id
      title
      summary
      is_live
      thumbnail
      user_id
      tec_id
      viewerCount
      max_viewerCount
      archive
      archive_file
      archiveCount
      reserve
      reserveDate
      announce_url
      reserve_peoples
      is_paid
      price
      createdAt
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      tec {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onDeleteStreaming = /* GraphQL */ `
  subscription OnDeleteStreaming {
    onDeleteStreaming {
      id
      title
      summary
      is_live
      thumbnail
      user_id
      tec_id
      viewerCount
      max_viewerCount
      archive
      archive_file
      archiveCount
      reserve
      reserveDate
      announce_url
      reserve_peoples
      is_paid
      price
      createdAt
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      tec {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      name
      user_id
      tec_id
      logininfo
      icon
      profile
      all_viewerCount
      createdAt
      updatedAt
      tec {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      buy_point
      receive_point
      custom_account
      customer
      twitter
      github
      homepage
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      name
      user_id
      tec_id
      logininfo
      icon
      profile
      all_viewerCount
      createdAt
      updatedAt
      tec {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      buy_point
      receive_point
      custom_account
      customer
      twitter
      github
      homepage
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      name
      user_id
      tec_id
      logininfo
      icon
      profile
      all_viewerCount
      createdAt
      updatedAt
      tec {
        id
        JavaScript
        Python
        Ruby
        SQL
        HTML
        Nextjs
        Vuejs
        Rails
        Go
        AWS
        TypeScript
        PHP
        Laravel
        Docker
        Rust
        COBOL
        R
        Java
        Swift
        Git
        React
        createdAt
        updatedAt
      }
      buy_point
      receive_point
      custom_account
      customer
      twitter
      github
      homepage
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost {
    onCreatePost {
      id
      uid
      channel
      comment
      icon
      gift
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost {
    onUpdatePost {
      id
      uid
      channel
      comment
      icon
      gift
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost {
    onDeletePost {
      id
      uid
      channel
      comment
      icon
      gift
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTec = /* GraphQL */ `
  subscription OnCreateTec {
    onCreateTec {
      id
      JavaScript
      Python
      Ruby
      SQL
      HTML
      Nextjs
      Vuejs
      Rails
      Go
      AWS
      TypeScript
      PHP
      Laravel
      Docker
      Rust
      COBOL
      R
      Java
      Swift
      Git
      React
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTec = /* GraphQL */ `
  subscription OnUpdateTec {
    onUpdateTec {
      id
      JavaScript
      Python
      Ruby
      SQL
      HTML
      Nextjs
      Vuejs
      Rails
      Go
      AWS
      TypeScript
      PHP
      Laravel
      Docker
      Rust
      COBOL
      R
      Java
      Swift
      Git
      React
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTec = /* GraphQL */ `
  subscription OnDeleteTec {
    onDeleteTec {
      id
      JavaScript
      Python
      Ruby
      SQL
      HTML
      Nextjs
      Vuejs
      Rails
      Go
      AWS
      TypeScript
      PHP
      Laravel
      Docker
      Rust
      COBOL
      R
      Java
      Swift
      Git
      React
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReserve = /* GraphQL */ `
  subscription OnCreateReserve {
    onCreateReserve {
      id
      streaming_id
      user_id
      flag
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateReserve = /* GraphQL */ `
  subscription OnUpdateReserve {
    onUpdateReserve {
      id
      streaming_id
      user_id
      flag
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteReserve = /* GraphQL */ `
  subscription OnDeleteReserve {
    onDeleteReserve {
      id
      streaming_id
      user_id
      flag
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotices = /* GraphQL */ `
  subscription OnCreateNotices {
    onCreateNotices {
      id
      receive_user
      send_user
      contents
      streaming_id
      flag
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      streaming {
        id
        title
        summary
        is_live
        thumbnail
        user_id
        tec_id
        viewerCount
        max_viewerCount
        archive
        archive_file
        archiveCount
        reserve
        reserveDate
        announce_url
        reserve_peoples
        is_paid
        price
        createdAt
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotices = /* GraphQL */ `
  subscription OnUpdateNotices {
    onUpdateNotices {
      id
      receive_user
      send_user
      contents
      streaming_id
      flag
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      streaming {
        id
        title
        summary
        is_live
        thumbnail
        user_id
        tec_id
        viewerCount
        max_viewerCount
        archive
        archive_file
        archiveCount
        reserve
        reserveDate
        announce_url
        reserve_peoples
        is_paid
        price
        createdAt
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotices = /* GraphQL */ `
  subscription OnDeleteNotices {
    onDeleteNotices {
      id
      receive_user
      send_user
      contents
      streaming_id
      flag
      user {
        id
        name
        user_id
        tec_id
        logininfo
        icon
        profile
        all_viewerCount
        createdAt
        updatedAt
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        buy_point
        receive_point
        custom_account
        customer
        twitter
        github
        homepage
      }
      streaming {
        id
        title
        summary
        is_live
        thumbnail
        user_id
        tec_id
        viewerCount
        max_viewerCount
        archive
        archive_file
        archiveCount
        reserve
        reserveDate
        announce_url
        reserve_peoples
        is_paid
        price
        createdAt
        user {
          id
          name
          user_id
          tec_id
          logininfo
          icon
          profile
          all_viewerCount
          createdAt
          updatedAt
          buy_point
          receive_point
          custom_account
          customer
          twitter
          github
          homepage
        }
        tec {
          id
          JavaScript
          Python
          Ruby
          SQL
          HTML
          Nextjs
          Vuejs
          Rails
          Go
          AWS
          TypeScript
          PHP
          Laravel
          Docker
          Rust
          COBOL
          R
          Java
          Swift
          Git
          React
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePaid = /* GraphQL */ `
  subscription OnCreatePaid {
    onCreatePaid {
      id
      streaming_id
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePaid = /* GraphQL */ `
  subscription OnUpdatePaid {
    onUpdatePaid {
      id
      streaming_id
      user_id
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePaid = /* GraphQL */ `
  subscription OnDeletePaid {
    onDeletePaid {
      id
      streaming_id
      user_id
      createdAt
      updatedAt
    }
  }
`;
