import List from '../Molecules/list';
import '../../style/menu.css';
import { Link } from 'react-router-dom';
// redux
import {useSelector, useDispatch } from 'react-redux';
// Atoms
import Img from '../Atoms/img';
import { boolean_sm_menu} from '../../actions';

function LeftMenu(props){
	const sm_menu = useSelector(state => state.smMenu.sm_menu);
	//ログイン中か確認
	const loginNow = useSelector(state => state.loginNow.bool);
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
	const dispatch = useDispatch();
	function smMenuToggle(){
		dispatch(boolean_sm_menu());
	}
	return(
		<div className={"md:hidden sm:block fixed top-0 z-30 border-light-blue-500 bg-gray-100 min-h-screen" + (sm_menu ?  " side_open": " side_close")} >
			{loginNow ?
				(
					// <HeaderList dark={props.dark}/>
					<Link to={"/user/" + userInfo.user_id} onClick={smMenuToggle}>
						<div className="flex mt-20 pb-5 px-4">
								{/*プロフィール画像*/}
								{userInfo.icon ?
									<Img size="md" type="md" src={userInfo.icon} />
										:
									<img src="/images/smiley-sticker.svg" className="h-12 w-12 w-auto rounded-full hover:opacity-75 cursor-pointer" />
								}
								{/* ユーザー名 */}
								<p className="my-auto mx-2 font-bold">{userInfo.name}</p>
						</div>
					</Link>
				)
					:
				(
					<div className="list-none flex justify-center mt-20 mb-5 whitespace-nowrap">
						<Link to="/login">
							<div className="border-2 border-blue-500 hover:opacity-75 text-blue-500 font-bold py-2 px-5 rounded mr-2 text-sm">ログイン</div>
						</Link>
						<Link to="/register">
							<div className="main-background border-2 main-border hover:opacity-75 text-white font-bold py-2 px-5 rounded text-sm">新規登録</div>
						</Link>
					</div>
				)
			}
			<List toggle={true}/>
		</div>
	)
}
export default LeftMenu