import React, { useState, useEffect } from "react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
// redux
import {useDispatch ,useSelector} from 'react-redux';
import { change_user, login_now } from '../../actions';
import UseToggle from '../../containers/toggle.js';

import PaymentIcon from '../Atoms/paymentIcon';

import { Link } from 'react-router-dom';
// DB関連
import * as gqlMutations from '../../graphql/mutations'
import * as gqlQueries from '../../graphql/queries'

function TransferHistory (props){
  const [historyList, setList] = useState([]);
  const [amounts, setAmount] = useState([]);
  const [created, setCreated] = useState([]);
  const [point, setPoint] = useState([]);
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
  useEffect(()=>{
    const f = async()=>{
	    // expressアカウントを取得する。
      const {data: {stripeFunction:{historyAmount, historyCreated}}} = await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { 
        event: 'transfer_history', 
        custom_account: userInfo.custom_account, 
      }));
      if(historyAmount.length){
        setList([...Array(historyAmount.length)].map((_, i) => i));
        setAmount(historyAmount);
        let array = [];
        // 購入履歴の日にちを変換
        historyCreated.map((date)=>{
          let d = new Date(Number(date) * 1000);
          let arg = d.toLocaleDateString();
          array.push(arg);
        })
        setCreated(array);
      }else{
        setList(false);
      }
    }
    f();
  },[]);
  return(
			<React.Fragment>
				<div className="md:w-3/6 w-full md:mx-auto md:mt-20 md:mt-10 mt-0 h-full md:border-solid md:border md:border-gray-200 md:shadow-md rounded">
					<div className="h-auto">
						<h2 className="text-center font-semibold text-2xl md:my-8 mt-8 mb-4">アカウント設定</h2>
						<div className="w-11/12 m-auto">
							<nav className="flex flex-row overflow-x-auto whitespace-nowrap hash_tag">
								<Link to={"/user/" + (userInfo.user_id) + "/edit"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-400 focus:outline-none">
									プロフィール
								</Link>
								<Link to={"/user/" + (userInfo.user_id) + "/transfer_history"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-400 focus:outline-none text-blue-600 border-b-2 font-medium border-blue-600">
									振込履歴
								</Link>
								<Link to={"/user/" + (userInfo.user_id) + "/card_info"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-400 focus:outline-none">
									カード情報
								</Link>
								<Link to={"/user/" + (userInfo.user_id) + "/bank_account"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-500 focus:outline-none">
									お振込先
								</Link>
							</nav>
						</div>
            <div className="justify-center items-center">
              {
                historyList ? 
                <ul className="flex flex-col p-4">
                  {
                    historyList.map((num) => 
                      <li className="border-gray-400 flex flex-row mb-2 md:w-4/6 w-11/12 mx-auto">
                        <div className="select-none bg-gray-200 rounded-md flex flex-1 items-center p-4">
                          <div className="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">
                            <PaymentIcon size="history"/>
                          </div>
                          <div className="flex-1">
                            <label className="text-xs">振込金額</label>
                            <div className="font-medium">{amounts[num]}円</div>
                          </div>
                          <div className="">
                            <label className="text-xs">申請日</label>
                            <div className="text-gray-600 text-sm">{created[num]}</div>
                          </div>
                        </div>
                      </li>
                    )
                  }
                  </ul>
                :
                <li className="my-8 border-gray-400 flex flex-row md:w-4/6 w-11/12 mx-auto">
                  <div className="select-none bg-gray-200 rounded-md flex flex-1 items-center p-4">
                    <div className="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">
                      <PaymentIcon size="history"/>
                    </div>
                    <div className="flex-1">
                      <label className="text-base font-semibold text-gray-700">振込履歴がありません</label>
                    </div>
                  </div>
                </li>
              }
            </div>
				</div>
				</div>
			</React.Fragment>
  )
}
export default TransferHistory