import React, { useState, useEffect } from 'react';
import MypageTemp from '../Templates/MypageTemp.js';

// 共通で表示する箇所
import Header from '../Organisms/Header.js';
import Footer from '../Organisms/footer.js';
import BlackModal from '../Molecules/black-modal';
import LeftMenu from '../Organisms/LeftMenu.js';
import SmLeftMenu from '../Organisms/sm-leftMenu.js';

function Mypage(){
	return(
		<React.Fragment>
			<BlackModal targetID='modal' />
			<Header/>
			<div className="flex min-h-screen pt-16">
				{/* PCレフトメニュー */}
				<LeftMenu/>
				{/* SMレフトメニュー */}
				<SmLeftMenu/>
				<MypageTemp />
			</div>
			<Footer/>
		</React.Fragment>
	)
}
export default Mypage
