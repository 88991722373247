import React, {useState, useEffect } from 'react';
import PaymentModal from '../Molecules/paymentModal';
// redux
import {useDispatch} from 'react-redux';
import { succeeded_state } from '../../actions';
// Containers
import UseToggle from '../../containers/toggle.js';
import PaymentIcon from '../Atoms/paymentIcon';

function Payment(props){
	// reduxディスパッチ
	const dispatch = useDispatch();
  const stackPoints = [
    {price: 550, point: 500 },
    {price: 1100, point: 1000 },
    {price: 3300, point: 3000 },
    {price: 5500, point: 5000 },
    {price: 11000, point: 10000 },
  ]
	const [toggle, modalToggle] = UseToggle(false);
	const [amount, setAmount] = useState('');
  const handlePayment = async(e)=>{
    const price = e.target.getAttribute('data-price');
    setAmount(price);
		modalToggle();
  }

  // 状態をリセットする
  const stateReset =  ()=>{
    modalToggle();
    // succeededのステートを変更
    dispatch(succeeded_state(false));
  }
	return(
    <React.Fragment>
			<PaymentModal targetID='modal' modalToggle={stateReset} toggle={toggle} amount={amount}/>
      <div className="w-full bg-gray-100">
        <h1 className="font-bold text-xl pl-2 ml-4 my-4">StackPoint 購入</h1>
          <div className="container mb-2 flex mx-auto w-full items-center justify-center">
            <ul className="flex flex-col flex-wrap md:w-4/6 w-11/12 shadow-md bg-white rounded-lg py-8">
                {stackPoints.map((stackPoint, index) => (
                  <li className="border-gray-400 flex flex-row mb-2 md:w-3/5 w-4/5 m-auto" key={index}  >
                    <div className="w-full justify-between rounded-md flex flex-1 items-center p-4 ease-in-out rounded-2xl border-2 p-6 mt-3 border-blue-300"> 
                      <div className="flex">
                        <div className="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">
                          <PaymentIcon size="history"/>
                        </div>
                        <div className="font-medium m-auto">
                          {stackPoint.point}pt
                        </div>
                      </div>
                      <div 
                        className="md:w-1/4 w-2/5 cursor-pointer md:mr-10 hover:opacity-75 text-wrap text-white text-bold flex flex-col rounded-md main-background justify-center items-center p-2" 
                        data-price={stackPoint.price}
                        onClick={handlePayment}>
                        {stackPoint.price}円
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
      </div>
    </React.Fragment>
	)
}
export default Payment;