import React, {useState, useEffect } from 'react';
import AnalyticsContent from './analyticsContent';

import AnalyticsTab from '../Molecules/analyticsTab';
// Containers
import UseReserve from '../../containers/reserved_func.js';

function AnalyticsStreaming(props){
  const streamingLists= props.resource.read().data.listStreamings.items;

  if(streamingLists.length == 0){
    props.func(false);
  }
  let [ reserveList_date, setReserveLists ] = useState([]);
	// 予約配信を配列にまとめる関数
	const [make_array_reserve] = UseReserve();

	useEffect(()=>{
		(async function (){
      const result = await make_array_reserve(streamingLists);
      setReserveLists(result);
    })();
	},[])
	return(
		<React.Fragment>
        <div className="min-h-screen">
          <AnalyticsTab/>
          <div className="w-9/12 mx-auto text-center">
            <div className="text-xl font-bold mt-7">
            ＜ 2022年2月〜3月 ＞
            </div>
            <ul className="flex justify-center my-10">
              <li className="bg-gray-100 px-10 py-5 rounded-lg mx-5 font-bold  text-blue-500">
                <p className="text-xl">視聴回数</p>
                <p className="mt-3 text-2xl">
                  10
                </p>
              </li>
              <li className="bg-gray-100 px-10 py-5 rounded-lg mx-5 font-bold text-green-500">
                <p className="text-xl">ボタンクリック数</p>
                <p className="mt-3 text-2xl">
                  10
                </p>
              </li>
              <li className="bg-gray-100 px-10 py-5 rounded-lg mx-5 text-xl font-bold text-red-500">
                <p className="text-xl">コメント数</p>
                <p className="mt-3 text-2xl">
                  10
                </p>
              </li>
            </ul>
            <div className="h-60 bg-gray-100">
              ここにグラフが入る
            </div>
          </div>
          <ul className="flex md:w-9/12 mx-auto px-5 mt-5 text-gray-800 font-bold">
            <li className="w-28">配信日</li>
            <li className="w-32 text-center mr-7">サムネイル</li>
            <li className="w-96 text-center">タイトル</li>
            <li className="w-44 text-center">ページビュー</li>
            <li className="w-44 text-center">視聴数</li>
            <li className="w-44 text-center">ボタンクリック数</li>
            <li className="w-44 text-center">コメント数</li>
          </ul>
            <div className="my-10">
            {						  
              reserveList_date.map((reserveList,index)=>
                <React.Fragment  key={index}>
                  <AnalyticsContent channel={reserveList.channel} thumbnail={reserveList.channel[1]} time={reserveList.time} width="md:w-9/12"/>
                </React.Fragment>
              )
            }
            </div>
        </div>
		</React.Fragment>
	)
}
export default AnalyticsStreaming