import {
	MODALSTATE,
} from '../actions/index.js'

const initialState = {
	bool: false,
};
function modal_state (state = initialState, action){
	switch (action.type){
		case MODALSTATE:
			return{ bool: !state.menu}
		// まずはここでstateをセットする
		default:
			return state
	}
}

export default modal_state;