//処理が失敗した時のモーダル
import React from 'react';

function ErrorSlider(props){
  return(
    <div className={"bg-red-50 fixed top-0 p-5 w-full text-red-600 font-bold z-50" + (props.toggle == true && " success-slider")}>
      <div className="flex justify-between">
        <div className="flex">
          <svg className="w-5 h-5 mr-2 m-auto" onClick={props.modalToggle} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg>
          <p>{props.message}!</p>
        </div>
        <svg 
        className="h-5 w-5 cursor-pointer text-red-600 hover:opacity-75" 
        onClick={props.modalToggle} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
  )
}
export default ErrorSlider 