import {
	PAID_STATE,
} from '../actions/index.js'

const initialState = {
	paid: false,
};
function paid_toggle (state = initialState, action){
	switch (action.type){
		case PAID_STATE:
			return{ paid: action.props}
		// まずはここでstateをセットする
		default:
			return state
	}
}

export default paid_toggle;