// 配信画面のコントローラー
import React, { useEffect, useState } from 'react';
import Button from '../Atoms/button';
// snsシェアボタンを作ってくれている
import {
    TwitterShareButton,
    TwitterIcon
} from 'react-share';
// AppSync
import API, { graphqlOperation } from '@aws-amplify/api';
// Containers
import UseClipBoard from '../../containers/copyClipboard.js';
// DB関連
import * as gqlQueries from '../../graphql/queries'

export default function Controller(props){
	const [title, setTitle] = useState('');
	const [ popUp, popUpToggle ] = useState(false);
  const url = window.location.protocol + '//' + window.location.host;
	const viewingUrl = url + '/viewing/' + props.channelId;

	useEffect(()=>{
      // streaming情報を取得する
      (async function getStreaming(){
        await API.graphql(graphqlOperation(gqlQueries.getStreaming, { id: props.channelId })).then((streamingInfo)=>{
          setTitle(streamingInfo.data.getStreaming.title);
        });
      })();
    },[])

	// クリップボードに保存する
	const copyToClipboard = (e)=>{
		let val = e.currentTarget.dataset['url'];
		let copyTarget = document.getElementById(val);
    popUpToggle(true);
    setTimeout(() => {
      UseClipBoard(copyTarget);
    }, 1);
    // 3秒後にけす　
    setTimeout(() => {
      popUpToggle(false);
    }, 3000);
	}
  return(
    <div className="w-full flex bg-gray-400 bg-opacity-25 py-2 justify-between">
      <div className="flex">
        <div className="flex justify-center mx-5 my-auto">
            {
                props.nowStreaming ?
                    <Button size="live" type={(props.nowStreaming ? "streaming_stop": "disabled")} children="終了"  func={props.stopStream} disabled={props.nowStreaming ? false: true}/>
                :
                    <Button size="live" type={(props.nowStreaming ? "disabled": "abled")} children="配信"  func={props.startStream} disabled={props.nowStreaming ? true: false}/>
            }
        </div>
        <div className="relative text-white ml-5 my-auto">
              <div className="group cursor-pointer inline-block text-center">
                {
                    props.is_mute ?
                    <React.Fragment>
                      <div className="rounded-full h-10 w-10 flex items-center justify-center bg-red-600">
                        <svg className="w-6 h-6 cursor-pointer hover:opacity-75 m-auto" xmlns="http://www.w3.org/2000/svg" onClick={props.releaseMute} width="25" height="25" fill="#ffffff" viewBox="0 0 256 256">
                            <line x1="191.97656" y1="104" x2="191.97656" y2="152" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></line>
                            <line x1="223.97656" y1="88" x2="223.97656" y2="168" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></line>
                            <line x1="48" y1="40" x2="208" y2="216" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></line>
                            <polyline points="112.15 62.994 152 32 152 106.829" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></polyline>
                            <path d="M152,154.4V224L80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H91.63636" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></path>
                        </svg>
                      </div>
                      <div className="w-24 opacity-0 bg-blue-300 text-white text-center text-xs rounded-lg py-2 absolute -left-7 z-10 group-hover:opacity-100 bottom-full px-3 pointer-events-none">
                        マイクをONにする
                        <svg className="absolute text-blue-200 h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255" space="preserve">
                          <polygon className="fill-current" points="0,0 127.5,127.5 255,0"/>
                        </svg>
                      </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <div className="rounded-full h-10 w-10 flex items-center justify-center shadow bg-gray-700">
                        <svg className="w-6 h-6 cursor-pointer hover:opacity-75 m-auto" onClick={props.audioMute} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ffffff" viewBox="0 0 256 256">
                            <path d="M218.88231,77.08831a72,72,0,0,1,0,101.82338" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></path>
                            <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80l72-56V224Z" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></path>
                            <line x1="80" y1="88" x2="80" y2="168" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></line>
                            <path d="M190.59793,105.37258a32,32,0,0,1,0,45.25484" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></path>
                        </svg>
                      </div>
                      <div className="w-24 opacity-0 bg-blue-300 text-white text-center text-xs rounded-lg py-2 absolute -left-7 z-10 group-hover:opacity-100 bottom-full px-3 pointer-events-none">
                        マイクをOFFにする
                        <svg className="absolute text-blue-200 h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255" space="preserve">
                          <polygon className="fill-current" points="0,0 127.5,127.5 255,0"/>
                        </svg>
                      </div>
                    </React.Fragment>
                }
              </div>
        </div>
        <div className="relative text-white ml-5 my-auto">
          <div className="group cursor-pointer inline-block text-center">
            {
                props.is_play ?
                  <React.Fragment>
                    <div className="rounded-full h-10 w-10 flex items-center justify-center shadow bg-gray-700">
                      <svg onClick={props.releaseVideo} className="m-auto w-6 h-6 text-white cursor-pointer hover:opacity-75" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                    <div className="w-24 opacity-0 bg-blue-300 text-white text-center text-xs rounded-lg py-2 absolute -left-7 z-10 group-hover:opacity-100 bottom-full px-3 pointer-events-none">
                      一時停止する
                      <svg className="absolute text-blue-200 h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255" space="preserve">
                        <polygon className="fill-current" points="0,0 127.5,127.5 255,0"/>
                      </svg>
                    </div>
                  </React.Fragment>
                :
                  <React.Fragment>
                    <div className="rounded-full h-10 w-10 flex items-center justify-center shadow bg-red-600">
                      <svg onClick={props.playVideo} className="h-6 w-6 flex items-center justify-center cursor-pointer hover:opacity-75" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                    <div className="w-24 opacity-0 bg-blue-300 text-white text-center text-xs rounded-lg py-2 absolute -left-7 z-10 group-hover:opacity-100 bottom-full px-3 pointer-events-none">
                      ライブを再開する
                      <svg className="absolute text-blue-200 h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255" space="preserve">
                        <polygon className="fill-current" points="0,0 127.5,127.5 255,0"/>
                      </svg>
                    </div>
                  </React.Fragment>
            }
          </div>
        </div>
        <div className="text-white mx-5 my-auto relative">
          <div className="relative text-white my-auto">
            <div className="group cursor-pointer inline-block text-center">
              <React.Fragment>
                <div onClick={copyToClipboard} data-url="copyUrl" className="rounded-full h-10 w-10 flex items-center justify-center bg-gray-700">
                  <svg className="m-auto w-6 h-6 text-white cursor-pointer hover:opacity-75" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                  </svg>
                </div>
                <div className="w-24 opacity-0 bg-blue-300 text-white text-center text-xs rounded-lg py-2 absolute -left-7 z-10 group-hover:opacity-100 bottom-full px-3 pointer-events-none">
                  視聴URLをコピーする
                  <svg className="absolute text-blue-200 h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255" space="preserve">
                    <polygon className="fill-current" points="0,0 127.5,127.5 255,0"/>
                  </svg>
                </div>
                <div className={"absolute bg-gray-300 rounded p-5 top-0 left-5 w-96" + (popUp ? " block": " hidden")}>
                  <p className="text-black font-semibold">クリップボードにコピーしました！</p>
                  <input id="copyUrl" className="text-black w-full px-2 py-1 mt-2 text-sm rounded" type="text" readOnly value={viewingUrl}/>
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
        <div className="text-white mx-5">
            <TwitterShareButton url={viewingUrl} title={title} hashtags={['stacklive']} className="w-7 h-7">
                <TwitterIcon size={25} round />
            </TwitterShareButton>
            <p className="text-xs font-semibold">SNS</p>
        </div>
    </div>
  )
}
