// 振り込み申請をするページ
import React, {useState, useEffect } from 'react';
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { Link, useHistory} from 'react-router-dom';
// フォームを扱うライブラリ
import { useForm } from "react-hook-form";
// Molecules
import SuccessPopup from '../Molecules/success-popup.js';
import Success from '../Molecules/success.js';
// redux
import {useDispatch ,useSelector} from 'react-redux';
import '../../style/setting.css';
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import { change_user } from '../../actions';
// Molecules
import ModalCircle from '../Molecules/modalCircle.js';
import Spinner from '../Atoms/spinner';
// Containers
import UseToggle from '../../containers/toggle.js';
// DB関連
import * as gqlMutations from '../../graphql/mutations'
import * as gqlQueries from '../../graphql/queries'

function Payout(){
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
  // 報酬総額
  let receive_point = '';
  if(userInfo.receive_point){
    receive_point = Math.round(userInfo.receive_point);
  }else{
    receive_point = 0;
  }
	const [payout_amount, setPayout ] =  useState(0);
	const [input_amount, setInput ] =  useState(0);
	const [ payout_enabled, setEnabled ] = useState(true);
	const [ stripe_url, setStripeUrl ] = useState('');
	const [ error, setError ] = useState('');
  const [succeeded, setSucceeded] = useState(false);
	// reduxディスパッチ
	const dispatch = useDispatch();
	// react-hook-formで使用する
	const { register, handleSubmit, watch, errors } = useForm();
	const [ is_loading, setLoading ] = useState(false);
	//  successモーダルのトグル
	const [toggle, modalToggle] = UseToggle(false);
	let history = useHistory();

	useEffect(()=>{
		const f = async()=>{
			// 振込申請金額未満だったらリダイレクト
			if(receive_point < 3350){
				// 振込申請画面へリダイレクト
				history.push("/dashboard");
			}	
			// 現状のStripeアカウントの状態を確認する。
			const getAccount = await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { 
				event: 'getAccount', 
				custom_account: userInfo.custom_account, 
			}));
			if(!getAccount.data.stripeFunction.charges_enabled || !getAccount.data.stripeFunction.payouts_enabled){
				// どっちかがfalseだった場合アカウントリンクを作成する。
				const {data: {stripeFunction: {account_link}}}= await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { 
					event: 'createAccountLink', 
					custom_account: userInfo.custom_account, 
					type: 'account_onboarding'
				}));
				setStripeUrl(account_link);
				setEnabled(false);
			}
		};
		f();
	},[]);
	// プラットフォームから子アカウントへ資金を移動する
	const submitPayout = async(data)=>{
		setLoading(true);
		if(!error){
			const {data: {stripeFunction: {id, amount}}}= await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { 
				event: 'transfers', 
				custom_account: userInfo.custom_account, 
				amount: payout_amount, 
			}));
			const transfer_id = id;

			// 入金をした後の残高を計算してユーザー情報をアップデートする
			let remain_amount = userInfo.receive_point - input_amount;
			let input = {
					id: userInfo.id,
					receive_point : remain_amount
			}
			await API.graphql(
				graphqlOperation(gqlMutations.updateUser, {
					input: input
			})).then((data) =>{
				// 成功処理
				if(data != null){
					nprogress.done();
					// setLoading(false);
					setSucceeded(true);
					// ユーザー状態が変更した事を発火
					dispatch(change_user());
				}
			}).catch(async(e)=>{
					// データベース処理でエラーが発生したら子アカウントからプラットフォームへ送金を戻す
					const {data: {stripeFunction: {id}}}= await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { 
						event: 'transfer_reversal', 
						transfer: transfer_id, 
					}));
					nprogress.done();
					setLoading(false);
					alert('振り込み申請中にエラーが起きました。少し時間をおいて再度お試しください。')
					console.log(e);
			});
		}else{
			setLoading(false);
		}
	}
	// リアルタイムでフォームを監視
  watch('payout_amount')
  // 入力された申請金額から手数料を引いて表示
  const handleChange = (e)=>{
		if(e.target.value == ''){
			setError(true);
		}else if(e.target.value - 350 <= 0 ){
			setError('申請金額は351円以上にしてください');
		}else if(receive_point < e.target.value){
			setError('金額が不足しています。');
		}else{
			setError(false);
		}
    setPayout(e.target.value - 350);
		// 入力された値
    setInput(e.target.value);
  }
	return(
			<React.Fragment>
				{
					succeeded ?
						<div className="w-full mt-48">
							<Success succeeded={succeeded} message="申請が完了しました。"/>
						</div>
					:
				<div className="md:w-3/6 w-full md:mx-auto md:mt-20 md:mt-10 mt-0 h-full md:border-solid md:border md:border-gray-200 md:shadow-md rounded">
					<div className="h-auto">
						<h2 className="text-center font-semibold text-2xl md:my-8 mt-8 mb-4">振込申請</h2>
						<div className="w-11/12 m-auto">
							{
								payout_enabled ? 
								'' 
								:  
								<p className="font-semibold ml-2 text-gray-600 text-center">
								StackLiveは安全な支払いのためにStripeと連携しています。<br/>
								振込申請をするためには
								<a href={stripe_url} className="text-blue-500 hover:opacity-75">本人確認</a>
								と
								<a href={stripe_url} className="text-blue-500 hover:opacity-75">銀行口座登録</a>
								をお願いいたします。
								</p>
							}
							<div>
								<div className="leading-loose">
									<form className="max-w-xl m-auto py-4 md:px-10 px-4 bg-white rounded">
										<div className="mb-3">
                      <div className="flex relative">                           
                        <span className="absolute left-2 top-1 text-gray-500 font-bold">振込申請金額</span>
                        <input 
                          id="payout_amount"
                          className={"text-right appearance-none w-full pl-5 pr-7 py-1 text-gray-700 bg-blue-50 rounded focus:outline-none border border-blue-300" + (errors.payout_amount ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
                          name="payout_amount" 
                          ref={register({ required: true })}
                          type="number" 
                          placeholder="5000" 
                          onChange={handleChange}
                        />
                        <span className="absolute right-2 top-1 text-gray-500 font-bold">円</span>
                      </div>
										{errors.payout_amount && <span className="text-red-600 font-semibold text-xs">振込金額を入力してください</span>}
										{error && <span className="text-red-600 font-semibold text-xs">{error}</span>}
										</div>
                    <p className="text-gray-500 mb-3 font-semibold text-sm ml-2">
											※報酬額が3,350円（振込下限3,000円+振込手数料350円）以上になると指定の口座に振込ができます。
										</p>
										<div className="mb-3 mt-10">
                      <div className="flex relative">                           
                        <span className="absolute left-2 top-1 text-gray-500 font-bold">現在の報酬金額</span>
                        <input 
                          id="receive_amount"
                          className={"appearance-none opacity-100 w-full text-right pl-5 pr-7 py-1 text-gray-700 bg-gray-100 rounded focus:outline-none border border-gray-300"}
                          name="receive_amount" 
													value={receive_point}
                          disabled
												/>
                        <span className="absolute right-2 top-1 text-gray-500 font-bold">円</span>
                      </div>
										</div>
										<div className="mb-3">
                      <div className="flex relative">                           
                        <span className="absolute left-2 top-1 text-gray-500 font-bold">振込手数料</span>
                        <input 
                          id="receive_amount"
                          className={"appearance-none opacity-100 w-full text-right pl-5 pr-7 py-1 text-gray-700 bg-gray-100 rounded focus:outline-none border border-gray-300"}
                          name="receive_amount" 
                          value="350"
                          disabled
												/>
                        <span className="absolute right-2 top-1 text-gray-500 font-bold">円</span>
                      </div>
										</div>
										<div className="mb-3">
                      <div className="flex relative">                           
                        <span className="absolute left-2 top-1 text-gray-500 font-bold">振込金額</span>
                        <input 
                          id="receive_amount"
                          className={"appearance-none opacity-100 w-full text-right pl-5 pr-7 py-1 text-gray-700 bg-gray-100 rounded focus:outline-none border border-gray-300"}
                          name="receive_amount" 
													value={payout_amount}
                          disabled
												/>
                        <span className="absolute right-2 top-1 text-gray-500 font-bold">円</span>
                      </div>
										</div>
										<p className="text-right text-sm text-gray-500 font-semibold">
											<span className="text-blue-500 hover:opacity-75">
												<a href="https://www.notion.so/StackLive-726d6cd633854b1b8174961e6285f024#fc511db8a2c34fefa9e2e915674b82c9">
													振込スケジュール
												</a>
											</span>
											に関してはこちら
										</p>
									</form>
								</div>
								<div className="text-center my-6">
										<button
										id=""
										className={"text-white font-bold px-4 py-2 text-ml rounded focus:outline-none" + (payout_enabled ? ' main-background hover:opacity-75' : ' bg-gray-300')}
										onClick={handleSubmit(submitPayout)}
										type="button"
										disabled={payout_enabled ? false : true}
										>
											{is_loading ?
											<Spinner/>
											:
											"申請する"}
										</button>
								</div>
							</div>
						</div>
					</div>							
				</div>
				}
			</React.Fragment>
	)
}
export default Payout
