import {
	STREAMING_INFO
} from '../actions/index.js'

const initialState = {
	id:'',
	playback_url: '',
	rtmp_url: '',
	channel_arn: '',
	title: '',
	summary: '',
	is_live: '',
	thumbnail: '',
  user_id: '',
	viewerCount:'',
	key:''
};

function streaming (state = initialState, action){
	switch (action.type){
		case STREAMING_INFO:
			return{
				id: action.params.id,
				playback_url: action.params.playbackUrl,
				rtmp_url: action.params.rtmp_url,
				channel_arn: action.params.channel_arn,
				title: action.params.title,
				summary: action.params.summary,
				is_live: action.params.is_live,
				thumbnail: action.params.thumbnail,
				user_id: action.params.user_id,
				viewerCount:action.params.viewerCount,
				key:action.params.key
			}
		// まずはここでstateをセットする
		default:
			return state
	}
}

export default streaming;
