// style
export const buttonSize = {
	md: 'ml-5 px-4 text-ml h-full',
	config: 'w-20 text-sm m-auto py-1 px-4',
	small: 'w-16 text-xs px-1',
	tab: 'py-4 px-6',
	live: 'w-20 py-2 px-4',
	liveConfig: 'w-20 py-2 px-4 m-auto my-4',
	yes_no: 'w-1/3 py-3 px-4 m-auto my-4',
	streamingconfig: "px-4 py-2",
	viewing_reserve: "md:px-6 px-4 py-3",
	notice_readmore: "py-2 px-5 my-2 text-sm",
	paid_button: "px-4 py-2 md:text-2xl text-lg",
}

export const buttonType = {
	blue: 'main-background hover:opacity-75 text-white font-bold rounded',
	paid: 'bg-yellow-400 hover:opacity-75 text-white font-bold rounded mt-10',
	white: 'border rounded-full shadow border-gray-400 block text-gray-600 hover:opacity-75 font-bold rounded focus:outline-none',
	activetab: 'hover:text-blue-500 focus:outline-none text-blue-600 border-b-2 font-medium border-blue-600 text-gray-600 block',
	tab: 'text-gray-600  block hover:text-blue-600 focus:outline-none',
	stop: 'bg-gray-500 hover:opacity-75 text-white font-bold rounded',
	abled: 'main-background hover:opacity-75 text-white font-bold rounded',
	streaming_stop: 'bg-red-600 hover:opacity-75 text-white font-bold rounded',
	disabled: 'bg-gray-300 text-white rounded opacity-75 font-bold mr-3',
	reserve: 'bg-red-600 hover:opacity-75 text-white font-bold text-ml rounded focus:outline-none',
	viewing_reserve: "main-background rounded md:text-sm text-xs text-white my-auto font-bold cursor-pointer hover:opacity-75",
	remove_reserve: "bg-red-500 rounded md:text-sm text-xs text-white my-auto font-bold cursor-pointer hover:opacity-75",
	notice_readmore: "border border-gray-300 rounded mx-auto shadow text-gray-600 hover:opacity-75"
}
