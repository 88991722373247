import React, {useState, useEffect} from 'react';
import API, { graphqlOperation } from '@aws-amplify/api';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ja';
import { notification_badge} from '../../actions';
import Img from '../Atoms/img';
import Button from '../Atoms/button';
// redux
import {useSelector, useDispatch } from 'react-redux';
import UseToggle from '../../containers/toggle.js';
// DB関連
import * as gqlQueries from '../../graphql/queries'
import * as gqlMutations from '../../graphql/mutations'

function TogglePage(props){
	const [notices, setNotice] = useState([]);
	// 通知の管理
	const [ token, setNextToken ] = useState('');
	const [bool, setHasMore] = useState(true);  
	// 通知の有無確認
	const [is_notice, setIsNotice] = useState(0);
	const [ is_loading, setLoading] = UseToggle(false);
	let history = useHistory();
	const dispatch = useDispatch();
	const noticeCount = useSelector(state => state.countNotices.count);
	// APIで受け取ったデータを整形する
	const createNotice = (data)=>{
		let array = [];
			if(data.length){
					data.map((noticeList)=>{
						switch(noticeList.contents){
							case "LIVE_START":
								let title =  noticeList.streaming.title.substr(0, 12) + '...';
								array = [...array, {
									id: noticeList.id, 
									name: noticeList.user.name, 
									icon: noticeList.user.icon, 
									streaming_id: noticeList.streaming_id, 
									streaming_title: title, 
									text: 'の配信を開始しました', 
									flag: noticeList.flag,
									createdAt: noticeList.createdAt
								}];
								break;
						}
				});
				setIsNotice(1);
				return array
			}else{
				array = ['通知はありません'];
				return array
			}
	}
	useEffect(()=>{
    (async function (){
			const { data: { createdAtNotice: { items: itemsPage1, nextToken } } } = await API.graphql({query:gqlQueries.createdAtNotice,variables:{ receive_user: props.userInfo.id, sortDirection: "DESC",limit: 15}});
			// 未読の件数
			const { data: { createdAtNotice: { items: noReadNotices} } } = await API.graphql({query:gqlQueries.createdAtNotice,variables:{ receive_user: props.userInfo.id, filter:{flag:{eq: 0}}}});
			// 通知の数を更新
			dispatch(notification_badge(noReadNotices.length));
			try{			
				let result = createNotice(itemsPage1);
				setNotice(result);
				setNextToken(nextToken);
				// 最初の読み込みの段階で残りのコンテンツが5件以上なければ終了
				if(nextToken == null){
						setHasMore(false);
				}
			}catch(e){
				console.log(e);
			}
    }());
	},[]);

	// 通知をクリック
	const noticeClick = async(e)=>{
		let id = e.currentTarget.dataset.noticeid;
		let streaming_id = e.currentTarget.dataset.streamingid;
		const clickNotice = await API.graphql(graphqlOperation(gqlQueries.getNotices,{ id: id }));
		// クリックした通知が未読であれば
		if(!clickNotice.data.getNotices.flag){
			API.graphql(graphqlOperation(gqlMutations.updateNotices,{input:{id: id, flag: 1}}));
			// 通知の数を更新
			dispatch(notification_badge(noticeCount - 1));
		}
		history.push("/viewing/" + streaming_id);
	}

	// 次のページを読み込む
  async function loadmore(){
		// ローディングを開始させる
		setLoading();
		if(bool){
			//次のコンテンツを読み込む
			const { data: { createdAtNotice: { items: itemsPage2, nextToken } } } = await API.graphql({query:gqlQueries.createdAtNotice,variables:{ receive_user: props.userInfo.id, sortDirection: "DESC",limit: 5, nextToken: token}});
			let nextContents = createNotice(itemsPage2);
			let mergeContent = notices.concat(nextContents);
			setNotice(mergeContent);
			setNextToken(nextToken);
			// 読み込みを終了 
			if(nextToken == null){
				setHasMore(false);
			}
		}
		// ローディングを終了させる
		setLoading();
	}

	return(
		<div className={"absolute md:-left-40 -left-64 mt-2 origin-top-right rounded-md shadow-lg w-72 z-10  overflow-scroll max-h-notice-area" + (props.value ? " block" : " hidden")}  >
      <div className="rounded-md shadow rounded bg-white">
				{
					is_notice ?
						notices.map((notice,index) =>
							<div 
								className={"flex px-4 py-4 text-sm cursor-pointer block hover:text-gray-900 hover:bg-gray-50" + (notice.flag ? " ": " bg-blue-50")}
								key={index}
								onClick={noticeClick}
								data-streamingid={notice.streaming_id} 
								data-noticeid={notice.id} 
							>
								{
									notice.icon ?
										<Img size="notice_icon" type="md" src={notice.icon}/>
											:
										<img src="/images/smiley-sticker.svg" className="mr-1 h-10 w-auto rounded-full "/>
								}
								<div className="break-all">
									<span className="font-bold">{notice.name}</span>
									さんが
									<span className="font-bold">{notice.streaming_title}</span>
									<span className="font-medium">{notice.text}</span>
									<p className="text-xs mt-1 text-gray-500">
										{moment(notice.createdAt).fromNow(true)}前
									</p>
								</div>
							</div>
						)
					:
							<div className="px-4 py-4 text-sm font-bold text-gray-700">
								{notices}
							</div>
				}
				<div className="flex">
					{
						bool == true &&
							<Button size="notice_readmore" type="notice_readmore" children="さらに読み込む" func={loadmore} is_loading={is_loading}/>
					}
				</div>
			</div>
		</div>
	)
}
export default TogglePage
