import { iconSize } from './theme.js';

export default function BellIcon({size, type, func}){
	const classNames = iconSize[size];
	return(
		<svg onClick={func} xmlns="http://www.w3.org/2000/svg" className={classNames} width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6b7788" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
  <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
		</svg>
	)
}
