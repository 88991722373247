import React, { useEffect, useState } from 'react';
import { Link} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
// フォームを扱うライブラリ
import { useForm } from "react-hook-form";
import UseToggle from '../containers/toggle.js';
// Molecules
import Logo from './Molecules/logo.js';
import ErrorCard from './Molecules/error-card.js';
import SuccessPopup from './Molecules/success-popup.js';
// Atoms
import Spinner from './Atoms/spinner';

export default function Forgot(props) {
	//  successモーダルのトグル
	const [now_success, successView] = UseToggle(false);
    const [codeSent, setCodeSent] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

	// react-hook-formで使用する
	const { register, handleSubmit, watch, errors } = useForm();
	const [ is_error, errorToggle ] = useState(false);
	const [ is_loading, setLoading ] = useState(false);
	const [error_message, setMessage] = useState('');
	const [input_email, setEmail] = useState('');
	// リアルタイムでフォームを監視
    watch('email');
    watch('new_password')
    watch('code')
    watch('cpassword')
    const new_password = watch("new_password", "");

    // パスワードリセットコード送信
    const email_handleSubmit = async(data)=>{
        setLoading(true);
        nprogress.start();
        await Auth.forgotPassword(data.email).then((result)=>{
            setCodeSent(true);
            setLoading(false);
            setEmail(data.email);
            nprogress.done();
            errorToggle(false);
            successView();
        }).catch((err)=>{
            switch(err.code){
                case 'LimitExceededException':
                    setMessage('再設定回数を超えました。時間をおいてお試しください')
            } 
            // setMessage('メールアドレスが存在しません')
            nprogress.done();
            setLoading(false);
            errorToggle(true);
        })
    };
    // 新しいパスワードの設定
    async function new_handleSubmit(data) {
            await Auth.forgotPasswordSubmit(
                input_email,
                data.code,
                data.new_password
            ).then(()=>{
                setConfirmed(true);
            }).catch((err)=>{
                switch(err.code){
                    case 'CodeMismatchException':
                        setMessage('メールアドレスか確認コードが間違っています')
                    case 'InvalidParameterException':
                        setMessage('パスワードは大文字、小文字、数字を含めて8文字以上にする必要があります')
                } 
                console.log(err);
                nprogress.done();
                setLoading(false);
                errorToggle(true);
            })
    };

    // パスワードリセットコード送信(view)
    function renderRequestCodeForm() {
        return (
		<div className="min-h-screen pt-16 md:pt-20 bg-gray-100 flex flex-col">						
			<div className="md:px-10 xs:p-0 mx-auto md:w-full md:max-w-lg w-10/12">						
			<h1 className="font-bold text-center text-2xl mb-5">
				{/*StackLiveロゴ*/}
				<Link to="/" className="inline-block">
					<Logo dark={props.dark} size="lx"/>
				</Link>
			</h1>  						
		    {/* エラーメッセージ文言 */}
            <ErrorCard toggle={is_error} children={error_message} func={()=> errorToggle(false)}/>
			<div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200">						
				<form onSubmit={handleSubmit(email_handleSubmit)} className="py-12 w-10/12 m-auto">
					<label className="font-semibold text-gray-600 pb-1 block md:text-base text-sm" htmlFor="mailaddress">メールアドレス</label>						
					<input 
						name="email" 
						type="text" 
						className={"focus:outline-none border appearance-none rounded-lg px-3 py-2 mt-1 w-full md:text-sm text-base" + (errors.email ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
						ref={register({ 
							required: {
								value: true,
								message: "メールアドレスを入力してください"
							},
							pattern: {
								value: /^[A-Za-z0-9]{1}[A-Za-z0-9\_.+-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
								message: 'メールアドレスの形式が正しくありません',
							},
						})} 
						placeholder="info@stacklive.jp"
						/>						
					{errors.email && <span className="text-red-600 font-semibold text-xs">{errors.email.message}</span>}
					<button 
						type="submit"
						className="cursor-pointer mt-6 main-background hover:bg-blue-400 transform_scale text-lg text-white w-full py-2.5 focus:outline-none rounded-lg text-sm shadow-sm font-semibold text-center inline-block"									
					>
					{is_loading ? 
					<Spinner/> 
					: 
					"パスワード再設定"}
					</button>
				</form>
			</div>						
			</div>
		</div>
        )
    }


    // 新しいパスワードの設定(view)
    function renderConfirmationForm() {
        return (
				<div className="min-h-screen pt-16 md:pt-20 bg-gray-100 flex flex-col">						
					<div className="md:px-10 xs:p-0 mx-auto md:w-full md:max-w-lg w-10/12">						
					<h1 className="font-bold text-center text-2xl mb-5">
						{/*StackLiveロゴ*/}
						<Link to="/" className="inline-block">
							<Logo dark={props.dark} size="lx"/>
						</Link>
					</h1>  						
		            {/* エラーメッセージ文言 */}
                    <ErrorCard toggle={is_error} children={error_message} func={()=> errorToggle(false)}/>
                    <div></div>
					<div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200">						
                        <form onSubmit={handleSubmit(new_handleSubmit)} className="py-12 w-10/12 m-auto">
							<label className="font-semibold text-gray-600 pb-1 block md:text-base text-sm" htmlFor="code">認証コード</label>						
							<input 
								name="code" 
								className={"focus:outline-none border appearance-none rounded-lg px-3 py-2 mt-1 w-full md:text-sm text-base" + (errors.code ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
								ref={register({ required: true })} 
								type="code" 
								placeholder="123456"
							/>						
							{errors.code && <span className="text-red-600 font-semibold text-xs">認証コードを入力してください</span>}
                            <label className="font-semibold text-gray-600 pb-1 block md:text-base text-sm mt-6" htmlFor="password">新パスワード</label>						
                            <input 
                                name="new_password" 
                                className={"focus:outline-none border appearance-none rounded-lg px-3 py-2 mt-1 w-full md:text-sm text-base" + (errors.new_password ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
                                ref={register({ required: true })} 
                                type="password" 
                                placeholder="******************"
                            />						
                            {errors.new_password && <span className="text-red-600 font-semibold text-xs">パスワードを入力してください</span>}
                            <label className="font-semibold text-gray-600 pb-1 block md:text-base text-sm mt-6" htmlFor="password">新パスワード(確認)</label>						
                            <input 
                                name="cpassword" 
                                className={"focus:outline-none border appearance-none rounded-lg px-3 py-2 mt-1 w-full md:text-sm text-base" + (errors.cpassword ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
                                ref={register({ 
                                    required: {
                                        value: true,
                                        message: "確認パスワードを入力してください"
                                    },
                                    validate: value =>
                                        value === new_password || "パスワードが一致しません" 
                                })} 
                                type="password" 
                                placeholder="******************"
                            />						
                            {errors.cpassword && <span className="text-red-600 font-semibold text-xs">{errors.cpassword.message}</span>}
                            <button 
                                type="submit"
                                className="cursor-pointer mt-6 main-background hover:bg-blue-400 transform_scale text-lg text-white w-full py-2.5 focus:outline-none rounded-lg text-sm shadow-sm font-semibold text-center inline-block"									
                            >
                            {is_loading ? 
                            <Spinner/> 
                            : 
                            "リセット"}
                            </button>
                        </form>
					</div>						
					</div>
                    <SuccessPopup func={successView} toggle={now_success}>確認コードを送信しました</SuccessPopup>
				</div>
        )
    }


    function renderSuccessMessage() {
        return (
				<div className="min-h-screen pt-16 md:pt-20 bg-gray-100 flex flex-col">						
					<div className="md:px-10 xs:p-0 mx-auto md:w-full md:max-w-lg w-10/12">						
					<h1 className="font-bold text-center text-2xl mb-5">
						{/*StackLiveロゴ*/}
						<Link to="/" className="inline-block">
							<Logo dark={props.dark} size="lx"/>
						</Link>
					</h1>  						
					<div className="bg-white w-full rounded-lg md:p-14 p-10 text-center">						
                        <p className="mb-6">新しいパスワードが設定されました。</p>
                        <div>
						<Link to="/login" className="">
                            <p className="text-blue-700">ログイン画面へ</p>
                        </Link>
                        </div>
					</div>						
					</div>
				</div>
    );
    }

    return (
        <div>
            {!codeSent
                ? renderRequestCodeForm()
                : !confirmed
                ? renderConfirmationForm()
                : renderSuccessMessage()
            }
        </div>
    )
}
