// actionを設定する
// ユーザー情報を取得
export const USER_INFO = 'USER_INFO'
export const LOGIN_NOW = 'LOGIN_NOW'
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL'
export const BOOLEAN_MENU = 'BOOLEAN_MENU'
export const BOOLEAN_SM_MENU = 'BOOLEAN_SM_MENU'
export const STREAMING_INFO = 'STREAMING_INFO'
export const CHANGE_USER = 'CHANGE_USER'
export const PROGRESS_STATE = 'PROGRESS_STATE'
export const SUCCEEDED = 'SUCCEEDED'
export const BUYPOINT = 'BUYPOINT'
export const NOTIFICATION = 'NOTIFICATION'
export const MODALSTATE = 'MODALSTATE'
export const SCHEDULE = 'SCHEDULE'
export const PAID_STATE = 'PAID_STATE'


// ユーザー情報をセット
export const user_info = (params) =>{
	return {
		type: USER_INFO,
		params
	}
}
// 現在ログイン中かどうか確認
export const login_now = (bool) =>{
	return {
		type: LOGIN_NOW,
		bool,
	}
}
export const confirm_email = (email) => {
	return {
		type: CONFIRM_EMAIL,
		email
	};
}
// true&false変更
export const boolean_menu = (bool) =>{
	return {
		type: BOOLEAN_MENU,
		bool,
	}
}
export const boolean_sm_menu = (bool) =>{
	return {
		type: BOOLEAN_SM_MENU,
		bool,
	}
}

export const streaming_info = (params) => {
	return {
		type: STREAMING_INFO,
		params
	};
}

export const change_user = (params) => {
	return {
		type: CHANGE_USER,
	};
}

export const succeeded_state = (bool) => {
	return {
		type: SUCCEEDED,
		bool
	};
}
export const buy_point_succeeded_state = (bool) => {
	return {
		type: BUYPOINT,
		bool
	};
}
export const notification_badge = (count) => {
	return {
		type: NOTIFICATION,
		count
	};
}
export const modal_state = (bool) => {
	return {
		type: MODALSTATE,
		bool
	};
}
export const schedule_change = (props) => {
	return {
		type: SCHEDULE,
		props
	};
}
export const paid_toggle = (props) => {
	return {
		type: PAID_STATE,
		props
	};
}