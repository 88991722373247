import React from 'react';
import PlayArchiveTemp from '../Templates/playArchiveTemp.js';

function PlayArchive(props){
	return(
		<React.Fragment>
			<PlayArchiveTemp />
		</React.Fragment>
	)
}
export default PlayArchive
