import {divSize, divType} from './theme.js';

function Div(props){
	const classNames = divSize[props.size] + ' ' + divType[props.type];
	return(
		<div className={classNames} onClick={props.func} key={props.key}>
			{props.children}
		</div>
	)
}
export default Div
