import React from 'react';
import ReactDOM from 'react-dom';
import './style/main.css';
import './style/auth.css';
import './index.css';
import './App.css';
import './tailwind.compiled.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// stripeのフロントで使用するモジュール
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// storeの管理
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import reducer from './reducers'

import awsconfig from "./aws-exports";
import Amplify, { Auth } from "aws-amplify";

// Amplifyの設定を行う
Amplify.configure(awsconfig);

let store = createStore(reducer);
// 新規登録するユーザーテーブル名を環境変数から取得。ローカル開発ではenvファイルを作成して定義する。本番環境ではAmplifyで定義してある。
const stripePublicApikey = process.env.REACT_APP_PUBLIC_APIKEY;
// stripeAPI公開キー
const promise = loadStripe(stripePublicApikey);
// Providerによって、storeがどのコンポーネントからも参照できるようになる
ReactDOM.render(
  <Provider store={store}>
    <Elements stripe={promise}>
      <App />
    </Elements>
  </Provider>,
  document.getElementById('root')
);
reportWebVitals();
