import React, {useState, useEffect} from 'react';
//Atoms
import Img from '../Atoms/img';
import Button from '../Atoms/button';

import { useSelector } from "react-redux";
import {useHistory,Link} from 'react-router-dom';
import API, { graphqlOperation } from '@aws-amplify/api';

// Containers
import UseStreaming from '../../containers/streamingFunction.js';
import UseToggle from '../../containers/toggle.js';
// snsシェアボタンを作ってくれている
import {
    TwitterShareButton,
    TwitterIcon
} from 'react-share';

import SuccessSlider from '../Molecules/success_slider.js';
import ErrorSlider from '../Molecules/error_slider.js';

// DB関連
import * as gqlQueries from '../../graphql/queries'

function ReserveTemp(props){
  const streamingInfo = props.resource.read().data.getStreaming;
	const userInfo = useSelector(state => state.auth.userInfo);
	// ログイン中か確認
	const loginNow = useSelector(state => state.loginNow.bool);
	let history = useHistory();
	const tec_data =  streamingInfo.tec;
	const [ is_loading, setLoading ] = useState(false);
	const [ reserved, setReserved ] = useState(false);
	const [ reserveId, setReserveId ] = useState('');
	const [ succeeded, modalSuccess] = UseToggle(false);
	const [ reserve_count, setCount] = useState(0);
	const [ width, setWidth] = useState(0);
	const [errored, modalError] = UseToggle(false);
	delete tec_data['id'];
	delete tec_data['createdAt'];
	delete tec_data['updatedAt'];
  // 連想配列名を取得する
	let arrays = Object.keys(tec_data); 
	let new_arrays = ['StackLive'];

	//視聴予約 
	const streamingFunction = UseStreaming();

  let myStreaming = false;
  if(streamingInfo.user.id == userInfo.id){
    myStreaming = true;
  }
  // 配列のnull以外の値で新しい配列を作成する
  arrays.map(object => {
    if(tec_data[object] != null){
			new_arrays.unshift(tec_data[object]);
    }
	})
  let progress_width= {width: width + '%'};

  // 配信予約日を算出
  const reserve_date = new Date(streamingInfo.reserveDate);
  let yyyy = reserve_date.getFullYear();
  let mm = reserve_date.getMonth()+1;
  let dd = reserve_date.getDate();
  let hours = reserve_date.getHours();
  let minutes = reserve_date.getMinutes();
  let dayOfWeek = reserve_date.getDay() ;
  let dayOfWeekStr = [ "日", "月", "火", "水", "木", "金", "土" ][dayOfWeek] ;
  let times = '';

  if(minutes == 0){
    times = hours + ':' + '0'+ minutes
  }else{
    times = hours + ':' + minutes
  }

  // 視聴予約
  const reserve_streaming = async()=>{
    if(loginNow){
      setLoading(true);
      try{
        const result = await streamingFunction.viewing_reserve(streamingInfo.id,userInfo.id);
        setReserveId(result.data.createReserve.id);
        modalSuccess();
        setReserved(true);
      }catch(e){
        alert('視聴予約中にエラーが起こりました。時間をおいて再度お試しください。')
      }
      setLoading(false);
    }else{
			//loginページに遷移 
			history.push("/login");
    }
  }
  // 視聴予約を取り消す
  const remove_reserve = async()=>{
    if(loginNow){
      setLoading(true);
      try{
        streamingFunction.delete_reserve(reserveId)
        modalError();
        setReserved(false);
      }catch(e){
        alert('視聴予約取り消し中にエラーが起こりました。時間をおいて再度お試しください。')
      }
      setLoading(false);
    }else{
			//loginページに遷移 
			history.push("/login");
    }
  }

  // 視聴予約をしているか確認
	useEffect(()=>{
    (async function (){
      try{
          const reserveList = await API.graphql(graphqlOperation(gqlQueries.listReserves,{filter:{streaming_id: {eq: streamingInfo.id}}}));
          // 応募数を取得しstateに入れる
          setCount(reserveList.data.listReserves.items.length);
          let reserve_progress = reserveList.data.listReserves.items.length / streamingInfo.reserve_peoples * 100;
          if(reserve_progress > 100){
            reserve_progress = 100;
          }
          setWidth(Math.round(reserve_progress));
          const result = await API.graphql(graphqlOperation(gqlQueries.listReserves,{filter:{streaming_id: {eq: streamingInfo.id},user_id:{eq: userInfo.id}}}));
          if(result.data.listReserves.items.length){
            setReserveId(result.data.listReserves.items[0].id);
            setReserved(true);
          }
      }catch(e){
        alert('情報の取得中にエラーが起こりました。')
      }
    }());
	},[]);

	return(
		<React.Fragment>
      {/* 成功したことを知らせるスライド */}
      <SuccessSlider modalToggle={modalSuccess} toggle={succeeded} message="視聴予約をしました" />
      {/* 失敗したことを知らせるスライド */}
      <ErrorSlider modalToggle={modalError} toggle={errored} message="視聴予約を取り消しました" />
      <div className="bg-gray-100 w-full">
        <h1 className="md:text-2xl text-xl md:mt-10 mt-5 m-auto font-bold text-center text-gray-700 md:w-2/3 w-11/12">
          {streamingInfo.title}
        </h1>
        <div className="flex md:my-10 my-5 justify-center">
          {/* ユーザー情報 */}
          <Link to={"/user/" + (streamingInfo.user.user_id)} className="hidden md:flex fit-content  hover:opacity-75">
            {streamingInfo.user.icon ?
              <Img size="reserve_icon" src={streamingInfo.user.icon} />
              :
              <img src="/images/smiley-sticker.svg" className="md:w-10 md:h-10 w-8 h-8 rounded-full mr-1"/>
            }
            <p className="md:text-lg text-base my-auto font-semibold text-gray-700">
              {streamingInfo.user.name} 
            </p>
          </Link>
        </div>
        <div className="md:flex justify-center md:my-10 mt-5">
          {											
            streamingInfo.thumbnail != null &&
            <Img size="reserve_content" src={streamingInfo.thumbnail} alt="thumbnail" />
          }
          {											
            streamingInfo.thumbnail == null &&
            <img src="/images/home-logo.png" 
              className="md:h-64 h-56 rounded-md object-cover md:mr-10 mx-auto md:mx-0" 
              alt="thumbnail"
            />
          }
          <div className="bg-white shadow-xl rounded-lg md:w-2/5 w-11/12 md:mx-0 md:mt-0 mx-auto mt-5">
              <ul className="h-full divide-y divide-gray-300 text-gray-600">
                  {/* スマホの時のみ表示 */}
                  <li className="md:hidden block md:px-4 px-2 py-3">
                    <Link to={"/user/" + (streamingInfo.user.user_id)} className="flex fit-content justify-center hover:opacity-75">
                    {streamingInfo.user.icon ?
                      <Img size="reserve_icon" src={streamingInfo.user.icon} />
                      :
                      <img src="/images/smiley-sticker.svg" className="w-8 h-8 rounded-full"/>
                    }
                    <p className="md:text-lg text-base my-auto font-semibold ml-1">
                      {streamingInfo.user.name} 
                    </p>
                  </Link>
                  </li>
                  {/* 進捗バー */}
                  <li className="flex md:h-1/3 py-3">
                    <div className={"border-r border-gray-300" + (streamingInfo.is_paid ? " w-1/3" : " w-1/2")}>
                      <div className="flex justify-center">
                        <svg className="md:w-6 md:h-6 w-5 h-5 mr-1 my-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span className="font-semibold md:text-lg text-sm">配信予約時間</span>
                      </div>
                      <div className="flex w-full mt-2">
                        <span className="text-gray-600 md:text-lg text-sm font-semibold m-auto">
                          {mm}/{dd} (<span className="text-base">{dayOfWeekStr}</span>) {times}
                        </span>
                      </div>
                    </div>
                    <div className={streamingInfo.is_paid ? "w-1/3 border-r border-gray-300" : "w-1/2"}>
                      <div className="flex justify-center">
                        <svg className="md:w-6 md:h-6 w-5 h-5 mr-1 my-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                        <span className="font-semibold md:text-lg text-sm">視聴予約数</span>
                      </div>
                      <div className="flex w-full mt-2">
                        <span className="text-gray-600 md:text-lg text-sm font-semibold m-auto">
                          {reserve_count}人
                        </span>
                      </div>
                    </div>
                    {
                      streamingInfo.is_paid == true &&
                        <div className="w-1/3">
                          <div className="flex justify-center">
                            <svg className="md:w-6 md:h-6 w-5 h-5 mr-1 my-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 8l3 5m0 0l3-5m-3 5v4m-3-5h6m-6 3h6m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span className="font-semibold md:text-lg text-sm">有料配信</span>
                          </div>
                          <div className="flex w-full mt-2">
                            <span className="text-red-600 md:text-lg text-sm font-bold m-auto">
                              ¥{streamingInfo.price}
                            </span>
                          </div>
                        </div>
                    }
                  </li>
                  <li className="md:h-1/3 md:px-4 px-2 py-3">
                    <div className="flex">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-code md:w-6 md:h-6 w-5 h-5 md:mr-3 mr-2" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <polyline points="7 8 3 12 7 16" />
                        <polyline points="17 8 21 12 17 16" />
                        <line x1="14" y1="4" x2="10" y2="20" />
                      </svg>
                      <span className="font-semibold md:text-lg text-sm">カテゴリー</span>
                    </div>
                    {/* カテゴリー */}
                    <div className="flex mt-2 overflow-scroll hash_tag">
                      {
                      new_arrays.map((tech,index)=>
                        <div className="justify-center items-center mx-1 font-medium py-1 px-2 bg-white rounded-full text-blue-700 bg-blue-100 border border-blue-300" key={index}>
                        <div className="text-xs font-normal leading-none max-w-full flex-initial">#{tech}</div>
                        </div>
                      )
                      }
                    </div>
                  </li>
                  <li className="md:h-1/3 px-4 py-3 flex justify-around">
                    {
                      myStreaming ?
                        <Link to={"/livestreaming/" + (streamingInfo.id)} 
                          className="hidden md:block md:px-6 px-4 py-3 main-background rounded md:text-sm text-xs text-white my-auto font-bold cursor-pointer hover:opacity-75"
                        >
                          ライブ配信を開始する
                        </Link>
                      :
                      reserved ?
                        <Button 
                          size="viewing_reserve" 
                          type="remove_reserve" 
                          children="視聴予約を取り消す" 
                          func={remove_reserve} 
                          data="" 
                          disabled={false} 
                          is_loading={is_loading}
                        />
                      :
                        <Button 
                          size="viewing_reserve" 
                          type="viewing_reserve" 
                          children="視聴予約する" 
                          func={reserve_streaming} 
                          data="" 
                          disabled={false} 
                          is_loading={is_loading}
                        />
                    }
                    <div className="flex flex-col">
                      <span className="font-bold text-gray-500 md:text-base text-xs">SNSでシェアする</span>
                      <TwitterShareButton 
                        url={"https://stacklive.jp/viewing/"+ streamingInfo.id} 
                        title={streamingInfo.title} 
                        hashtags={['stacklive']}
                        className="flex m-auto hover:opacity-75"
                      >
                        <TwitterIcon size={32} round/>
                      </TwitterShareButton>
                    </div>
                  </li>
              </ul>
          </div>
        </div>
        <div className="md:w-2/3 w-full mx-auto mt-5">
          <span className="bg-white py-3 md:px-6 px-4 md:text-base text-sm inline-block text-blue-500 border-t-2 font-medium border-blue-500">
            詳細
          </span>
        </div>
        <div className="bg-white md:p-10 px-3 py-5 md:w-2/3 md:text-base text-sm w-full md:m-auto text-gray-800 whitespace-pre-line md:mb-10 mb-5 rounded">
          {streamingInfo.summary}
        </div>
      </div>
		</React.Fragment>
	)
}
export default ReserveTemp 
