import { iconSize } from './theme.js';

export default function AnalyticsIcon({size, type, func}){
	const classNames = iconSize[size];
	return(
    <svg className={classNames} onClick={func} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none"></rect>
      <polyline points="224 208 32 208 32 48" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
      <polyline points="224 96 160 152 96 104 32 160" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
    </svg>
	)
}