import React from 'react';
import videojs from 'video.js';
// Styles
import 'video.js/dist/video-js.css';
// amplify
import Amplify, {API, graphqlOperation} from "aws-amplify";
// DB関連
import * as gqlMutations from '../../graphql/mutations'

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    // instantiate video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      return;
    });
    const video = document.querySelector('video');
    // 動画が再生されたら視聴回数をcountUPさせる
    video.addEventListener('play', async(event) => {
      // 視聴回数を増やす
        const countUp = this.props.channel.archiveCount + 1;
        await API.graphql(
            graphqlOperation(gqlMutations.updateStreaming, {
            input: {
                id: this.props.channel.id,
                    archiveCount: countUp 
                }
            })
        )
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }
  render() {  
    return (
      <div data-vjs-player>
        <video ref={node => (this.videoNode = node)} className="video-js md:mt-4 vjs-big-play-centered w-full h-full"/>
      </div>
    );
  }
}