import { Link, useHistory } from 'react-router-dom';
//Atoms
import Div from '../Atoms/div';
import HomeIcon from '../Atoms/homeIcon';
import TrendIcon from '../Atoms/trendIcon';
import NewIcon from '../Atoms/newIcon';
import TrophyIcon from '../Atoms/trophyIcon';
import UsersIcon from '../Atoms/usersIcon';
import ConfigIcon from '../Atoms/configIcon';
import LogoutIcon from '../Atoms/logoutIcon';
import PaymentIcon from '../Atoms/paymentIcon';
import DashboardIcon from '../Atoms/dashboardIcon';
import ArchiveIcon from '../Atoms/archiveIcon';
import Span from '../Atoms/span';
// redux
import {useSelector, useDispatch } from 'react-redux';
import { boolean_sm_menu, boolean_menu } from '../../actions';
// Containers
import UseSignOut from '../../containers/signOut.js';

function List(props){
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
	// ログイン中か確認
	const loginNow = useSelector(state => state.loginNow.bool);
	const dispatch = useDispatch();
	function smMenuToggle(){
		dispatch(boolean_sm_menu());
	}
	function menuToggle(){
		dispatch(boolean_menu());
		dispatch(boolean_sm_menu());
	}
	// サインアウト
	const [ signOut ] = UseSignOut();
	const element = (
			<span className="flex whitespace-nowrap">
				<HomeIcon size="md"/>
				<Span size={props.toggle ? "base" : "hidden" } children="Home"/>
			</span>
	)
	const element0 = (
			<span className="flex whitespace-nowrap">
				<NewIcon size="md"/>
				<Span size={props.toggle ? "base" : "hidden" } children="新着"/>
			</span>
	)
	//　トレンドリスト 
	// const element1 = (
	// 		<span className="flex whitespace-nowrap">
	// 			<TrendIcon size="md"/>
	// 			<Span size={props.toggle ? "base" : "hidden" } children="トレンド"/>
	// 		</span>
	// )
	// アーカイブあとから追加したから8
	const element8 = (
			<span className="flex whitespace-nowrap">
				<ArchiveIcon size="md"/>
				<Span size={props.toggle ? "base" : "hidden" } children="アーカイブ"/>
			</span>
	)
	const element2 = (
			<span className="flex whitespace-nowrap">
				<TrophyIcon size="md"/>
				<Span size={props.toggle ? "base" : "hidden" } children="ランキング"/>
			</span>
	)
	// const element3 = (
	// 		<span className="flex whitespace-nowrap">
	// 			<UsersIcon size="md"/>
	// 			<Span size={props.toggle ? "base" : "hidden" } children="マイページ"/>
	// 		</span>
	// )
	const element4 = (
		<span className="flex whitespace-nowrap">
			<ConfigIcon size="menu"/>
			<Span size={props.toggle ? "base" : "hidden" } children="設定"/>
		</span>
	)
	const element5 = (
		<span className="flex whitespace-nowrap">
			<PaymentIcon size="menu"/>
			<Span size={props.toggle ? "base" : "hidden" } children="StackPoint購入"/>
		</span>
	)
	const element6 = (
		<span className="flex whitespace-nowrap">
			<LogoutIcon size="menu"/>
			<Span size={props.toggle ? "base" : "hidden" } children="ログアウト"/>
		</span>
	)
	const element7 = (
		<span className="flex whitespace-nowrap">
			<DashboardIcon size="menu"/>
			<Span size={props.toggle ? "base" : "hidden" } children="ダッシュボード"/>
		</span>
	)
	return(
		<div className="md:pt-3 border-r-2 border-light-blue-500 h-full">
			<Link to="/">
				<Div size="menu" type="menu" children={element} func={smMenuToggle}/>
			</Link>
			<Link to="/new">
				<Div size="menu" type="menu" children={element0} func={smMenuToggle}/>
			</Link>
			{/* <Link to="/trend">
				<Div size="menu" type="menu" children={element1} func={smMenuToggle}/>
			</Link> */}
			<Link to="/archive">
				<Div size="menu" type="menu" children={element8} func={smMenuToggle}/>
			</Link>
			<Link to="/ranking">
				<Div size="menu" type="menu" children={element2} func={smMenuToggle}/>
			</Link>
			{/* {
				loginNow ?
					<Link to={"/user/" + userInfo.user_id}>
						<Div size="menu" type="pcMenu" children={element3} func={smMenuToggle}/>
					</Link>
				:
					<Link to="/login">
						<Div size="menu" type="pcMenu" children={element3} func={menuToggle}/>
					</Link>
			} */}
			{
				loginNow ?
					<Link to="/payment">
						<Div size="menu" type="menu" children={element5} func={smMenuToggle}/>
					</Link>
				:
					<Link to="/login">
						<Div size="menu" type="menu" children={element5} func={menuToggle}/>
					</Link>
			}
			{
				loginNow == true &&  
					<Link to="/dashboard">
						<Div size="menu" type="smMenu" children={element7} func={smMenuToggle}/>
					</Link>
			}
			{
				loginNow ?
					<Link to={"/user/" + userInfo.user_id + "/edit"}>
						<Div size="menu" type="menu" children={element4} func={smMenuToggle}/>
					</Link>
				:
					<Link to="/login">
						<Div size="menu" type="menu" children={element4} func={menuToggle}/>
					</Link>
			}
			{
				loginNow == true &&  
					<Div size="menu" type="smMenu" children={element6} func={signOut}/>
			}
		</div>
	)
}
export default List
