import React, {useState, useEffect } from 'react';
import { Link, useHistory} from 'react-router-dom';
import '../../style/header.css';
// redux
import {useSelector, useDispatch } from 'react-redux';
import { boolean_sm_menu } from '../../actions';

//Atoms
import SearchIcon from '../Atoms/searchIcon';
// Molecules
import Logo from '../Molecules/logo.js';
import HeaderList from '../Molecules/headerList.js';

function Header(props){
	//ログイン中か確認
	const loginNow = useSelector(state => state.loginNow.bool);
	const userInfo = useSelector(state => state.auth.userInfo);
	const dispatch = useDispatch();
	const sm_menu = useSelector(state => state.smMenu.sm_menu);
	const smMenuToggle = ()=>{
		dispatch(boolean_sm_menu());
	}
	return(
		<header className={"" + (props.dark ? " bg-gray-800 border-gray-600" : " border-gray-200" )}>
			{/* スマホでのハンバーガーメニュー */}
			<div className={"burger burger-squeeze md:hidden rounded-lg z-50 fixed" + (sm_menu ? " open" : "" )} onClick={smMenuToggle}>
				<div className="burger-lines bg-black"></div>
			</div>
			<div className={"w-full px-4 text-gray-700 z-20 fixed top-0 shadow border-b bg-white w-full" + (props.dark ? " bg-gray-800 border-gray-600" : " ")}>
				<div className="flex mx-auto md:items-center md:justify-between">
					<div className="py-4 flex md:justify-between justify-center w-full">
							{/*StackLiveロゴ*/}
							<Link to="/" className="md:m-0">
								<Logo dark={props.dark} size="md"/>
							</Link>
						<ul className="flex justify-end md:w-2/5 absolute right-3">
								{/* 検索窓 */}
								<div className="md:w-3/5 flex items-center md:px-3 text-lg font-semibold text-primary rounded-lg md:mt-0 hover:text-gray-900 focus:text-gray-900  focus:outline-none focus:shadow-outline cursor-pointer">
									<form className="w-full h-full text-gray-600 flex justify-end">
										{/*searchアイコン*/}
										<Link to="/search" className="md:my-auto mt-2">
											<span className="self-center">
												<SearchIcon size="md"/>
											</span>
										</Link>
									</form>
								</div>
							{/*ログイン分岐*/}
							{loginNow ?
								(
									<HeaderList dark={props.dark} userInfo={userInfo}/>
								)
									:
								(<li className="hidden md:flex list-none self-center">
									<Link to="/login">
										<div className="main-background hover:opacity-75 text-white font-bold py-2 px-4 rounded mr-2 text-sm">ログイン</div>
									</Link>
									<Link to="/register">
										<div className="main-background hover:opacity-75 text-white font-bold py-2 px-4 rounded mr-2 text-sm">新規登録</div>
									</Link>
								</li>)
							}
						</ul>
					</div>
				</div>
			</div>
		</header>
	)
}
export default Header;
