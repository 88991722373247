// 法人向けの紹介ページ
import React from 'react';
import Footer from '../Organisms/footer.js';
import LpHeader from '../Organisms/lp_header.js';
// Molecules
import BlackModal from '../Molecules/black-modal';
import SmLeftMenu from '../Organisms/sm-leftMenu.js';
import { Link } from 'react-router-dom';
function About(props){
	return(
		<React.Fragment>
				<BlackModal targetID='modal' />
				<LpHeader />
        <div className="pt-8 bg-blue-50">
					{/* SMレフトメニュー */}
					<SmLeftMenu/>
            <div id="top" className="container md:flex md:mt-52 md:mb-44 my-32 mx-auto h-96">
              <div className="md:w-1/2 w-11/12 mx-auto my-auto about_color">
                <h1 className="md:text-4xl text-3xl md:font-bold font-black text-center">
                  <div className="">
                    <span className="text-blue-600 text-5xl">「技術力」</span>で<span className="inline-block">エンジニアと繋がる</span>
                    <span className="inline-block mt-5 md:block hidden">ライブ配信プラットフォーム</span>
                  </div>
                  {/* <div className="md:hidden block">
                    <span className="text-blue-600 text-4xl">エンジニア</span>向け<span className="inline-block mt-5">ライブ配信プラットフォーム</span>
                  </div> */}
                </h1>
                <p className="text-center font-bold my-5 text-base">
                  StackLiveならたくさんのエンジニアにアプローチすることができます。
                </p>
                  <Link to='/register' className="inline-block hidden md:flex place-content-center">
                    <p className="about-background hover:opacity-75 font-bold py-4 px-16 rounded-full text-lg text-white">
                      無料ではじめる ▶ 
                    </p>
                  </Link>
              </div>
              <div className="md:w-1/2 w-full">
                <img src="/images/pc_phone.png" className="mx-auto md:h-full h-56"/>
              </div>
              {/* スマホボタン */}
              <Link to='/register' className="md:hidden inline-block flex place-content-center mt-8">
                <p className="about-background hover:opacity-75 font-bold py-4 px-16 rounded-full text-lg text-white">
                  無料ではじめる ▶ 
                </p>
              </Link>
            </div> 
            {/* StackLiveとは?*/}
            <div id="about" className="bg-white w-full md:py-24 py-16">
                <div className="font-bold md:text-2xl text-xl text-center text-blue-500">ABOUT</div>
                <div className="font-bold md:text-4xl text-3xl text-center mt-3">StackLiveとは？</div>
                <div className="place-content-center md:w-10/12 m-auto md:mt-16 mt-10">
                    <div className="md:text-4xl text-2xl my-auto font-bold line-height3 text-center w-10/12 mx-auto">
                        企業がライブ配信をして
                        <p>優秀なエンジニアと繋がれるサービスです</p>
                    </div>
                    <div className="flex justify-center md:h-96 h-48 md:w-3/5 mt-8 w-full mx-auto">
                      <div className="h-full relative w-1/2">
                          <img src="/images/javascript-coding-live.png" className="md:h-96 h-36 mx-auto"/>
                          <p className="text-center absolute-center-bottom font-bold text-gray-800">企業</p>
                      </div>
                      <div className="my-auto mx-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" className="my-auto md:mx-auto md:mx-6 mx-0 md:h-12 h-8 icon icon-tabler icon-tabler-arrow-big-right" width="44" height="44" viewBox="0 0 24 24" strokeWidth="0" stroke="#1e7198" fill="#1e7198" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <path d="M4 9h8v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="my-auto md:mx-auto md:h-12 h-8 icon icon-tabler icon-tabler-arrow-big-left" width="44" height="44" viewBox="0 0 24 24" stroke-width="0" stroke="#1e7198" fill="#1e7198" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <path d="M20 15h-8v3.586a1 1 0 0 1 -1.707 .707l-6.586 -6.586a1 1 0 0 1 0 -1.414l6.586 -6.586a1 1 0 0 1 1.707 .707v3.586h8a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1z" />
                        </svg>
                      </div>
                      <div className="h-full relative  w-1/2">
                          <img src="/images/businessman.svg" className="md:mt-16 mt-8 md:h-52 h-20 mx-auto"/>
                          <p className="text-center absolute-center-bottom font-bold text-gray-800 text-sm">エンジニア</p>
                      </div>
                    </div>
                </div>
            </div>
            <div className="w-full">
              <div className="w-full">
                <div className="md:flex md:mx-10 mx-0 md:py-28 py-14">
                  <div className="md:w-10/12 w-full p-3 h-auto about_color mx-auto">
                    <div className="font-bold md:text-5xl text-2xl text-center">エンジニア採用で</div>
                    <h1 className="font-bold md:text-5xl text-2xl text-center">こんな<span className="text-5xl text-blue-600">お悩み</span>ありませんか？</h1>
                    <ul className="mt-16 md:flex">
                      <li className="md:w-1/3 md:mx-4 mx-2 p-4 bg-white rounded-lg">
                        <h2 className="text-blue-600 font-bold text-2xl text-center">優秀なエンジニアと出会えない</h2>
                        <img src="/images/worried-1.png" className="mx-auto h-60"/>
                        <div className="about_color font-medium text-center">転職サイトやエージェントでは優秀なエンジニアに出会える機会が少ない</div>
                      </li>
                      <li className="md:w-1/3 md:mx-4 mx-2 p-4 bg-white rounded-lg">
                        <h2 className="text-blue-600 font-bold text-2xl text-center">求めている人材とのミスマッチが多い</h2>
                        <img src="/images/worried-2.png" className="mx-auto h-60"/>
                        <div className="about_color font-medium text-center">テキストだけでは100%求めている人材像を伝えきれずに採用のミスマッチが続いている</div>
                      </li>
                      <li className="md:w-1/3 md:mx-4 mx-2 p-4 bg-white rounded-lg">
                        <h2 className="text-blue-600 font-bold text-2xl text-center">自社の強みを上手くアピールできない</h2>
                        <img src="/images/worried-3.png" className="mx-auto h-60"/>
                        <div className="about_color font-medium text-center">テキストだけでは自社の技術力や雰囲気を伝えきれずに採用に活かしきれていない</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* StackLiveが選ばれる理由 */}
            <div className="w-full mx-auto py-20 bg-white">
              <div className="font-bold md:text-2xl text-xl text-center text-blue-500">REASONS</div>
              <div className="font-bold md:text-4xl text-2xl text-center about_color mt-3">StackLiveが選ばれる理由</div>
              <div className="w-10/12 mx-auto">
                <div className="md:flex md:mx-10 mx-0 py-14">
                  <div className="md:w-1/2 w-full p-3 h-auto about_color m-auto shadow-lg rounded-lg relative border border-gray-100">
                    <span className="text-blue-600 md:text-8xl text-6xl font-bold absolute md:-top-12 -top-8">01</span>
                    <p className="text-gray-600 mb-3 text-center pt-5">Deliver efficiently</p>
                    <h1 className="font-bold md:text-3xl text-2xl text-center">エンジニア限定だから効率的にリーチが可能</h1>
                    <p className="text-gray-600 md:text-base text-sm my-5 md:px-8 px-2">
                      月間10,000人以上エンジニアが集まるプラットフォームで自社をアピールすることが可能です。
                      普段やっているYouTube Liveやその他サービスよりも効率的にエンジニアに届けることができます。
                    </p>
                    <div className="md:hidden block md:w-1/2 w-full">
                      <img src="/images/live_pc_2.png" className="inline-block"/>
                    </div>
                  </div>
                  <div className="md:block hidden md:w-1/2 w-full">
                    <img src="/images/live_pc_2.png" className="inline-block"/>
                  </div>
                </div>
                <div className="md:flex md:mx-10 mx-0 md:py-8 py-14">
                  <div className="md:block hidden md:w-1/2 w-full text-center">
                    <img src="/images/phone_check_button.png" className="inline-block h-100"/>
                  </div>
                  <div className="md:w-1/2 w-full p-3 h-auto about_color m-auto shadow-lg rounded-lg relative border border-gray-100">
                    <span className="text-blue-600 md:text-8xl text-6xl font-bold absolute md:-top-12 -top-8">02</span>
                    <p className="text-gray-600 mb-3 text-center pt-5">Appeal Strength</p>
                    <h1 className="font-bold md:text-3xl text-2xl text-center">ライブ配信で自社の強みをアピール</h1>
                    <p className="text-gray-600 md:text-base text-sm my-5 md:px-8 px-2">
                      技術イベントやライブコーディングを配信し自社の強みや雰囲気をリアルタイムで伝えることができます。
                      リアルタイムにコミュニケーションをとり、テキストや動画では伝えきれない魅力を伝えることができます。
                    </p>
                    <div className="md:hidden block md:w-1/2 w-full text-center">
                      <img src="/images/phone_check_button.png" className="inline-block h-72"/>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:mx-10 mx-0 md:py-8 py-14">
                  <div className="md:w-1/2 w-full p-3 h-auto about_color m-auto shadow-lg rounded-lg relative border border-gray-100">
                    <span className="text-blue-600 md:text-8xl text-6xl font-bold absolute md:-top-12 -top-8">03</span>
                    <p className="text-gray-600 mb-3 text-center">Free Price</p>
                    <h1 className="font-bold md:text-3xl text-2xl text-center">完全無料で配信が可能</h1>
                    <p className="text-gray-600 md:text-base text-sm my-5 md:px-8 px-2">
                      転職サイトやエージェントよりも圧倒的に低価格でエンジニアにリーチすることができます。
                      StackLiveから応募が入っても、紹介手数料などを請求することはありません。
                    </p>
                    <div className="md:hidden block md:w-1/2 w-full">
                      <img src="/images/live_pc_2.png" className="inline-block"/>
                    </div>
                  </div>
                  <div className="md:block hidden md:w-1/2 w-full">
                    <img src="/images/live_pc_2.png" className="inline-block"/>
                  </div>
                </div>
              </div>
              <Link to='/register' className="inline-block flex place-content-center">
                <p className="about-background hover:opacity-75 font-bold py-4 px-16 rounded-full text-lg text-white">
                  無料ではじめる ▶ 
                </p>
              </Link>
            </div>
          <div className="mt-10">
            <div className="md:pt-28 w-11/12 mx-auto">
              <div className="font-bold md:text-2xl text-xl text-center text-blue-500">SIMPLY</div>
              <div className="font-bold md:text-4xl text-2xl text-center about_color mt-3">2ステップで簡単<span className="inline-block">ライブ配信</span></div>
              <p className="text-gray-600 w-4/5 mx-auto mt-4 md:text-center text-xl">ライブ配信には別のソフトを用意していただく必要はございません。<br className="md:block hidden"/>2ステップでいつでも簡単にライブ配信を行うことができます。
              </p>
            </div>
            <div className="flex justify-center">
              <div className="my-10">
                <span className="circle_background text-white mx-auto rounded-full md:h-10 md:w-10 h-8 w-8 flex items-center justify-center my-10">1</span>
                <img src="/images/iphone.png" className="mx-auto md:h-48 h-32"/>
                <p className="text-gray-800 font-bold text-center my-5">会員登録</p>
              </div>
                <svg xmlns="http://www.w3.org/2000/svg" className="my-auto md:mx-16 mx-6 icon icon-tabler icon-tabler-arrow-big-right" width="44" height="44" viewBox="0 0 24 24" strokeWidth="0" stroke="#1e7198" fill="#1e7198" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M4 9h8v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1z" />
                </svg>
              <div className="my-10">
                <span className="circle_background text-white mx-auto rounded-full md:h-10 md:w-10 h-8 w-8 flex items-center justify-center my-10">2</span>
                <img src="/images/human.png" className="mx-auto md:h-48 h-32"/>
                <p className="text-gray-800 font-bold text-center my-5">配信</p>
              </div>
            </div>
            {/* 料金プラン */}
            <div id="price" className="bg-white md:py-28 pt-12 pb-8">
                <div className="text-center font-semibold">
                    <h1 className="md:text-5xl text-3xl">
                        <span className="text-blue-500 tracking-wide">ご利用料金</span>
                    </h1>
                    <p className="pt-6 text-xl text-gray-500 font-normal w-full px-8 md:w-full">
                      0円でサービスの利用が可能です。<br/>月々制限なくライブ配信をすることができます。
                    </p>
                </div>
                <div className="md:pt-24 mb-8 mx-5 md:flex flex-row place-content-center">
                    <img src="/images/説明する女性.svg" className="mx-24 mt-auto md:h-48 h-32 md:block hidden"/>
                    <div className="md:w-96 md:px-8 md:pt-16 md:pb-8 md:mt-0 mt-10 px-3 pb-3 pt-8 bg-gray-800 drop-shadow-2xl text-center rounded-3xl text-white transform ">
                        <h1 className="text-white font-semibold md:text-3xl text-2xl">スタンダードプラン</h1>
                        <p className="pt-2 tracking-wide">
                            <span className="text-4xl font-semibold">0円</span>
                            <span className="text-gray-400 font-medium">/月額</span>
                        </p>
                        <div className="pt-8">
                            <p className="text-xl font-bold text-gray-400 text-center pt-5">
                                <span className="pl-2">
                                  ライブ配信<span className="text-white pl-3">無制限</span>
                                </span>
                            </p>
                            <Link to='/register'>
                              <p className="font-bold w-full py-4 bg-yellow-500 mt-8 rounded-xl text-white hover:opacity-75">
                                無料ではじめる ＞ 
                              </p>
                            </Link>
                        </div>
                    </div>
                      <img src="/images/説明する男性.svg" className="md:mx-24 mt-auto md:h-48 h-32 md:block hidden"/>
                </div>
            </div>
          </div>
          {/* よくある質問 */}
          <div id="faq" className="py-24 bg-white">
            <h1 className="md:text-4xl text-2xl font-bold about_color mb-4 text-center">よくある質問</h1>
            <ul className="py-2 flex md:justify-around overflow-scroll overflow-auto hash_tag">
              <li className="md:w-1/6 md:mx-0 mx-2 p-2 py-4 about_background rounded-lg">
                <p className="font-bold py-2 md:w-auto w-52">
                  <span className="text-main text-2xl mr-2">Q</span>
                  StackLiveとはなんですか？
                </p>
                <p className="text-sm text-gray-600">StackLiveはエンジニア向けのライブ配信サービスです。
                <br/>画面共有をしながら知見を共有しコミュニケーションをとる事ができます。
                </p>
              </li>
              <li className="md:w-1/6 md:mx-0 mx-2 p-2 py-4 about_background rounded-lg">
                <p className="font-bold py-2 md:w-auto w-52">
                  <span className="text-main text-2xl mr-2">Q</span>
                  どんな使い方ができますか？
                </p>
                <p className="text-sm text-gray-600">あくまで一例ではありますが、このような使い方をしていただいています。
                <br/>・エンジニアイベントやライブコーディング。
                <br/>・採用募集配信
                </p>
              </li>
              <li className="md:w-1/6 md:mx-0 mx-2 p-2 py-4 about_background rounded-lg">
                <p className="font-bold py-2 md:w-auto w-52">
                  <span className="text-main text-2xl mr-2">Q</span>
                  投げ銭はできますか？
                </p>
                <p className="text-sm text-gray-600">
                  メッセージ付きギフトとノーマルギフトを送ることができます。
                  <br/>
                  ギフトの種類には1pt〜10,000ptまで種類豊富に揃えております。
                </p>
              </li>
              <li className="md:w-1/6 md:mx-0 mx-2 p-2 py-4 about_background rounded-lg">
                <p className="font-bold py-2  md:w-auto w-52">
                  <span className="text-main text-2xl mr-2">Q</span>
                  LIVE配信をする為に用意するものはありますか？
                </p>
                <p className="text-sm text-gray-600">
                  現時点では内蔵マイクや別付けのマイクの環境が必要です。
                  <br/> 今後ミュート機能を対応予定です。
                </p>
              </li>
              <li className="md:w-1/6 md:mx-0 mx-2 p-2 py-4 about_background rounded-lg">
                <p className="font-bold py-2 md:w-auto w-52">
                  <span className="text-main text-2xl mr-2">Q</span>
                  LIVE配信ができる動作環境はなんですか？
                </p>
                <p className="text-sm text-gray-600">現時点で動作する環境は以下になります。
                <br/>・Google Chrome58以降(水準)
                <br/>・Firefox56以降
                <br/>・Edge Browser80以降
                <br/>・Opera45以降
                </p>
              </li>
            </ul>
          </div>
        </div>
			<Footer/>
		</React.Fragment>
    )
}
export default About

