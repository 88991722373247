export const  spanSize ={
	md: 'text-2xl my-auto mx-0',
	base: 'text-base my-auto mx-0 ml-5',
	hidden: "hidden",
	lx:'text-3xl my-auto',
	footer: "md:text-3xl text-2xl my-auto mx-0"
}
export const spanType = {
    basic: "tracking-normal font-bold",
		dark: "tracking-normal text-white font-bold"
};
export const spanStyle = {
	main: {
		'fontFamily': "var(--font-base)"
	}
}
