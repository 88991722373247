import React, { useEffect, useRef, useState,Suspense} from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import {useHistory} from 'react-router-dom';
// Pages
import Chat from '../Pages/Chat.js';
import Controller from '../Organisms/controller.js';
import Summary from '../Organisms/summary.js';

// Molecules
import PopUp from '../Molecules/popUp.js';
import QrModal from '../Molecules/qr-modal';
import LiveStartModal from '../Molecules/livestart_modal';
import WaitModal from '../Molecules/wait_modal';
import ThanksModal from '../Molecules/thanks_modal';
import YesNoModal from '../Molecules/yes_no_modal';

// Containers
import UseToggle from '../../containers/toggle.js';
import UseAgoraFunction from '../../containers/agoraFunction.js';
// redux
import { useSelector, useDispatch } from 'react-redux';
// AppSync
import API, { graphqlOperation } from '@aws-amplify/api';
// DB関連
import * as gqlQueries from '../../graphql/queries'
import * as gqlMutations from '../../graphql/mutations'
import { useParams, Prompt } from "react-router";
import QRCode from "qrcode.react";


function StreamingTemp(props){
    const streamingInfo = props.resource.read().data.getStreaming;
	const divEl = useRef(null);
    const channelId = useParams().channelId;
    const inputRef = React.useRef();
	const [token, setToken] = useState('');
	const [uid, setUid] = useState('');
	const [client, setClient] = useState('');
	const [audioTrack, setAudio] = useState('');
	const [agora_sid, setSid] = useState('');
	const [agora_resourceId, setResourceId] = useState('');
	const [archive_file, setArchive] = useState('');
	const [nowArchive, setNowArchive] = useState(false);
	const [videoTrack, setVideo] = useState([]);
	const [nowStreaming, setStreaming] = useState(false);
	const [liveconfig, switchConfig] = UseToggle(false);
	const [ popUp, popUpToggle ] = UseToggle(false);
    const [ leftmenu, menuToggle ] = UseToggle(true);
	const [is_mute, setMute] = useState(false);
	const [is_play, setPlay] = useState(true);
    const dispatch = useDispatch();
    const [ qrBool, qrToggle ] = UseToggle(false);
    const [ waitBool, setWait ] = useState(false);
    const [ successBool, switchSuccess ] = UseToggle(false);
    const [ yesNoBool, switchYesNo ] = UseToggle(false);
    let [viewerCount, setViewerCount] = useState(streamingInfo.viewerCount);
    // ライブ配信を終了させる処理
	const [ agoraStop, agoraRecording] = UseAgoraFunction();
	const history = useHistory();
    // QRコード
    const element = (
        <QRCode value={new URL("/chat/"+channelId, window.location.href).href} size={120} className="m-auto"/>
    )
	// ログイン中のユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);

    // ライブ配信中にページ内移動をしたらライブ配信を終了させる
    const navgation = async()=>{
        // 初回にブラウザの履歴を消す
        window.history.pushState(null, null, null);
        // ブラウザの戻ると進むボタンを押した時にライブ配信中の場合は遷移させずにライブ配信を終了させるようにアラートを出す
        window.addEventListener('popstate', async(e) => {
            let nowLive = await API.graphql(graphqlOperation(gqlQueries.getStreaming, { id: channelId }))
            // 配信中だったら
            if(nowLive.data.getStreaming.is_live){
                alert('ライブ配信中です。ページを遷移する場合はライブ配信を終了させてください。');
                // 毎回ブラウザの履歴を削除する
                window.history.pushState(null, null, null);
                return
            }else{
			    // homeにリダイレクト
                history.push("/");
            }
        },[]);
    }

	useEffect(()=>{
        // Agoraのクライアントを定義する
        const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        setClient(client);
        (async function getStreaming(){
            const agoraInfo = await API.graphql(graphqlOperation(gqlQueries.getAgoraToken, { channel: channelId }));
            setToken(agoraInfo.data.getAgoraToken.token);
            //誰かがライブ配信を見に来た 
            await client.on("user-joined", (user,reason) => {
                // 視聴者をインクリメント
                viewerCount = viewerCount + 1;
                setViewerCount(viewerCount);
            })
            //ライブ配信が終了された処理
            await client.on("user-left", (user,reason) => {
                // 視聴者をデクリメント
                viewerCount = viewerCount - 1;
                setViewerCount(viewerCount);
            })
        })();
        // ログインしているユーザーとライブ配信をしているユーザーが違えばリダイレクト
        if(userInfo.id != streamingInfo.user_id){
            // 視聴ページへリダイレクト
            history.push('/viewing/' + streamingInfo.id);
        }
        // 動的にチャットの高さを取得
        const view_height =  document.documentElement.clientHeight;
        document.getElementById('pc_chat_height').style.height = (view_height) + 'px';
    },[])
    
    // ブラウザの画面共有をクリックして終了した時のイベントを検知する
	useEffect(()=>{
        // videoTrackが配列か判定
        if(!Array.isArray(videoTrack)){
            // イベントを検知
            videoTrack.on("track-ended", async() => {
                const archive_data = await agoraStop(client,audioTrack,videoTrack,channelId, agora_resourceId, agora_sid);
                // アーカイブ配信をしていたら
                if(agora_resourceId){
                    let filename = archive_data.filename; 
                    setArchive(filename);
                }
                setStreaming(false);
                const element =  document.getElementById(uid);
                element.remove()
                setNowArchive(false);
                // 成功モーダルを表示
                switchSuccess();
            });
        }
    },[videoTrack])

    // 画面共有を開始する
    const startStream  = async ()=>{
        const check_browser  = await AgoraRTC.checkSystemRequirements();
        // ブラウザをチェック
        if(check_browser){
            await AgoraRTC.getDevices().then(devices=>{
                (async function (){
                    const uid = await client.join(
                    '927dcdc2edac4016b94439051dfe8c88',
                    channelId,
                    token,
                    null);
                    try{
                        // マイクを取得する
                        const audioInput = await AgoraRTC.getMicrophones();
                        // マイクの有無を確認
                        if(!audioInput.length){
                            alert("マイクが確認できないため、ミュートでの配信となります");
                        }
                        // 画面共有トラックを確認
                        const videoTrack = await AgoraRTC.createScreenVideoTrack();
                        // マイクがあれば音声ありで配信
                        if(audioInput.length){
                            const AudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
                            await client.publish([AudioTrack]);
                            setAudio(AudioTrack);
                            setMute(false);
                        }else{
                            setMute(true);
                        }
                        setStreaming(true)
                        const playerContainer = document.createElement("div");
                        playerContainer.classList.add(
                            'h-full', 
                            'bg-black',
                            'bg-opacity-50',
                            'rounded',
                            'md:mt-4',
                            'mt-0',
                            'text-white',
                            'text-center',
                            'md:text-2xl',
                            'text-xl',
                            'font-bold',
                            'flex',
                        );
                        let p1 = document.createElement('p');
                        p1.innerHTML = 'ライブ配信中です'
                        let content_dom = document.createElement('div');
                        content_dom.classList.add('m-auto');
                        content_dom.appendChild(p1);
                        playerContainer.id = uid;
                        let parent = document.getElementById("play_video");
                        playerContainer.appendChild(content_dom);
                        parent.appendChild(playerContainer);
                        // 画面共有をローカルで再生する
                        // videoTrack.play(playerContainer);
                        // ライブ配信を公開する
                        await client.publish([videoTrack]);
                        setUid(uid);
                        setVideo(videoTrack);
                        setPlay(true);
                        API.graphql(
                            graphqlOperation(gqlMutations.updateStreaming, {
                            input: {
                                id: channelId,
                                is_live: 1
                            }
                            })
                        )
                        // アーカイブに残すにチェックが入っていたら録画を開始する
                        if(inputRef.current.checked){
                            const result = agoraRecording(token, channelId);
                            result.then(({data: {agoraRecording :{resourceId, sid, error}}})=>{
                                if(error){
                                    alert('録画にエラーが起こりました。時間をおいて再度お試しくださいませ。')
                                }
                                setResourceId(resourceId);
                                setSid(sid);
                                setNowArchive(true);
                            }).catch((err)=>{
                                console.log(err);
                            })
                        }
                        // 予約配信を設定していたら視聴予約しているユーザーに通知をさせる
                        if(streamingInfo.reserve){
                            const reserveLists = API.graphql(graphqlOperation(gqlQueries.listReserves,{filter:{streaming_id: {eq: streamingInfo.id}}}));
                            reserveLists.then((result)=>{
                                result.data.listReserves.items.map((list)=>{
                                    try{
                                        API.graphql(graphqlOperation(gqlMutations.createNotices,{
                                            input:{
                                                receive_user: list.user_id,
                                                send_user: userInfo.id,
                                                contents: 'LIVE_START', 
                                                streaming_id: streamingInfo.id,
                                                flag: 0  

                                            }
                                        }))
                                    }catch(e){
                                        console.log(e);
                                    }
                                })
                            }).catch((e)=>{
                                console.log(e);
                            })
                            // 予約配信を終了させる
                            API.graphql(
                                graphqlOperation(gqlMutations.updateStreaming, {
                                input: {
                                    id: channelId,
                                    reserve: 0
                                }
                                })
                            )
                        }
                        // コンフィグモーダルを閉じる
                        switchConfig();
                        // IFTTTで自分にライブ配信されたことをpush通知する
                        API.graphql(graphqlOperation(gqlQueries.pushIfttt));
                    }catch(e){
                        // 配信エラーの処理
                        console.log(e);
                        alert('デバイスが確認できません。ブラウザのバージョンやマイクを確認ください')
                        client.leave();
                    }
                }())
            }).catch(e => {
                alert('デバイスが確認できません。ブラウザのバージョンやマイクを確認ください')
            });
            }else{
                alert('ブラウザがサポートされていません。')
            }
    }
    // 終了する
    const stopStream  = async ()=>{
        switchYesNo();
        setWait(true);
        const archive_data = await agoraStop(client,audioTrack,videoTrack,channelId, agora_resourceId, agora_sid);
        // アーカイブ配信をしていたら
        if(agora_resourceId){
            let filename = archive_data.filename; 
            setArchive(filename);
        }
        setStreaming(false);
        const element =  document.getElementById(uid);
        element.remove()
        setWait(false);
        setNowArchive(false);
        // 成功モーダルを表示
        switchSuccess();
    }
    // 音声をミュートさせる
    const audioMute = async()=>{
        await client.unpublish(audioTrack); 
        setMute(true);
    }
    // ミュートを解除する
    const releaseMute = async()=>{
        await client.publish(audioTrack); 
        setMute(false);
    }

    //　一時停止再開 
    const playVideo = async()=>{
        if(nowStreaming){
            await client.publish(videoTrack); 
            setPlay(true);
            //マイクも再開する
            if(audioTrack){
                await client.publish(audioTrack); 
                setMute(false);
            }
            //配信中ですにかえる 
            let event_dom = document.getElementById(uid);
            let children_dom = event_dom.querySelector('p');
            if(children_dom != null){
                children_dom.textContent = "ライブ配信中です";
            }
        }
    }
    //一時停止 
    const releaseVideo = async()=>{
        if(nowStreaming){
            await client.unpublish(videoTrack); 
            setPlay(false);
            // マイクが入っていいたらミュートにする
            if(audioTrack){
                await client.unpublish(audioTrack); 
                setMute(true);
            }
            //非表示中ですと文言を変える 
            let event_dom = document.getElementById(uid);
            let children_dom = event_dom.querySelector('p');
            if(children_dom != null){
                children_dom.textContent = "一時停止中です";
            }
        }
    }
	return(
		<React.Fragment>
            {/* promptを使いページ遷移を検知している */}
            <Prompt
                when={true}
                message={navgation}
            />
			<QrModal targetID='modal' modalToggle={qrToggle} toggle={qrBool} element={element}/>
            {/* ライブ配信を開始するモーダル */}
			<LiveStartModal targetID='modal' modalToggle={switchConfig} toggle={liveconfig} startStream={startStream} inputRef={inputRef}/>
            {/* 終了確認モーダル */}
			<YesNoModal targetID='modal' modalToggle={switchYesNo} toggle={yesNoBool} func={stopStream} children='配信を終了します。よろしいですか？' />
            {/* 処理中モーダル */}
			<WaitModal targetID='modal' toggle={waitBool} children='終了処理中です。少々お待ちください...'/>
            {/* Thanksモーダル */}
			<ThanksModal targetID='modal' toggle={successBool} modalToggle={switchSuccess} archive={archive_file} user_id={userInfo.user_id} channelId={channelId} title={streamingInfo.title}/>
			<div className="flex bg-gray-700 pt-16">
				{/* <LiveMenu popUp={popUpToggle} channelId={channelId} start={startStream} stop={stopStream} menu={ leftmenu } toggle={menuToggle} nowStreaming={nowStreaming}/> */}
                <div className="w-9/12 px-4 relative">
                    {/* アーカイブ中のアイコン */}
                    {
                        nowArchive == true &&
                        <div className="flex absolute top-5 left-5 z-10">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#f80d0d" viewBox="0 0 256 256">
                                <rect width="256" height="256" fill="none"></rect>
                                    <g>
                                        <path d="M128,24A104,104,0,1,0,232,128,104.11791,104.11791,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.09957,88.09957,0,0,1,128,216Z"></path>
                                        <circle cx="128" cy="128" r="72"></circle>
                                    </g>
                            </svg>
                            <spna className="text-white font-bold my-auto mx-1">アーカイブ中</spna>
                        </div>
                    }
                    <div ref={divEl} className="mt-4" id="play_video" style={{height: "calc(100vh * 3/4)"}}>
                        {
                            nowStreaming == false &&
                            <React.Fragment>
                                <div className="bg-gray-500 h-full relative">
                                    <img src="/images/loading.png" className="transform-50 absolute top-1/2 left-1/2 h-10"/>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <Controller 
                        nowStreaming={nowStreaming}
                        startStream={switchConfig}
                        stopStream={switchYesNo}
                        is_mute={is_mute}
                        releaseMute={releaseMute}
                        audioMute={audioMute}
                        is_play={is_play}
                        releaseVideo={releaseVideo}
                        playVideo={playVideo}
                        channelId={channelId}
                        popUp={popUpToggle}
                    />
                    {/* 概要欄 */}
                    <Suspense fallback={<React.Fragment></React.Fragment>}>
                        <Summary resource={props.resource} channelId={channelId} dark={true}/>
                    </Suspense>
                </div>
                <div className="w-3/12 pb-20" id="pc_chat_height">
                    <Chat qr={true} dark={true} toggle={qrToggle} viewerCount={viewerCount}/>
                </div>
			</div>
			<div className={'' + (popUp ? " opacity-100" : " opacity-0")}>
				<PopUp text="クリップボードにコピーしました"/>
			</div>
		</React.Fragment>
	)
}
export default StreamingTemp
