import React from "react";
//Atoms
import Img from '../Atoms/img';
import { Link } from 'react-router-dom';

function Content(props){
	const tec_data =  props.channel.tec;
	delete tec_data['id'];
	delete tec_data['createdAt'];
	delete tec_data['updatedAt'];
  // 連想配列名を取得する
	let arrays = Object.keys(tec_data); 
	let new_arrays = ['StackLive'];

  // 配列のnull以外の値で新しい配列を作成する
  arrays.map(object => {
    if(tec_data[object] != null){
			new_arrays.unshift(tec_data[object]);
    }
	})
	return(
			<React.Fragment >
					<div className="2xl:w-1/5 md:w-1/4 w-11/12 mx-auto md:px-2 px-1 mb-5 ">
						<div className="rounded-lg shadow-2xl">
						<Link to={"/viewing/" +props.channel.id} className="  tracking-wide">
						<div className="md:flex-shrink-0 relative hover:opacity-75">
							{
								props.channel.is_paid ? 
									<span className="flex text-white font-bold absolute bg-yellow-500 rounded text-xs top-2 left-2 p-1">
										有料配信 ¥{props.channel.price}
									</span>
								:
									<span className="flex text-white font-bold absolute bg-red-600  rounded text-xs top-2 left-2 p-1">
										ライブ配信
									</span>
							}
							<div className="absolute right-2 bg-gray-50 opacity-75 top-2 p-1 text-gray-600 rounded flex items-center ">
								<svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" /></svg>
								<p className="text-xs tracking-tighter">
									{props.channel.viewerCount}
								</p>
							</div>
							{											
								props.channel.thumbnail != null &&
								<Img size="thumbnail" src={props.channel.thumbnail} alt="thumbnail" />
							}
							{											
								props.channel.thumbnail == null &&
								<img src="/images/home-logo3.png" className="object-cover w-full md:h-48 rounded-lg rounded-b-none rounded-lg shadow-lg antialiased cursor-pointer" alt="thumbnail"/>
							}
							<span className="flex text-gray-600 absolute bg-gray-50 opacity-75 rounded text-xs bottom-2 right-2 p-1">
								<div className="user-logo">
									{/*プロフィール画像*/}
									{props.channel.user.icon ?
										<Img size="content" src={props.channel.user.icon} />
											:
										<img src="/images/smiley-sticker.svg" className="rounded-full mr-1 h-4 w-auto"/>
									}
								</div>
								<span className="my-auto">{props.channel.user.name}</span> 
							</span>
						</div>
					</Link>
					{/* ハッシュタグ */}
						<div className="flex mt-1 overflow-scroll hash_tag">
							{
								new_arrays.map((tech,index)=>
									<div className="justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-blue-700 bg-blue-100 border border-blue-300" key={index}>
										<div className="text-xs font-normal leading-none max-w-full flex-initial">#{tech}</div>
									</div>
								)
							}
						</div>
						<div className="h-28 px-4 pt-1 pb-2 mt-1">
							<h2 className="max-h-12 overflow-clip overflow-hidden font-bold text-base text-gray-800 tracking-normal">
								{props.channel.title}
							</h2>
                <p className="max-h-12 text-xs mt-1 break-words overflow-ellipsis overflow-hidden text-gray-700">
									{props.channel.summary}
                </p>
						</div>
				</div>
    </div>
</React.Fragment>
	)
}
export default Content
