import React, {Suspense,useMemo} from 'react';
import StreamingTemp from '../Templates/StreamingTemp.js';
import Footer from '../Organisms/footer.js';
import { useParams } from "react-router";
// Organisms
import Header from '../Organisms/Header.js';
// amplify
import Amplify, { API, graphqlOperation} from "aws-amplify";

import UseFetchResource from '../../containers/fetchResource.js';
// DB関連
import * as gqlQueries from '../../graphql/queries'


function Livestreaming(props){
  // パラメータから現在アクセスしているチャンネルを取得
  let { channelId } = useParams();
	const [wrapPromise] = UseFetchResource();
  const streamingInfo = API.graphql(graphqlOperation(gqlQueries.getStreaming, { id: channelId }))
  const streamingResource = wrapPromise(streamingInfo)

	return(
		<React.Fragment>
			<Header dark='true'/>
      <Suspense fallback={<div className="bg-gray-700 min-h-screen pt-16"></div>}>
        <StreamingTemp  resource={streamingResource}/>
      </Suspense>
			<Footer/>
		</React.Fragment>
	)
}
export default Livestreaming
