import React from 'react';
import LiveTemp from '../Templates/LiveTemp.js';
// redux
import { useSelector} from 'react-redux';

function Liveconfig(props){
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
	return(
		<React.Fragment>
			<LiveTemp userInfo={userInfo}/>
		</React.Fragment>
	)
}
export default Liveconfig
