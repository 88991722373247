import React, {useState, useEffect } from 'react';
import Amplify, { API, graphqlOperation } from "aws-amplify";
import {Link} from 'react-router-dom';
import Header from '../Organisms/Header.js';
import Footer from '../Organisms/footer.js';
import BlackModal from '../Molecules/black-modal';
import LeftMenu from '../Organisms/LeftMenu.js';
import SmLeftMenu from '../Organisms/sm-leftMenu.js';

// Molecules
import SuccessPopup from '../Molecules/success-popup.js';
// redux
import {useDispatch ,useSelector} from 'react-redux';
import '../../style/setting.css';
import 'nprogress/nprogress.css'
import Spinner from '../Atoms/spinner';
import UseToggle from '../../containers/toggle.js';

function Dashboard(){
	const [ is_loading, setLoading ] = useState(false);
	const [ enabledPayout, setEnabled ] = useState(true);
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
  let receive_point = '';
  if(userInfo.receive_point){
    // receive_point = Math.round(userInfo.receive_point * 0.6);
    receive_point = Math.round(userInfo.receive_point);
  }else{
    receive_point = 0;
  }
	//  successモーダルのトグル
	const [now_success, successView] = UseToggle(false);

	// 申請金額が足りているか確認
	useEffect(()=>{
		if(receive_point < 3350){
			setEnabled(false);
		}
	},[]);
	return(
			<React.Fragment>
			<BlackModal targetID='modal' />
			<Header/>
			<div className="flex min-h-screen pt-16">
				{/* PCレフトメニュー */}
				<LeftMenu/>
				{/* SMレフトメニュー */}
				<SmLeftMenu/>
				<div className="md:w-3/6 w-full md:mx-auto md:mt-20 md:mt-10 mt-0 h-full md:border-solid md:border md:border-gray-200 md:shadow-md rounded">
					<div className="h-auto">
						<h2 className="text-center font-semibold text-2xl md:my-8 mt-8 mb-4">ダッシュボード</h2>
						<div className="w-11/12 m-auto">
							<nav className="flex flex-row overflow-x-auto whitespace-nowrap hash_tag">
                <Link to="/dashboard" className="w-1/2 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-500 focus:outline-none text-blue-600 border-b-2 font-medium border-blue-600">
                  報酬
                </Link>
								<Link to="/dashboard/point" className="w-1/2 text-gray-600 text-center py-3 md:px-4 px-2 block hover:text-blue-400 focus:outline-none">
									StackPoint
                </Link>
							</nav>
							<div>
                <h2 className="text-center text-xl mt-8 my-4">報酬総額</h2>
                  <div className="w-full px-6">
                      <div className="flex justify-center items-center px-5 py-6 shadow-sm rounded-md bg-gray-100">
                          <div className="p-2 rounded-full bg-blue-600 bg-opacity-75">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-currency-yen" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                              <path d="M12 19v-7l-5 -7m10 0l-5 7" />
                              <line x1="8" y1="17" x2="16" y2="17" />
                              <line x1="8" y1="13" x2="16" y2="13" />
                            </svg>
                          </div>
                          <div className="mx-5">
                              <h4 className="text-2xl font-semibold text-gray-700">{receive_point}円</h4>
                          </div>
                      </div>
                  </div>
								<p className="text-gray-500 font-semibold text-sm mt-4 px-6">貰ったStackPointに応じた報酬額です。<br/>※報酬額が3,350円（振込下限3,000円+振込手数料350円）以上になると指定の口座に振込ができます。</p>
								<div className="text-center my-6">
									{
										enabledPayout ?
										<Link to="/payout" className="hover:opacity-75 text-white font-bold px-4 py-3 text-ml rounded focus:outline-none main-background">
												{is_loading ? 
												<Spinner/> 
												: 
												"振り込み申請"}
										</Link>
										:
										<div className="fit-content m-auto text-white font-bold px-4 py-3 text-ml rounded focus:outline-none bg-gray-300">
												振り込み申請
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
				<SuccessPopup func={successView} toggle={now_success}>変更を保存しました。</SuccessPopup>
			</div>
			<Footer/>
			</React.Fragment>
	)
}
export default Dashboard
