import {
	CHANGE_USER,
} from '../actions/index.js'

const initialState = {
	listener: 0,
};
function change_user (state = initialState, action){
	switch (action.type){
		case CHANGE_USER:
			return{ listener: state.listener = state.listener + 1}
		// まずはここでstateをセットする
		default:
			return state
	}
}

export default change_user;