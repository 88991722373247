import ReactDOM from 'react-dom';
// 決済のフォーム
import CheckoutForm from "./checkoutForm";

function paymentModal(props){
  // ポータルを使用してモーダル要素に表示
  return ReactDOM.createPortal(
      <div className={"flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-opacity-80 bg-gray-600 z-20 bg-opacity-80" + (props.toggle ? ' block': ' hidden')}>
        <div className="bg-gray-100 rounded-lg md:w-2/5 w-11/12">
          <svg className="w-6 h-6 cursor-pointer text-gray-600 mt-3 ml-3 inline hover:opacity-75" onClick={props.modalToggle} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
          </svg>
          <div className="flex flex-col items-start p-4">
            <CheckoutForm amount={props.amount}/>
          </div>
        </div>
      </div>,
      document.getElementById(props.targetID)
  )
}
export default paymentModal