import Amplify, {API, graphqlOperation} from "aws-amplify";
// DB関連
import * as gqlQueries from '../graphql/queries'
import * as gqlMutations from '../graphql/mutations'

const UseStripe = ()=>{
  // streamingテーブルにデータを登録する。通常配信
  const createPayment = async({cardsource, userInfo, pay_amount, elements, CardElement,stripe})=>{
    // 登録カードで支払うか一回限りのカード情報で払うか
    if(cardsource){
      // クリックされた金額で決済を作成する
      const {data:{stripeFunction:{id, amount }}} = await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { event: 'createCutomerPayment', customer:userInfo.customer, amount: pay_amount }));
      // 受け取った決済情報の決済を完結させる
      const capturePayment = await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { 
          event: 'capturePayment', 
          paymentIntent: id 
        }))
      return {capturePayment, amount}
    }else{
      // クリックされた金額で決済を作成する
      const {data:{stripeFunction:{id, amount }}} = await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { event: 'createPayment', amount: pay_amount }));
      const cardElement = elements.getElement(CardElement);
      // 受け取った決済情報の決済を完結させる
      const cardPayment = await stripe.confirmCardPayment(id, {
        payment_method: {
          card: cardElement
        }
      });
      return {cardPayment, cardElement}
    }
  }
  return {createPayment};
}
export default UseStripe