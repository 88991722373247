import {
	LOGIN_NOW
} from '../actions/index.js'

const initialState = {
	bool: false,
};
function loginNow (state = initialState, action){
	switch (action.type){
		// ログイン中か確認
		case LOGIN_NOW:
			return{ bool: action.bool }
		// まずはここでstateをセットする
		default:
			return state
	}
}

export default loginNow;
