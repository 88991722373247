//Yes or Noのモーダル
import ReactDOM from 'react-dom';
import React, {useState} from 'react';
import Button from '../Atoms/button';

function YesNoModal(props){

  return ReactDOM.createPortal(
      <div className={"flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-opacity-80 bg-gray-600 z-20 bg-opacity-80" + (props.toggle ? ' block': ' hidden')}>
        <div className="bg-white rounded-lg w-1/2 py-10">
          <p className="text-center py-5 font-bold text-lg">{props.children}</p>
          <div className="flex">
            <Button size="yes_no" type="abled" children="はい"  func={props.func} />
            <Button size="yes_no" type="stop" children="いいえ"  func={props.modalToggle} />
          </div>
        </div>
      </div>,
      document.getElementById(props.targetID)
  )
}
export default YesNoModal