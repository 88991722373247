import ReactDOM from 'react-dom';
import React, {useEffect, useState } from 'react';
import MessageGift from './message_gift';
import NormalGift from './normal_gift';
import SendGift from './send_gift';
import BuyModal from './buy_modal';
import Success from '../Molecules/success.js';

// redux
import {useDispatch ,useSelector} from 'react-redux';
import { change_user, succeeded_state } from '../../actions';
import Amplify, {API, graphqlOperation} from "aws-amplify";
import { useParams } from 'react-router';
// フォームを扱うライブラリ
import { useForm } from "react-hook-form";
// DB関連
import * as gqlQueries from '../../graphql/queries'
import * as gqlMutations from '../../graphql/mutations'


function giftModal(props){
	const [ is_switch, setSwitch ] = useState(true);
	const [ is_point, setSendPoint ] = useState(false);
	const [ is_message, setMessage ] = useState(false);
	const [ lackPoint, setLackPoint ] = useState(false);
	const [ buy_page, setBuyPage ] = useState(false);
	const [ click_point, setPoint ] = useState(0);
	const [ click_logo, setLogo ] = useState('');
	const [ remain_point, setRemain ] = useState(0);
	const [ streamingUser, setStreaming ] = useState([]);
	const [ is_loading, setLoading ] = useState(false);
	const succeeded = useSelector(state => state.progress_state.succeeded);

	// reduxディスパッチ
	const dispatch = useDispatch();
	// react-hook-formで使用する
	const { register, handleSubmit, watch, errors } = useForm();
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
  // チャンネルID
  let channelId = useParams().channelId;
	// 新規登録するユーザーテーブル名を環境変数から取得。ローカル開発ではenvファイルを作成して定義する。本番環境ではAmplifyで定義してある。
	const tablename = process.env.REACT_APP_USER_TABLE;

  useEffect(()=>{
    const f = async()=>{
      const {data: {getStreaming:{ user }} }= await API.graphql(graphqlOperation(gqlQueries.getStreaming, { id: channelId }));
      setStreaming(user);
    }
    f();
  },[])
  const message_gifts = [
    ['git.png', 100],
    ['React.svg', 100],
    ['go.png', 100],
    ['vue.svg', 300],
    ['swift.svg', 300],
    ['ruby.svg', 300],
    ['c.svg', 500],
    ['php.svg', 500],
    ['typescript.svg', 1000],
    ['java.svg', 3000],
    ['python.svg', 5000],
    ['javascript.svg', 10000],
  ]
  const normal_gifts = [
    ['like.svg', 1],
    ['party.svg', 1],
    ['cola.svg', 1],
    ['beer.svg', 5],
    ['coffee-cup.svg', 5],
    ['hamburger.svg', 5],
    ['chocolate.svg', 10],
    ['lollipop.svg', 10],
    ['favourites.svg', 10],
    ['pen.svg', 50],
    ['gift.svg', 50],
    ['computer.svg', 50],
  ]
  const setdata = (e)=>{
    const logo = e.target.getAttribute('data-logo');
    const point = e.target.getAttribute('data-point');
    const message_flag = e.target.getAttribute('data-message');
    // String→Int
    const parsedata = parseInt(point);
    // 所持しているポイントの残高計算
    const mypoint = userInfo.buy_point - point;
    // 残高ポイントが不足しているか否か確認
    if(mypoint >= 0){
      setRemain(mypoint);
      setPoint(parsedata);
      setLogo(logo);
      setSendPoint(true);
      if(message_flag == '1'){
        setMessage(true);
      }
    }else{
      setLackPoint(true);
    }
  }
  // ポイントを送付する
  const send_point =async(data)=>{
      setLoading(true);
      const {data: {getStreaming:{ user }} }= await API.graphql(graphqlOperation(gqlQueries.getStreaming, { id: channelId }));
      // ポイントを受け取った場合の数を計算する
      // 手数料を40％もらう計算
      const get_point = user.receive_point + (click_point * 0.6);
      try{
			  // dynamodbにポイントをUpdate(Lambdaでトランザクションを使って行う)
        await API.graphql(graphqlOperation(gqlQueries.lambdaTransaction, 
          { 
          event: 'send_point',
          send_user: userInfo.id,
          receive_user: streamingUser.id,
          remain_point: remain_point,
          get_point: Math.round(get_point),
          tablename: tablename
        }));
        // ギフトをDynamodbに登録する
        const input = {
            uid:userInfo.id,
            channel: channelId,
            comment: '',
            gift: 1,
            icon: click_logo
        }
        // メッセージ付きのギフトか確認
        if(data.message){
          input['comment']=data.message
        }
        await API.graphql(graphqlOperation(gqlMutations.createPost, { input }));           
        // ユーザー状態が変更した事を発火           
        dispatch(change_user());
        // succeededのステートを変更
        dispatch(succeeded_state(true));
        setLoading(false);
      }catch(e){
        setLoading(false);
        alert('ポイント送付時にエラーが起きました。時間をおいてもう一度お試しください')
        console.log(e);
      }
  }
  // ポイントを購入するページを表示させる
  const buy_point = ()=>{
    setBuyPage(true);
  }
  const stateReset =  ()=>{
    props.modalToggle();
    setSwitch(true);
    setSendPoint(false);
    setMessage(false);
    setLackPoint(false);
    setBuyPage(false);
    setPoint(0);
    setLogo('');
    setRemain(0);
    // succeededのステートを変更
    dispatch(succeeded_state(false));
  }
  // ポータルを使用してモーダル要素に表示
  return ReactDOM.createPortal(
      <div className={"flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-opacity-80 bg-gray-600 z-50 bg-opacity-80" + (props.toggle ? ' block': ' hidden')}>
        <div className="bg-white rounded-lg md:w-2/5 w-11/12">
          <svg className="w-6 h-6 cursor-pointer text-gray-600 mt-3 ml-3 inline hover:opacity-75" onClick={stateReset} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
          </svg>
          <div className="flex flex-col items-start p-4 w-full">
            {
              succeeded ?
                <div className="w-full">                   
                  <Success succeeded={succeeded} message="ギフトを贈りました。"/>
                </div>
              :
              <React.Fragment>
                {
                  is_point ?
                    <SendGift click_logo={click_logo} click_point={click_point} is_message={is_message} remain_point={remain_point} func={send_point} is_loading={is_loading}/>
                    :
                    <React.Fragment>
                      {
                        buy_page ?
                        // モーダルでポイントを購入するぺーじ
                        <BuyModal />
                        :
                        <div className="w-full">
                          <nav className="flex flex-row overflow-x-auto whitespace-nowrap hash_tag w-full">
                            <div className={"w-1/2 text-gray-600 py-3 block hover:text-blue-500 focus:outline-none text-center cursor-pointer" + (is_switch ? " border-b-2 border-blue-600 text-blue-600" : " ")} onClick={()=>setSwitch(true)}>
                              メッセージ付きギフト
                            </div>
                            <div className={"w-1/2 text-gray-600 py-3 block hover:text-blue-500 focus:outline-none text-center cursor-pointer" + (is_switch ? " " : " border-b-2 border-blue-600 text-blue-600")} onClick={()=>setSwitch(false)}>
                              ノーマルギフト
                            </div>
                          </nav>
                          <div className="">
                            {
                              is_switch ? 
                                <MessageGift content={message_gifts} func={setdata}/>
                              :
                                <NormalGift content={normal_gifts} func={setdata}/>
                            }
                          </div>
                          <div className="text-center m-auto">
                            {
                              lackPoint ? 
                              <p className="my-5 text-red-600 font-semibold">所持ポイントが不足しています。ポイントを購入してください。</p>
                              :
                              <p className="my-5 text-gray-600 font-semibold">現在の所持ポイント：{userInfo.buy_point}pt</p>
                            }
                            <div className="cursor-pointer main-background hover:opacity-75 text-white font-bold px-4 py-3 text-ml rounded focus:outline-none" onClick={buy_point}>
                              StackPoint購入
                            </div>
                          </div>
                        </div>
                      }
                    </React.Fragment>
                }
              </React.Fragment>
            }
          </div>
        </div>
      </div>,
      document.getElementById(props.targetID)
  )
}
export default giftModal