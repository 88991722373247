import { iconSize } from './theme.js';

export default function LogoutIcon({size, type, func}){
	const classNames = iconSize[size];
	return(
		<svg xmlns="http://www.w3.org/2000/svg" className={classNames} width="20" height="20" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
  		<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  		<path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
  		<path d="M7 12h14l-3 -3m0 6l3 -3" />
		</svg>
	)
}
