import React, { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Amplify, { API, graphqlOperation } from "aws-amplify";
// redux
import {useDispatch ,useSelector} from 'react-redux';
import { change_user } from '../../actions';
import UseToggle from '../../containers/toggle.js';

import { Link } from 'react-router-dom';
// DB関連
import * as gqlMutations from '../../graphql/mutations'
import * as gqlQueries from '../../graphql/queries'

// クレジットカード
export default function CardInfo (props){
  const [imgfile, setimg] = useState('');
  // カードが保存されているか
  const [cardsource, setSource] = useState(false);
  const [cardArray, setCardArray] = useState([]);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
	const [is_checked, checkToggle] = UseToggle(true);
  const inputRef = React.useRef();
  const stripe = useStripe();
  const elements = useElements();
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
  useEffect(()=>{
    const f = async()=>{
	    // 顧客アカウントを取得する。
      const {data: {stripeFunction:{brand,last4,month,year,source}}} = await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { 
        event: 'customer_card', 
        customer: userInfo.customer, 
      }));
      switch(brand){
        case 'Visa':
          setimg('visa');
          break;
        case 'American Express':
          setimg('american-express');
          break;
        case 'JCB':
          setimg('jcb');
          break;
        case 'MasterCard':
          setimg('mastercard');
          break;
      }
      if(source){
        setSource(true);
      }
      setCardArray({brand,last4,month,year});
    }
    f();
  },[]);
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        padding: 50,
        fontSize: "16px",
        "::placeholder": {
          color: "gray"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  // ユーザー情報をアップデートする
  const updateUser = async(input)=>{
    const user = await API.graphql(
      graphqlOperation(gqlMutations.updateUser, {
        input: input
    }));
    // ユーザー状態が変更した事を発火
    dispatch(change_user());
  }
  // カード情報をアップデート
  const updateCard = ()=>{
    const cardElement = elements.getElement(CardElement);
    // ユーザー情報があれば更新する
    if(userInfo.customer){
      //customerのカード情報を更新する。 
      stripe.createToken(cardElement).then(async function(result) {
        const {data:{stripeFunction:{ id }}} = await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { event: 'update_customer', customer: userInfo.customer, card_token: result.token.id }));
      }).catch((e)=>{
        console.log(e);
      });
    }else{
    // 新規でstripe Customerを作成してカード情報を保存する
      stripe.createToken(cardElement).then(async function(result) {
        const {data:{stripeFunction:{ id }}} = await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { event: 'create_customer', card_token: result.token.id }));
        let input = {
          id: userInfo.id,
          customer: id 
        } 
        updateUser(input);
      }).catch((e)=>{
        console.log(e);
      });
    }
  }
  return(
			<React.Fragment>
				<div className="md:w-3/6 w-full md:mx-auto md:mt-20 md:mt-10 mt-0 h-full md:border-solid md:border md:border-gray-200 md:shadow-md rounded">
					<div className="h-auto">
						<h2 className="text-center font-semibold text-2xl md:my-8 mt-8 mb-4">アカウント設定</h2>
						<div className="w-11/12 m-auto">
							<nav className="flex flex-row overflow-x-auto whitespace-nowrap hash_tag">
								<Link to={"/user/" + (userInfo.user_id) + "/edit"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-400 focus:outline-none">
									プロフィール
								</Link>
								<Link to={"/user/" + (userInfo.user_id) + "/transfer_history"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-400 focus:outline-none">
                  振込履歴
								</Link>
								<Link to={"/user/" + (userInfo.user_id) + "/card_info"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-400 focus:outline-none text-blue-600 border-b-2 font-medium border-blue-600">
									カード情報
								</Link>
								<Link to={"/user/" + (userInfo.user_id) + "/bank_account"} className="w-1/4 text-center text-gray-600 py-3 md:px-4 px-2 block hover:text-blue-500 focus:outline-none">
									お振込先
								</Link>
							</nav>
						</div>
            <div className="justify-center items-center">
                {
                  cardsource ?
                  <React.Fragment>
                    <h2 className="text-center font-bold my-5 text-lg text-gray-700">登録済カード</h2>
                    <div className="space-y-16">
                      <div className="md:w-96 md:h-56 w-80 h-48 mx-auto bg-gray-400 border border-gray-300 rounded-xl relative text-white shadow-md">
                          <div className="w-full pl-8 pr-4 absolute md:top-14 top-9">
                              <div className="flex">
                                <div className="pt-1">
                                    <p className="font-light text-sm">
                                      カード番号
                                    </p>
                                    <p className="font-medium tracking-more-wider font-mono">
                                        <strong>●●●● ●●●● ●●●●</strong><span className="px-2 py-1 font-bold">{cardArray.last4}</span>
                                    </p>
                                </div>
                              </div>
                              <div className="pt-6 pr-6">
                                  <div className="flex justify-between relative">
                                      <div className="">
                                          <p className="font-light text-xs text-xs">
                                            有効期限
                                          </p>
                                          <p className="font-medium tracking-wider text-sm font-mono">
                                            {cardArray.year}年{cardArray.month}月
                                          </p>
                                      </div>
                                      <img src={"/images/cardBrand/" + imgfile + ".svg"} className="w-14 absolute right-0"/>
                                  </div>
                              </div>
    
                          </div>
                      </div>
                    </div>
                    <div 
                      className="main-background cursor-pointer hover:opacity-75 fit-content mx-auto my-5 text-white font-bold px-4 py-3 text-ml rounded focus:outline-none" 
                      onClick={()=>setSource(false)}
                    >
                      変更する
                    </div>
                  </React.Fragment>
                  :
                  <div className="w-4/5 m-auto">
                    <h2 className="text-center font-bold my-5 text-lg text-gray-700">新規登録カード</h2>
                    <p className="text-red-600 my-2 text-xs">{error}</p>
                    <div className="border border-blue-300 py-3 px-2 rounded m-auto">
                      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
                    </div>
                    <p className="text-gray-700 text-sm mt-3">・カード情報はStripeに保存されます</p>
                    <div 
                      className="main-background cursor-pointer hover:opacity-75 fit-content mx-auto my-5 text-white font-bold px-4 py-3 text-ml rounded focus:outline-none" 
                      onClick={updateCard}
                    >
                      登録する
                    </div>
                  </div>
                }
            </div>
				</div>
				</div>
			</React.Fragment>
  )
}