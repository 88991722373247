import React, { Suspense, useEffect, useState } from 'react';
import { Link, useHistory} from 'react-router-dom';
const SearchContent = React.lazy(() => import('../Organisms/searchContent.js')); 
//Atoms
import SearchIcon from '../Atoms/searchIcon';
//Atoms
import Input from '../Atoms/input';

function SearchTemp(props){
	const [ isSearch, setSearch ] = useState(false);
	const [query, setQuery] = useState(props.keyword);

	//keywordが変更される度に走る 
	useEffect(()=>{
		if(props.keyword == ''){
				setSearch(false);
		}else{
				setSearch(true);
			};
	},[]);

	let history = useHistory();
	// 検索クエリを送信
	function onSearch(e){
		e.preventDefault();
		if(query == ''){
			history.push("/search");
		}else{
			// メール認証コードページへリダイレクト
			history.push("/search/"+ query);
		}
	}

	const queryChange = (e) =>{
		const value = e.target.value;
		setQuery(value);
	}
	return(
			<React.Fragment>
            <div className="w-full">
              <form className="mx-auto mt-14 md:w-3/5 w-4/5 md:h-14 h-12 text-gray-600 flex " onSubmit={onSearch}>
                {/*searchアイコン*/}
                <span className="self-center absolute">
                  <SearchIcon size="md"/>
                </span>
                <Input css="search" type="search" placeholder="Search" func={queryChange} value={query}/>

                <button type="submit" className="absolute right-0 top-0 mt-5 mr-4">
                </button>
              </form>
              <Suspense fallback={<p></p>}>
                <SearchContent resource={props.resource} keyword={props.keyword} isSearch={isSearch}/>
              </Suspense>
            </div>
			</React.Fragment>
	)
}
export default SearchTemp