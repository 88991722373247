import LiveCog from '../Organisms/LiveCog.js';
import React, {useState} from 'react';

function LiveTemp(props){
	return(
			<React.Fragment>
				<LiveCog userInfo={props.userInfo} />
			</React.Fragment>
	)
}
export default LiveTemp
