import ReactDOM from 'react-dom';
import React, {useState} from 'react';

function QrModal(props){
  return ReactDOM.createPortal(
      <div className={"flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-opacity-80 bg-gray-600 z-20 bg-opacity-80" + (props.toggle ? ' block': ' hidden')}>
        <div className="bg-white rounded-lg w-1/2">
          <svg className="w-6 h-6 cursor-pointer text-gray-600 mt-3 ml-3 inline hover:opacity-75" onClick={props.modalToggle} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
          </svg>
          <div className="">
            {/* QRコードを表示させる場所 */}
            {props.element}
            <p className="text-center text-gray-800 font-bold my-5">
              こちらからチャットのページへ遷移する事ができます。<br/>LIVE配信をしながらスマホでコメントを確認しましょう！
            </p>
          </div>
        </div>
      </div>,
      document.getElementById(props.targetID)
  )
}
export default QrModal