import {
	BOOLEAN_MENU,
} from '../actions/index.js'

const initialState = {
	menu: false,
};
function booleanList (state = initialState, action){
	switch (action.type){
		case BOOLEAN_MENU:
			return{ menu: !state.menu}
		// まずはここでstateをセットする
		default:
			return state
	}
}

export default booleanList;
