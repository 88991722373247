//Yes or Noの確認画面
import ReactDOM from 'react-dom';
import React, {useState} from 'react';
import Button from '../Atoms/button';
import Spinner from '../Atoms/spinner';

function PaidConfirm(props){
  return (
    <div className={"w-full"}>
      <div className="bg-white rounded-lg py-10">
        <p className="text-center font-bold text-lg text-gray-700">ポイントを使い有料配信を視聴します</p>
        <p className="text-center my-5 text-gray-700 font-bold text-lg">所持ポイント：{props.myPoint}→<span className="text-red-600 font-bold">{props.remain_point}</span></p>
        <div className="flex">
          {
            props.is_loading ? 
              <Spinner/>
            :
            <div className="w-full flex">
              <Button size="yes_no" type="abled" children="はい"  func={()=>props.func()} />
              <Button size="yes_no" type="stop" children="いいえ"  func={()=>props.setConfirmPage()} />
            </div>
          }
        </div>
      </div>
    </div>
  )
}
export default PaidConfirm