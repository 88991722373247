import React from 'react';
import Footer from '../Organisms/footer.js';
import Header from '../Organisms/Header.js';
// Molecules
import BlackModal from '../Molecules/black-modal';
import SmLeftMenu from '../Organisms/sm-leftMenu.js';

function Terms(props){
	return(
		<React.Fragment>
				<BlackModal targetID='modal' />
				<Header />
        <div className="md:w-3/5 w-full md:m-auto pt-16">
					{/* SMレフトメニュー */}
					<SmLeftMenu/>
	<div className="md:border md:border-gray-300 rounded my-10 md:px-10 px-8 py-5">
				<div className="text-center my-8">
					<h2 className="font-bold text-2xl">利用規約</h2>
				</div>
				<div className="m-auto">
					<div className="text-center">
						<p className="text-left">StackLive（以下 「本サービス」といいます。）のご利用にあたり、本利用規約 （以下 「本規約」といいます。）のすべての条項をお読みいただき、同意していただく必要がございます。本サービスの利用者（以下「利用者」といいます。）は、予め本規約に同意した上で、本サービスを利用するものとします。</p>
					</div>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">第1条.（適用）</h4>
					</div>
					<div className="">
						<p>本規約は、本サービスの提供条件及び本サービス運営者（以下「運営者」といいます。）と利用者との間の権利義務関係を定めることを目的とし、利用者と運営者との間のサービスの利用に関わる一切の関係に適用されるものとします。</p>
					</div>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">第2条.（定義）</h4>
					</div>
          <p>「利用者」とは、コンテンツの投稿の有無を問わず、本サービスの閲覧など本サービスを利用されるすべての方を意味します。</p>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">第3条.（登録）</h4>
					</div>
          <p className="my-4">
            ・本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ運営者の定める一定の情報（以下「登録情報」といいます。）を運営者の定める方法で運営者に提供することにより、運営者に対し、本サービスの利用の登録を申請することができます。
          </p>
          <p className="my-4">
            ・登録の申請は必ず本サービスを利用する個人又は法人自身が行わなければならず、原則として代理人による登録申請は認められません。また、登録希望者は、登録の申請にあたり、真実、正確かつ最新の情報を運営者に提供しなければなりません。
          </p>
          <p className="my-4">              
            ・利用者は、ログインアカウント及びパスワード（以下「ログインアカウント等」といいます。）の登録・管理等をご自身の責任において行うものとし、利用者の管理不十分、使用上の過誤、第三者の使用等により生じた損害について、運営者は一切の責任を負わないものとします。また、利用者は、ログインアカウント等にかかるサービス（本サービスを含み、以降同様とする。）の利用をご自身の責任において利用するものとし、当該サービスの利用については当該サービスの運営者が規定する各規約の定めに従うものとします。ログインアカウント等にかかるサービスを利用したことにより生じた損害、当該サービス運営者とお客様の間に生じたトラブル等について運営者は一切の責任を負わないものとします。
          </p>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">第4条.（登録情報の変更）</h4>
					</div>
          <p className="my-4">              
            利用者は、登録情報に変更があった場合は、遅滞なく、運営者の定める方法により、当該変更事項を運営者に通知し、運営者から要求された資料 を提出するものとします。
          </p>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">第5条.（ユーザー名とパスワード）</h4>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
              <span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">1</span>
            </div>
						<h5 className="my-auto">登録ユーザーは、設定した自らのユーザー名とパスワードの管理を自己の責任で行います。</h5>
					</div>
					<div className="text-left flex my-2">
            <div className="w-10 m-auto">
              <span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">2</span>
            </div>
						<h5 className="my-auto">ユーザー名及びログインパスワードを利用して行われた行為は、当該ユーザー名及びパスワードを有する登録ユーザーが行ったものとみなします。</h5>
					</div>
					<div className="text-left flex my-2">
            <div className="w-10 m-auto">
              <span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">3</span>
            </div>
						<h5 className="my-auto">パスワード又はユーザーID等の管理不十分、第三者の使用等によって生じた損害または不利益に関する責任は利用者が負うものとし、運営者は一切の責任を負いません。</h5>
					</div>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">第6条.（禁止行為）</h4>
					</div>
					<p>利用者が本サービスを利用するに際して、次の行為を行うことを禁止します。</p>
					<div className="text-left flex my-2">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">1</span>
            <p className="my-auto">法令または公序良俗に違反する行為</p>
					</div>
					<div className="text-left flex my-2">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">2</span>
            <p className="my-auto">本規約又はガイドラインに違反すること</p>
					</div>
					<div className="text-left flex my-2">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">3</span>
            <p className="my-auto">公序良俗に反すること</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">4</span>
            </div>
            <p className="my-auto">違法行為・犯罪的行為・重大な危険行為に結びつくこと又はこれらを助長すること</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-10 m-auto">
              <span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">5</span>
            </div>
            <p className="my-auto">運営者、他のお客様又は第三者の知的財産権（著作権、意匠権、実用新案権、商標権、特許権、ノウハウが含まれますがこれに限定されません）を侵害すること</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">6</span>
            </div>
            <p className="my-auto">本サービスの運営を妨げること、又は運営者の信用を毀損すること</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">7</span>
            </div>
            <p className="my-auto">スパムとみなされる行為（機械により自動生成された文章の投稿や同一内容の文章を繰り返し投稿する行為など）</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-10 mt-1">
						  <span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">8</span>
            </div>
            <p className="my-auto">過度に暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引または助長する表現、他人に不快感を与える表現等、不適切な内容を投稿する行為</p>
					</div>
					<div className="text-left flex my-2">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">9</span>
            <p className="my-auto">異性交際に関する情報を送信する行為</p>
					</div>
					<div className="text-left flex my-2">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">10</span>
            <p className="my-auto">その他、運営者が不適切と判断する行為</p>
					</div>
          <p>
            利用者により前項各号に該当する行為又は本サービスの趣旨・目的に照らして不適切であると運営者が判断する行為がなされた場合、運営者は当該利用者に対して、本サービスの利用の停止その他運営者が適切と判断する措置（以下「利用停止措置」といいます。）をとることができるものとします｡なお、利用停止措置は利用者の帰責性の有無にかかわらず運営者の裁量・判断に基づき行うことができるものとし、利用停止措置を行った理由については､その如何を問わず利用者に対して一切お答えいたしかねます｡加えて、利用停止措置に起因して利用者に生じた損害については､運営者は一切の責任を負わないものとします｡
          </p>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">第7条.（本サービスの提供の停止等）</h4>
					</div>
          <p className="my-3">運営者は、以下のいずれかの事由があると判断した場合、利用者に事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</p>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">1</span>
            </div>
            <p className="my-auto">本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">2</span>
            </div>
            <p className="my-auto">地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">3</span>
            </div>
            <p className="my-auto">コンピュータまたは通信回線等が事故により停止した場合</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">4</span>
            </div>
            <p className="my-auto">本サービスが利用しているクラウドサービスが停止した場合</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
            <span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">5</span>
            </div>
            <p className="my-auto">その他、運営者が本サービスの提供が困難と判断した場合</p>
					</div>
          <p className="my-4">運営者は、本サービスの提供の停止または中断により、利用者または第三者が被ったいかなる損害または不利益について、理由を問わず一切の責任を負わないものとします。</p>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">第8条.（返金に関して）</h4>
					</div>
					<p>登録ユーザーはポイントを運営者の定める方法により使用することで、運営者の定める範囲のコンテンツ使用権を取得することができるものとします。</p>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">1</span>
            </div>
            <p className="my-auto">運営者がカード会社またはその代理店から、利用料金を徴収できなかった場合でも、ユーザーは運営者に対し、運営者が要求する金額を全額支払うことに同意するものとします。</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">2</span>
            </div>
            <p className="my-auto">如何なる事情があろうと、ポイント購入後及び有料サービス契約後の返金請求は無効とします。</p>
					</div>
					<div className="text-left flex my-2">
            <div className="w-7 my-auto">
						<span className="my-auto mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-blue-400 text-white text-sm">3</span>
            </div>
            <p className="my-auto">登録ユーザーが退会等により本サービスの利用資格を喪失した場合は、未使用分のポイントも消滅するものとします。</p>
					</div>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">第9条.（著作権、財産権その他の権利）</h4>
					</div>
          <p>・本サービス含まれているコンテンツ及び個々の情報､商標､画像､広告､デザイン等に関する著作権､商標権その他の知的財産権、及びその他の財産権は全て運営者又は正当な権利者に帰属しています｡</p>
          <p className="my-4">・利用者または第三者は、本サービス上のコンテンツについて、権利者の許可を得ることなく、無断で転載または二次配布等を行うことはできません。</p>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">第10条.（広告の掲載）</h4>
					</div>
          <p>運営者は、第三者の広告を本サービス上に掲載することができるものとします。</p>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">第11条.（準拠法及び管轄裁判所）</h4>
					</div>
          <p className="">本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
					<div className="text-left mt-5 mb-2">
						<h4 className="my-auto mx-0 font-bold text-xl">第12条.（本規約等の変更）</h4>
					</div>
          <p>運営者は、本サービスの内容を自由に変更できるものとします。</p>
					</div>
		</div>
	</div>
			<Footer/>
		</React.Fragment>
	)
}
export default Terms