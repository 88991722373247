import {Link} from 'react-router-dom';
// redux
import {useSelector, useDispatch } from 'react-redux';
//Atoms
import Div from '../Atoms/div';
import DashboardIcon from '../Atoms/dashboardIcon';
import ConfigIcon from '../Atoms/configIcon';
import LogoutIcon from '../Atoms/logoutIcon';
import AnalyticsIcon from '../Atoms/analyticsIcon';

// Containers
import UseSignOut from '../../containers/signOut.js';

function HeaderMenu({value}){
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
	const element = (
		<span className="flex">
			<DashboardIcon size="md"/>
			ダッシュボード	
		</span>
	)
	const element3 = (
		<span className="flex">
			<AnalyticsIcon size="md"/>
			<p className="my-auto">分析</p>
		</span>
	)
	const element1 = (
		<span className="flex">
			<ConfigIcon size="md"/>
			アカウント設定
		</span>
	)
	const element2 = (
		<span className="flex">
			<LogoutIcon size="md"/>
				ログアウト
		</span>
	)
	// サインアウト
	const [ signOut ] = UseSignOut();
	return(
		<div className={"togglePage absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-48 z-10" + (value ? " block" : " hidden")}  >
				<div className="px-2 py-2 bg-white rounded-md shadow">
				<Link to={"/user/" + userInfo.user_id}>
					<Div size="md" type="md" children={userInfo.name}/>
				</Link>
				<Link to="/dashboard">
					<Div size="md" type="md" children={element}/>
				</Link>
				<Link to={"/user/" + userInfo.user_id + "/analytics"}>
					<Div size="md" type="md" children={element3}/>
				</Link>
				<Link to={"/user/" + userInfo.user_id + "/edit"}>
					<Div size="md" type="md" children={element1}/>
				</Link>
				<Div size="md" type="md" children={element2} func={signOut}/>
			</div>
		</div>
	)
}
export default HeaderMenu
