import React, { useEffect, useState } from 'react';
import API, { graphqlOperation } from '@aws-amplify/api';
import { Link } from 'react-router-dom';
import { listPosts } from '../../graphql/queries';
import { createPost } from '../../graphql/mutations';
import { onCreatePost } from '../../graphql/subscriptions.js';
// フォームを扱うライブラリ
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Img from '../../components/Atoms/img/index.js';
import moment from 'moment';
import 'moment/locale/ja';
import useHover from './use-hover';
// DB関連
import * as gqlQueries from '../../graphql/queries'

function Chat(props) {
	  // react-hook-formで使用する
    const { register, handleSubmit, reset } = useForm();
    // 最新40件のコメントを格納するstate
    const [messages, setMessages] = useState([]);
    const [chatPage, setChatPage] = useState(false);
    // ログインしているユーザーの情報
    const userInfo = useSelector(state => state.auth.userInfo);
    // チャンネルID
    let channelId = useParams().channelId;
    // hover
    const [hoverRef, isHovered] = useHover();
	const inputRef = React.useRef();
    // 最新40件のコメントを表示（最初の一回だけ実行する）
    useEffect(() => {
        const tmp = []
        API
        .graphql(graphqlOperation(listPosts,{filter:{channel: {eq: channelId}},limit:40}))
        .then((response) => {
        response.data.listPosts.items.map(value => {
        tmp.push(value);
        })
        // 時間でソート
        tmp.sort( function(a, b) {
            return a.createdAt < b.createdAt ? -1 : 1;
        });
        setMessages(tmp);
        })
        // 見ているページがstacklive.jp/chat/*******のページかどうか確認する
        let dir = location.href.split("/");
        let dir2 = dir[dir.length -2];
        if(dir2 == "chat"){
          setChatPage(true);
          function setHeight() {
            const vh = window.innerHeight;
            document.getElementById('only_chat_height').style.height = vh + 'px';
          }
          // 2.初期化
          setHeight();
          // 3.ブラウザのサイズが変更された時・画面の向きを変えた時に再計算する
          window.addEventListener('resize', setHeight);
        }
    },[]);
    // messages stateに変更があった場合(コメントが投稿された時)、ユーザーの画面を更新する
    useEffect(() => {
        const subscription = API
          .graphql(graphqlOperation(onCreatePost))
          .subscribe({
            next: (event) => {
            setMessages([...messages, event.value.data.onCreatePost]);
            }
          });
        return () => {
          subscription.unsubscribe();
        }
    },[messages]);

  // コメントをDynamodbに登録する
    const messageSubmit =  (data) => {
        const input = {
            uid:userInfo.id,
            channel: channelId,
            comment: data.message,
            icon: '',
            gift: 0
        }
        try {
            API.graphql(graphqlOperation(createPost, { input }))
            reset();
        } catch (error) {
            console.warn(error);
        }
  };
  const onkeyBord = (event)=>{
    if(event.ctrlKey&&event.which==13){
      inputRef.current.click();
      return false
    };
  }
  return (
<React.Fragment>
  <div id="only_chat_height" className="messages h-full">
    <div className="relative flex flex-col h-full md:py-4 p-0">
        <div className={"bg-chat flex flex-col flex-shrink-0 md:rounded-2xl rounded-none md:bg-gray-100 h-full border" + (props.dark ? " bg-gray-300" : " ")}> 
        {            
          props.qr ?
            <div className="text-right px-3 pt-3 flex justify-between">
              {/* 視聴者数 */}
              <div>
                <svg className="w-6 h-6 inline-block text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
                <p className="text-sm inline-block ml-2">{props.viewerCount}人視聴中</p>
              </div>
              {/* QRコード */}
              <svg className="w-8 h-8 cursor-pointer inline-block" fill="none" strokeWidth="1.5" stroke="#868686" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" onClick={props.toggle}>
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z" 
                />
              </svg>
            </div>
          :
              <div className="ml-5 md:my-5 my-3">
                <React.Fragment>
                  <svg className="w-6 h-6 inline-block text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                  <p className="text-base inline-block ml-2">{props.viewerCount}人視聴中</p>
                </React.Fragment>
              </div>
        }
          <div className="overflow-y-auto pb-16 pt-1 md:pt-4 px-3 h-full break-all">
            {messages.map((message,index) =>
              <div className=" flex flex-col overflow-x-auto pb-4" key={index}>
                <div className="flex flex-col h-full">
                  <div className="grid gap-y-2">
                    <div className="fit-content p-1 rounded-lg relative">
                      <div className="flex flex-row items-center">
                        {
                          message.user.icon ?   
                          <Img size="md" type="chat" src={message.user.icon}/>
                          :
                          <img src="/images/smiley-sticker.svg" className="flex items-center justify-center h-8 w-8 rounded-full flex-shrink-0"/>
                        }
                        <div className='ml-3'>
                          <div className="text-xs font-semibold mb-1">{message.user.name}</div>
                          {
                            message.gift ?
                              <div className={"text-xs py-2 px-3 shadow rounded-xl flex fit-content" + (props.dark ? " bg-gray-100" : " bg-white")}>
                                {
                                  message.comment ?
                                  <div className={"my-auto mr-1"}>{message.comment}</div>
                                  :
                                  <span className="my-auto mr-1">{message.user.name}さんからギフトが届きました！</span>
                                }
                                <img className="h-7 m-auto" src={"/images/giftlogo/"+(message.icon)}></img>
                              </div>
                            :
                              <div className={"text-xs py-2 px-3 shadow rounded-xl fit-content" + (props.dark ? " bg-gray-100" : " bg-white")}>{message.comment}</div>
                          }
                          <div className="text-xs absolute -bottom-4 right-2 text-gray-700">{moment(message.createdAt).fromNow(true)}前</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={"md:absolute fixed bottom-0 flex flex-row items-center rounded-xl w-full py-3 px-3" + (props.dark ? " bg-gray-100" : " bg-gray-600 bg-opacity-75") + (props.archive ? " hidden" : " ") }>
          <div className="w-full">
              {userInfo.id
                  ?
                    <form className="flex">
                      <textarea
                        name="message"
                        className={"h-10 resize-none flex w-full border rounded-xl focus:outline-none focus:border-blue-300 px-3 py-2 mx-2 appearance-none text-base"}
                        ref={register({ required: true })}
                        type="text"
                        placeholder="コメントを入力"
                        onKeyDown={onkeyBord}
                      /> 
                      <input type="text" className="hidden" name="dummy"/>
                          {/* 送信ボタン */}
                          <button ref={inputRef} type="button" id="submit" className="h-11/12 m-auto flex items-center justify-center bg-blue-500 hover:bg-blue-400 rounded-lg text-white px-3 py-2 flex-shrink-0" onClick={handleSubmit(messageSubmit)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-send" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                              <line x1="10" y1="14" x2="21" y2="3" />
                              <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
                            </svg>
                          </button>
                          {/* ギフトのポップアップを表示させるボタン */}
                          <div className="p-2 rounded-full bg-yellow-500 bg-opacity-75 absolute -top-11 right-4 cursor-pointer hover:opacity-75" onClick={props.modalToggle}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ffffff" viewBox="0 0 256 256">
                              <rect width="256" height="256" fill="none"></rect>
                              <rect x="32" y="80" width="192" height="48" rx="7.99999" strokeWidth="24" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none"></rect>
                              <path d="M208,128v72a8,8,0,0,1-8,8H56a8,8,0,0,1-8-8V128" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></path>
                              <line x1="128" y1="80" x2="128" y2="208" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
                              <path d="M173.25483,68.68629C161.94113,80,128,80,128,80s0-33.94113,11.31371-45.25483a24,24,0,0,1,33.94112,33.94112Z" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></path>
                              <path d="M82.74517,68.68629C94.05887,80,128,80,128,80s0-33.94113-11.31371-45.25483A24,24,0,0,0,82.74517,68.68629Z" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></path>
                            </svg>
                          </div>
                    </form>
                  :
                  // ログインしていない時の処理
                    <div className="" ref={hoverRef}>
                        {isHovered
                          ?
                            <ul className="flex mx-auto my-1 justify-center">
                              <Link to="/login">
                                <div className="main-background hover:opacity-75 text-white font-bold py-2 px-4 rounded mr-2 text-sm">ログイン</div>
                              </Link>
                              <Link to="/register">
                                <div className="main-background hover:opacity-75 text-white font-bold py-2 px-4 rounded text-sm">新規登録</div>
                              </Link>
                            </ul>
                          :
                          <form className="flex">
                            <input
                              type="text"
                              name="message"
                              className="flex w-full border rounded-xl focus:outline-none focus:border-blue-300 px-4 py-2 mx-2 appearance-none text-base"
                              placeholder="コメントを入力"
                            />
                                {/* 送信ボタン */}
                                <button className="h-11/12 m-auto flex items-center justify-center bg-blue-500 hover:bg-blue-400 rounded-lg text-white px-3 py-2 flex-shrink-0">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-send" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <line x1="10" y1="14" x2="21" y2="3" />
                                    <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
                                  </svg>
                                </button>
                              {/* ギフトのポップアップを表示させるボタン */}
                              <div className="p-2 rounded-full bg-yellow-500 bg-opacity-75 absolute -top-11 right-4 cursor-pointer hover:opacity-75">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ffffff" viewBox="0 0 256 256">
                                  <rect width="256" height="256" fill="none"></rect>
                                  <rect x="32" y="80" width="192" height="48" rx="7.99999" strokeWidth="24" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none"></rect>
                                  <path d="M208,128v72a8,8,0,0,1-8,8H56a8,8,0,0,1-8-8V128" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></path>
                                  <line x1="128" y1="80" x2="128" y2="208" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
                                  <path d="M173.25483,68.68629C161.94113,80,128,80,128,80s0-33.94113,11.31371-45.25483a24,24,0,0,1,33.94112,33.94112Z" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></path>
                                  <path d="M82.74517,68.68629C94.05887,80,128,80,128,80s0-33.94113-11.31371-45.25483A24,24,0,0,0,82.74517,68.68629Z" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></path>
                                </svg>
                              </div>
                          </form>
                        }
                    </div>
            }
            </div>
          </div>
        </div>
      </div>
  </div>
</React.Fragment>
  );
}
export default Chat;
