import React, { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Amplify, { API, graphqlOperation } from "aws-amplify";
// redux
import {useDispatch ,useSelector} from 'react-redux';
import { change_user, buy_point_succeeded_state} from '../../actions';
import Spinner from '../Atoms/spinner';
import Success from '../Molecules/success.js';
// クレジットカード
import CreditCard from "./creditcard";
// Containers
import UseStripeFunction from '../../containers/stripeFunction.js';
import UseCurrentUser  from '../../containers/currentUser.js';
import UseToggle from '../../containers/toggle.js';
// DB関連
import * as gqlMutations from '../../graphql/mutations'
import * as gqlQueries from '../../graphql/queries'

export default function CheckoutForm(props) {
	const [bool,currentUser] = UseCurrentUser(false);
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
	const succeeded = useSelector(state => state.progress_state.buy_point_succeeded);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
	const [is_checked, checkToggle] = UseToggle(true);
  // 登録したカード情報で支払うか新しいカード情報で支払うか
  const [cardsource, setSource] = useState(false);
  const [cardarray, setCardArray] = useState([]);
  const inputRef = React.useRef();
  const stripe = useStripe();
  const elements = useElements();
	// reduxディスパッチ
	const dispatch = useDispatch();
  const customStripefunc = UseStripeFunction();
	useEffect(()=>{
		const f = async()=>{
			// 顧客アカウントを取得する。
			const {data: {stripeFunction:{brand,last4,month,year,source}}} = await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { 
				event: 'customer_card', 
				customer: userInfo.customer, 
			}));
      if(source){
        setSource(true);
      }
      setCardArray({brand,last4,month,year});
		};
    // クレジットカードを保存していた場合
    if(userInfo.customer){
      f();
    }
	},[]);
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        padding: 50,
        fontSize: "16px",
        "::placeholder": {
          color: "gray"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  // ユーザー情報をアップデートする
	const updateUser = async(amount)=>{
    let input = {
      id: userInfo.id,
      buy_point: '' 
    } 
    switch(amount){
      case 550:
        input['buy_point'] = userInfo.buy_point + 500;
        break;
      case 1100:
        input['buy_point'] = userInfo.buy_point + 1000;
        break;
      case 3300:
        input['buy_point'] = userInfo.buy_point + 3000;
        break;
      case 5500:
        input['buy_point'] = userInfo.buy_point + 5000;
        break;
      case 11000:
        input['buy_point'] = userInfo.buy_point + 10000;
        break;
    }
		const user = await API.graphql(
			graphqlOperation(gqlMutations.updateUser, {
				input: input
		}));
    // ユーザー状態を変更する
    currentUser();
		return user
	}
  const change_success = ()=>{
    // succeededのステートを変更
    dispatch(buy_point_succeeded_state(true));
    // 成功の処理
    setError(null);
    setProcessing(false);
  }
  // 支払いボタンをクリック
  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);
    if(cardsource){
      const args = {
        cardsource: cardsource,
        userInfo: userInfo,
        pay_amount: props.amount,
      }
      const result = await customStripefunc.createPayment(args);
      if(result.capturePayment.error){
        // カード情報に不備がある場合
        setProcessing(false);
        setError(result.capturePayment.error.message)
      }else{
        updateUser(result.amount);
        change_success();
      }
    }else{
      const args = {
        cardsource: cardsource,
        userInfo: userInfo,
        pay_amount: props.amount,
        elements: elements,
        CardElement: CardElement,
        stripe: stripe
      }
      const result  = await customStripefunc.createPayment(args);
      if(result.cardPayment.error){
        // カード情報に不備がある場合
        setProcessing(false);
        setError(result.cardPayment.error.message)
      }else{
        updateUser(result.cardPayment.paymentIntent.amount);
        if(inputRef.current.checked){
          // すでにCustomerがあれば更新なければ作成
          if(userInfo.customer){
            //customerのカード情報を更新する。 
            stripe.createToken(result.cardElement).then(async function(result) {
              await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { event: 'update_customer', customer: userInfo.customer, card_token: result.token.id }));
              change_success();
            });
          }else{
            //カード情報を保存するためにcustomerを作成してカード情報を保存する。 
            stripe.createToken(result.cardElement).then(async function(result) {
              if(result.error){
                setError(result.error);
              }else{
                const {data:{stripeFunction:{ id }}} = await API.graphql(graphqlOperation(gqlQueries.stripeFunction, { event: 'create_customer', card_token: result.token.id }));
                let input = {
                  id: userInfo.id,
                  customer: id 
                } 
                const user = await API.graphql(
                  graphqlOperation(gqlMutations.updateUser, {
                    input: input
                }));
                // ユーザー状態を変更する
                currentUser();
                change_success();
              }
            });
          }
        }else{
          change_success();
        }
      }
    }
  };
  // 隠れてるチェックボックスをトグル
  const checktoggle = (e)=>{
    checkToggle();
  }
  // 保存したクレジットカード支払いを変える
  const cardChange = ()=>{
    setSource(false);
  }
  return (
    <form id="payment-form" className="w-full">
				{
					succeeded ?
						<div className="w-full">
							<Success succeeded={succeeded} message="購入が完了しました。"/>
						</div>
					:
          <div className="w-11/12 m-auto">
            <label className="mb-3 block text-gray-500 font-bold text-center">支払い情報</label>
            <label className="mb-3 block text-gray-700 font-bold">支払い額：<span className="text-red-500">{props.amount}円</span></label>
            {
              cardsource ? 
              <div>
                <p className="font-semibold text-gray-600 text-center my-6">保存したクレジットカードで支払う</p>
                <CreditCard array={cardarray}/>
                <p className="content-end px-5 font-semibold text-gray-600 text-right mt-4 text-sm cursor-pointer hover:opacity-75" onClick={cardChange}>変更する→</p>
              </div>
              :
            <div>
              <p className="text-red-600 my-2 text-xs">{error}</p>
              <div className="w-full border border-blue-300 py-3 px-2 rounded">
                <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
              </div>
              <div className="flex items-center cursor-pointer mt-3">
                <div className="relative" onClick={checktoggle}>
                  <input 
                  id="toogleButton" 
                  type="checkbox" 
                  className="hidden"
                  ref={inputRef} 
                  checked={is_checked}
                  readOnly
                  />
                  <div
                    className="toggle-path bg-gray-200 w-9 h-5 rounded-full shadow-inner"
                  ></div>
                  <div
                    className="toggle-circle absolute w-5 h-5 bg-white rounded-full shadow inset-y-0 left-0"
                  ></div>
                </div>
                <div className="px-2 text-sm">カード情報をStripeに保存する</div>
              </div>
            </div>
            }
            <button
              // disabled={processing || disabled || succeeded}
              className="main-background text-white rounded py-2 px-4 mt-4 mx-auto flex font-bold "
              id="submit"
              onClick={handleSubmit}
            >
              <span>
                {processing ? 
                  <Spinner/>
                : 
                  "支払う"
                }
              </span>
            </button>
          </div>
      }
    </form>
  );
}

