import React, { useState } from 'react';
import { Link,useHistory } from 'react-router-dom';
import Amplify, { API, graphqlOperation, Storage } from "aws-amplify";
// フォームを扱うライブラリ
import { useForm } from "react-hook-form";
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import config from "../../aws-exports.js";
// redux
import {useDispatch ,useSelector} from 'react-redux';

import { change_user } from '../../actions';
// DB関連
import * as gqlMutations from '../../graphql/mutations'

// Molecules
import ModalCircle from '../Molecules/modalCircle.js';
import Logo from '../Molecules/logo.js';
// Atoms
import Spinner from '../Atoms/spinner';
// Containers
import UseUuid from '../../containers/uuid.js';
import UseToggle from '../../containers/toggle.js';
import Uses3Upload from '../../containers/s3-upload.js';

function Profile (){
	Amplify.configure(config);
	// reduxディスパッチ
	const dispatch = useDispatch();
	// store
	// ユーザー情報
	const userInfo = useSelector(state => state.auth.userInfo);
	// react-hook-formで使用する
	const { register, handleSubmit, watch, errors } = useForm();
	const [ is_loading, setLoading ] = useState(false);

  const inputRef = React.useRef();

	const [src, setSrc] = useState(null);
	const [filetype, setType] = useState(null);
	const [toggle, modalToggle] = UseToggle(false);
	const [fileUrl, setFileUrl ] =  useState('');
	// s3にアップロード
  const [upload] = Uses3Upload();

	// inputを擬似クリック
	const handleClick = ()=> inputRef.current.click();

  const onSelectFile = (e) => {
		const files = Object.values(e.target.files).concat();
		files.filter((file) => {
			// ファイルをバリデーションする
      if (
        ![
          "image/jpeg",
          "image/png",
          "image/bmp",
          "image/svg+xml",
        ].includes(file.type)
      ){
				alert('jpeg, png, svg以外のファイル形式は表示されません');
        return;
      }else{
				if (e.target.files && e.target.files.length > 0) {
					let selectedFile = e.target.files[0];
					setType(selectedFile.type)
					const reader = new FileReader();
					let imgtag = document.getElementById("myimage");
					imgtag.title = selectedFile.name;
					reader.addEventListener('load', () =>
						setSrc(reader.result)
					);       
					reader.readAsDataURL(e.target.files[0]);
					modalToggle();
					// valueを初期化
					let inputfile = document.getElementById("inputfile");
					inputfile.value = '';
				}
			}
		});
  };

	async function updateUser(input){
		const user = await API.graphql(
			graphqlOperation(gqlMutations.updateUser, {
				input: input
		}));
		return user
	}
	let history = useHistory();
	//s3にファイルを送信&自己紹介文を保存
	const pushFile = async (data)=>{
		setLoading(true);
    nprogress.start()
		if(fileUrl == ''){
			let input = {
					id: userInfo.id,
					profile: data.profile
		}
		let result = updateUser(input);
		result.then(data=>{
			// 成功処理
			if(data != null){
				nprogress.done();
				setLoading(false);
				// ユーザー状態が変更した事を発火
				dispatch(change_user());
				history.push("/tech");
			}
		}).catch(e=>{
				nprogress.done();
				setLoading(false);
				console.log(e);
		})
		}else{
			// uuidを作成
			const [uuid] = await UseUuid();
			let imageData = await fetch(fileUrl);
			let blobData = await imageData.blob();
			// s3にアップロードする
      await upload(uuid,blobData,filetype);			
			let input = {
					id: userInfo.id,
					profile: data.profile,
					icon: uuid,
			}
			let result = updateUser(input);
			result.then(data=>{
				// 成功処理
				if(data != null){
					nprogress.done();
					setLoading(false);
					history.push("/tech");
				}
			}).catch(e=>{
					nprogress.done();
					setLoading(false);
					console.log(e);
			})
		}
	}
	// リアルタイムでフォームを監視
  watch('profile')
	return(
		<div className="">
			<ModalCircle src={src} targetID='modal' modalToggle={modalToggle} toggle={toggle} setFileUrl={setFileUrl} fileUrl={fileUrl}/>
			<div >
				<div className="md:justify-between justify-center flex mt-6 md:ml-5 ml-0">
					<Link to="/">
						<Logo dark={false} size="md"/>
					</Link>
				</div>
				<div className="activation-form md:mx-auto md:mt-24 mt-12 md:w-2/5 w-full md:border-solid md:border md:border-gray-200 bg-white md:shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
					{/*component*/}
					<div className="mx-auto w-full">
							<p className="font-bold text-gray-700 text-center mb-8">次にアイコンと自己紹介を設定しましょう。</p>
							<div className="md:w-2/6 w-full m-auto mb-8">
								{											
									fileUrl != '' &&
									<img className="rounded-full flex items-center justify-center cursor-pointer hover:opacity-75 md:h-44 md:w-44 h-28 w-28 m-auto"  id="myimage" alt="Crop" src={fileUrl} onClick={handleClick}/>
								}
								{											
                  fileUrl == '' &&
                  <img className="rounded-full md:w-40 md:h-40 w-28 h-28 m-auto rounded-lg shadow-lg cursor-pointer" id="myimage" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" onClick={handleClick}/>
								}
								<input 
									id="inputfile" 
									className="hidden" 
									type="file" 
									ref={inputRef} 
									onChange={onSelectFile} 
								/>
							</div>
							<div className="col-span-2 mb-4">
							<textarea 
								name="profile"
								className={"focus:outline-none mt-4 border-gray-300 appearance-none rounded border-2 px-3 py-2 md:text-ml w-full h-72" + (errors.profile ? " border-red-600 focus:border-red-600" : " focus:border-blue-300")}
								ref={register({ required: true })} 
                placeholder="自己紹介" 
							/>
							{errors.profile && <span className="text-red-600 font-semibold text-xs">自己紹介を入力してください</span>}
							</div>
							<button 
								id="activation-button" 
								className="w-1/5 block m-auto main-background hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" 
								type="button" 
								onClick={handleSubmit(pushFile)}>
								{is_loading ? 
								<Spinner/> 
								: 
								"次へ"}
							</button>
						<Link to="/tech">
							<p className="text-gray-600 hover:text-gray-400 text-center font-bold text-sm mt-2">スキップ</p>
						</Link>
						</div>
				</div>
			</div>
		</div>
	)
}

export default Profile;

