import {
	BOOLEAN_SM_MENU,
} from '../actions/index.js'

const initialState = {
	sm_menu: false
};
function smMenu (state = initialState, action){
	switch (action.type){
		case BOOLEAN_SM_MENU:
			return{ sm_menu: !state.sm_menu}
		// まずはここでstateをセットする
		default:
			return state
	}
}

export default smMenu;
