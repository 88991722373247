// 運営会社情報ページ
import React from 'react';
import Footer from '../Organisms/footer.js';
import Header from '../Organisms/Header.js';
// Molecules
import BlackModal from '../Molecules/black-modal';
import SmLeftMenu from '../Organisms/sm-leftMenu.js';

function Company(props){
	return(
		<React.Fragment>
				<BlackModal targetID='modal' />
				<Header />
        <div className="md:w-3/5 w-full md:m-auto pt-16">
					{/* SMレフトメニュー */}
					<SmLeftMenu/>
            <div className="md:border md:border-gray-300 rounded my-10 md:px-10 px-8 py-5">
              <div className="text-center my-8 flex justify-center">
                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#294c7a" viewBox="0 0 256 256">
                  <line x1="16" y1="215.98316" x2="240" y2="215.98316" fill="none" stroke="#294c7a" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                  <path d="M143.99414,215.98316v-176a8,8,0,0,0-8-8h-96a8,8,0,0,0-8,8v176" fill="none" stroke="#294c7a" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
                  <path d="M223.99414,215.98316v-112a8,8,0,0,0-8-8h-72" fill="none" stroke="#294c7a" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
                  <line x1="63.99414" y1="71.98316" x2="95.99414" y2="71.98316" fill="none" stroke="#294c7a" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                  <line x1="79.99414" y1="135.98316" x2="111.99414" y2="135.98316" fill="none" stroke="#294c7a" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                  <line x1="63.99414" y1="175.98316" x2="95.99414" y2="175.98316" fill="none" stroke="#294c7a" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                  <line x1="175.99414" y1="175.98316" x2="191.99414" y2="175.98316" fill="none" stroke="#294c7a" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                  <line x1="175.99414" y1="135.98316" x2="191.99414" y2="135.98316" fill="none" stroke="#294c7a" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                </svg>
                <h2 className="font-bold text-2xl">運営者情報</h2>
                </div>
                <div className="m-auto border rounded">
                  <ul className="md:flex w-full text-gray-600 font-semibold  bg-gray-100 border-b">
                    <li className="md:w-1/2 w-full md:p-5 p-3 md:border-r md:border-b-0 border-b">
                      運営者
                    </li>
                    <li className="md:w-1/2 w-full md:p-5 px-3 py-5 bg-white">
                      清水太一
                    </li>
                  </ul>
                  <ul className="md:flex w-full text-gray-600 font-semibold  bg-gray-100 border-b">
                    <li className="md:w-1/2 w-full md:p-5 p-3 md:border-r md:border-b-0 border-b">
                      連絡先
                    </li>
                    <li className="md:w-1/2 w-full md:p-5 px-3 py-5 bg-white">
                      <a href="mailto:info@stacklive.jp">info@stacklive.jp</a>
                    </li>
                  </ul>
                  <ul className="md:flex w-full text-gray-600 font-semibold  bg-gray-100">
                    <li className="md:w-1/2 w-full md:p-5 p-3 md:border-r md:border-b-0 border-b">
                      事業内容
                    </li>
                    <li className="md:w-1/2 w-full md:p-5 px-3 py-5 bg-white">
                      StackLiveの運営
                    </li>
                  </ul>
                </div>
          </div>
        </div>
			<Footer/>
		</React.Fragment>
	)
}
export default Company