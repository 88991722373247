import React, {Suspense, useState, useEffect } from 'react';
const HomeTrend = React.lazy(() => import('../Organisms/homeTrend.js')); 
const HomeNew = React.lazy(() => import('../Organisms/homeNew.js')); 
const HomeArchive = React.lazy(() => import('../Organisms/homeArchive.js')); 
const HomeReserve = React.lazy(() => import('../Organisms/homeReserve.js')); 
const HomePickUp = React.lazy(() => import('../Organisms/homePickUp.js')); 
import NoResults from '../Molecules/no-results.js';
// redux
import {useSelector} from 'react-redux';

// amplify
import Amplify, { API } from "aws-amplify";

import UseFetchResource from '../../containers/fetchResource.js';
// DB関連
import * as gqlQueries from '../../graphql/queries'
import { Link} from 'react-router-dom';


function Home(props){
	const [wrapPromise] = UseFetchResource();
	// 今の日付と時間を管理
	const reserve_schedule = useSelector(state => state.now_schedule.date);
	// トレンドの15件を早期フェッチする。
	// const trendResource = fetchTodoList(API.graphql({query: gqlQueries.byTrendSort,variables: { is_live: 1, sortDirection: "DESC", limit:15}}));
	// 新着順の20件を早期フェッチする。
	const newInfo = API.graphql({query: gqlQueries.byCreatedAt,variables: { is_live: 1, sortDirection: "DESC", limit:20}});
  const newResource = wrapPromise(newInfo)
	// アーカイブの20件を早期フェッチする。
  const archiveInfo = API.graphql({query: gqlQueries.byArchiveSort,variables: { archive: 1, sortDirection: "DESC", limit:20}});
  const archiveSource = wrapPromise(archiveInfo)
	// 予約配信の20件を早期フェッチする。
  const reserveInfo = API.graphql({query: gqlQueries.byReserveDate,variables: { reserve: 1,reserveDate: {ge: reserve_schedule.today_str}, sortDirection: "ASC", limit:12}});
  const reserveSource = wrapPromise(reserveInfo)

	const [isNewLive, setNewLive] = useState(true);
	const [isArchive, setArchive] = useState(true);
	const [isReserve, setReserve] = useState(true);
	return(
			<React.Fragment>
				<div className="w-full">
					<Suspense fallback={<p className="relative"></p>}>
						{/* ピックアップをカルーセルで表示 */}
						<HomePickUp resource={[newResource,reserveSource,archiveSource]}/>
					</Suspense>
					<div className="home-margin-top">
							<Suspense fallback={<p></p>}>
									{/* {
										isNewLive == false &&
										<NoResults children="配信中のライブがありません...."/>
									} */}
								<React.Fragment>
									{
										isNewLive == true &&
											<div className="flex justify-between">
												<div className="text-gray-700 m-6 font-bold md:text-2xl text-xl">
													新着ライブ
												</div>
												<Link to="/new" 
													className="md:mr-10 mr-2 md:text-sm text-xs py-2 md:px-4 px-2 flex my-auto h-fitcontent border border-blue-500 text-blue-500 rounded-sm font-boldhover:opacity-75"
												>
													もっと見る
													<svg 
														className="md:w-5 md:h-5 h-4 w-4" 
														fill="none" 
														stroke="currentColor" 
														viewBox="0 0 24 24" 
														xmlns="http://www.w3.org/2000/svg"
													>
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
													</svg>
												</Link>
											</div>
									}
									<HomeNew resource={newResource} func={setNewLive}/>
									{
										isReserve &&
											<div className="flex justify-between">
												<div className="text-gray-700 m-6 font-bold md:text-2xl text-xl">
													予約配信
												</div>
												<Link to="/reserves" 
													className="md:mr-10 mr-2 md:text-sm text-xs py-2 md:px-4 px-2 flex my-auto h-fitcontent border border-blue-500 text-blue-500 rounded-sm font-boldhover:opacity-75"
												>
													もっと見る
													<svg 
														className="md:w-5 md:h-5 h-4 w-4" 
														fill="none" 
														stroke="currentColor" 
														viewBox="0 0 24 24" 
														xmlns="http://www.w3.org/2000/svg"
													>
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
													</svg>
												</Link>
											</div>
									}
									<HomeReserve resource={reserveSource} func={setReserve}/>
									{
										isArchive &&
											<div className="flex justify-between">
												<div className="text-gray-700 m-6 font-bold md:text-2xl text-xl">
													アーカイブ
												</div>
												<Link to="/archive" 
													className="md:mr-10 mr-2 md:text-sm text-xs py-2 md:px-4 px-2 flex my-auto h-fitcontent border border-blue-500 text-blue-500 rounded-sm font-boldhover:opacity-75"
												>
													もっと見る
													<svg 
														className="md:w-5 md:h-5 h-4 w-4" 
														fill="none" 
														stroke="currentColor" 
														viewBox="0 0 24 24" 
														xmlns="http://www.w3.org/2000/svg"
													>
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
													</svg>
												</Link>
											</div>
									}
									<HomeArchive resource={archiveSource} func={setArchive}/>
								</React.Fragment>
							</Suspense>
					</div>
				</div>
			</React.Fragment>
	)
}
export default Home
