import React, {useState, useEffect } from 'react';
import Amplify, { API, graphqlOperation} from "aws-amplify";
import {Link} from 'react-router-dom';
// Atoms
import Img from '../Atoms/img';
// DB関連
import * as gqlQueries from '../../graphql/queries'

// DynamoDBからuser_id,viewerCountを取得する
function Trend(props){
	// all_viewerCountの降順で取得したユーザーテーブルの情報を格納する
	const [rankings, setRanking] = useState([]);
	useEffect(()=>{
		(async function dblistStream(){
				const { data: { byUserRanking: { items: users} } } = await API.graphql({query: gqlQueries.byUserRanking, variables: { logininfo: 1, sortDirection: "DESC",limit:50}});
				setRanking([...users]);
		})();
	},[]
	);

	return(
			<React.Fragment>
					<div className="w-full">
						<h1 className="font-bold text-xl pl-2 ml-4 my-4">ランキング</h1>
						<ul className="md:flex block mx-2 md:mx-auto flex-wrap user_list justify-evenly">
						{
							rankings.map((user,i) =>
								<Link to={"/user/" + user.user_id} key={i} className="">
									<li className="bg-white p-2 rounded-r-xl rounded-b-xl shadow border relative mb-5 hover:opacity-75">
										<span className="absolute text-center text-white top-0 left-0 ribbon font-bold">{i+1}</span>
										<div className="flex w-full">
											<div className="pt-6">
												{/*プロフィール画像*/}
												{user.icon ?
													<Img size="ranking" src={user.icon} />
														:
													<img src="/images/smiley-sticker.svg" className="h-16 w-16 rounded-full mr-1"/>
												}
											</div>
											<div className="px-3 md:pb-3 pb-0 m-auto pt-6 w-10/12">
												<div className="font-semibold text-gray-700">
													{user.name}
												</div>
												<div className="h-36 max-h-16 text-sm text-gray-500 overflow-ellipsis overflow-hidden">
													{
														user.profile
													}
												</div>
											</div>
										</div>
									</li>
								</Link>
							)
						}
						</ul>
					</div>
			</React.Fragment>
	)
}
export default Trend;

