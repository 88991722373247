import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

function FirstRoute(props) {
	const userInfo = useSelector(state => state.auth.userInfo);
  //ログインしていないと入れないページ 渡された props をそのまま Route に設定する
	return userInfo.logininfo ? <Redirect to="/" /> : <Route {...props} />;
}

export default FirstRoute;
