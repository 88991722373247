import {
  NOTIFICATION,
} from '../actions/index.js'

const initialState = {
	count: 0,
};
function notification_badge (state = initialState, action){
	switch (action.type){
		case NOTIFICATION:
			return{ count: action.count}
		// まずはここでstateをセットする
		default:
			return state
	}
}

export default notification_badge;
